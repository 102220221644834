import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
import MapboxCircle from "mapbox-gl-circle";

import { addAddress } from "../../../Services/Redux/Slices/Location.Slice";
import { Button } from "../../../Components/Common/Buttons/Button";
import config from "../../../config";
import useStateRef from "react-usestateref";
import { GetTimeZone } from "../../../Services/Helpers/GetTimeZone";
import { updateLocationLink } from "../../../Services/APIHandlers/ProfileApi";

setDefaults({
  key: config.googleAPI,
  language: "en",
  region: "es",
});

const Location = () => {
  //const isLoading = useSelector((state) => state.location.isLoading);
  const [isLoading, setLoading] = useState(false);

  if (isLoading) return null;
  return <MapWithSearchBox />;

};

const MapWithSearchBox = () => {
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const markerRef = useRef(null);
  const myCircleRef = useRef(null);

  const token = useSelector((state) => state.auth.token);
  console.log(">>token", token);

  const dispatch = useDispatch();
  const location = useSelector((state) => state.location.address);
  const [locationData, setLocationData, locationDataRef] =
    useStateRef(location);

  const [geoAddress, setGeoAddress] = useState({
    address: "",
    city: "",
    state: "",
    zip: "",
  });

  useEffect(() => {
    console.log(">>locationData", locationData);
  }, [locationData]);

  const extractAddressComponents = (results) => {
    const result = results[0];
    const addressComponents = result.address_components;

    const addressDetails = {
      address: result.formatted_address,
      street: "",
      city: "",
      state: "",
      zip: "",
    };

    addressComponents.forEach((component) => {
      if (component.types.includes("street_number")) {
        addressDetails.street += component.long_name + " ";
      }
      if (component.types.includes("route")) {
        addressDetails.street += component.long_name;
      }
      if (component.types.includes("locality")) {
        addressDetails.city = component.long_name;
      }
      if (component.types.includes("administrative_area_level_1")) {
        addressDetails.state = component.short_name;
      }
      if (component.types.includes("postal_code")) {
        addressDetails.zip = component.long_name;
      }
    });

    setGeoAddress(addressDetails);
  };

  useEffect(() => {
    const locationData = locationDataRef.current;

    mapboxgl.accessToken =
      "pk.eyJ1IjoiaG9wcGVyZml0IiwiYSI6ImNsa2tpM2c0ZzA2dWkzcG1tNnV3MmVyMjQifQ.x9vQVrNmILfTln-SAXS8pw";
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [locationData?.lng ?? -74.5, locationData?.lat ?? 40],
      zoom: 15,
    });

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: false,
    });

    mapRef.current.addControl(geocoder);

    // Listen for 'result' event from geocoder to center map on selected location
    geocoder.on("result", (e) => {
      console.log(">>result", e.result);
      const locationData = locationDataRef.current;

      mapRef.current.flyTo({
        center: e.result.center,
        essential: true, // Ensures smooth animation
      });
      // Remove existing marker
      if (markerRef.current) markerRef.current.remove();
      // Add new marker at the selected location
      markerRef.current = new mapboxgl.Marker({ color: "red" })
        .setLngLat(e.result.center)
        .addTo(mapRef.current);
      // Move the circle to the selected location
      myCircleRef.current.setCenter({
        lat: e.result.center[1],
        lng: e.result.center[0],
      });
      setLocationData({
        ...locationData,
        lat: e.result.center[1],
        lng: e.result.center[0],
      });
    });

    mapRef.current.addControl(new mapboxgl.NavigationControl());

    mapRef.current.on("load", () => {
      const locationData = locationDataRef.current;

      // Create a marker at the initial location
      markerRef.current = new mapboxgl.Marker({ color: "red" })
        .setLngLat([locationData?.lng ?? -74.5, locationData?.lat ?? 40])
        .addTo(mapRef.current);

      // Add any additional map configuration or event handling here
      myCircleRef.current = new MapboxCircle(
        {
          lat: locationData.lat,
          lng: locationData.lng,
        },
        locationData.radius ? locationData.radius * 1609.34 : 1,
        {
          editable: false,
          minRadius: 10,
          fillColor: "#29AB87",
        }
      ).addTo(mapRef.current);
    });

    mapRef.current.on("click", (e) => {
      const locationData = locationDataRef.current;
      // Remove existing marker
      if (markerRef.current) markerRef.current.remove();

      // Add new marker at the clicked location
      markerRef.current = new mapboxgl.Marker({ color: "red" })
        .setLngLat(e.lngLat)
        .addTo(mapRef.current);

      // Move the circle to the clicked location
      const circleCenter = {
        lat: e.lngLat.lat,
        lng: e.lngLat.lng,
      };
      myCircleRef.current.setCenter(circleCenter);

      setLocationData({
        ...locationData,
        lat: e.lngLat.lat,
        lng: e.lngLat.lng,
      });
    });
  }, [locationDataRef, setLocationData]);

  const calculateZoomLevel = (latDelta, longDelta) => {
    const earthCircumference = 40075016.686;
    const pxWidth = window.innerHeight * 0.8;
    const pxHeight = window.innerHeight * 0.8;
    const metersPerPixelLat = latDelta / pxHeight;
    const metersPerPixelLong = longDelta / pxWidth;
    const zoomLevelLat = Math.log2(
      earthCircumference / (metersPerPixelLat * 256)
    );
    const zoomLevelLong = Math.log2(
      earthCircumference / (metersPerPixelLong * 256)
    );
    const zoomLevel = Math.round((zoomLevelLat + zoomLevelLong) / 2);
    return zoomLevel;
  };

  useEffect(() => {
    if (locationData?.lat && locationData?.lng) {
      geocode(
        RequestType.LATLNG,
        `${locationData?.lat},${locationData?.lng}`
      ).then(({ results }) => {
        let address = results[0].formatted_address;
        console.log(">>address", results[0]);
        extractAddressComponents(results);

        // dispatch(addAddress(address));
        if (address != null) {
          let latDelta = 0.02;
          let lonDelta = 0.02;
          if (locationData.radius && locationData.lat) {
            const latDegree = 69.172;
            const cosLat = Math.cos((locationData.lat * Math.PI) / 180);
            let radiusLevel;
            if (Number(locationData.radius) < 10) {
              radiusLevel = Number(locationData.radius) + 15;
            } else if (Number(locationData.radius) < 15) {
              radiusLevel = Number(locationData.radius) + 20;
            } else if (Number(locationData.radius) < 20) {
              radiusLevel = Number(locationData.radius) + 25;
            } else {
              radiusLevel = Number(locationData.radius) + 40;
            }
            latDelta = Number(radiusLevel) / latDegree;
            lonDelta = latDelta / (cosLat * latDegree);
          }
          const zoomLevel = calculateZoomLevel(latDelta, lonDelta);
          if (locationData.radius) {
            mapRef.current.flyTo({
              center: [locationData.lng, locationData.lat],
              zoom: zoomLevel * 0.4,
              essential: true,
            });
          }
        }
      });
    }
  }, [dispatch, locationData]);

  const onRadiusChange = (e) => {
    const newRadius = parseFloat(e.target.value);
    if (!isNaN(newRadius)) {
      myCircleRef.current.setRadius(newRadius * 1609.34); // Convert miles to meters
      console.log(">>radius", newRadius * 1609.34);
      setLocationData({
        ...locationData,
        radius: e.target.value,
      });
    } else {
      setLocationData({
        ...locationData,
        radius: 0,
      });
    }
  };

  const handleSubmit = async () => {
    const locationData = locationDataRef.current;

    try {
      const timeZone = await GetTimeZone({
        latitude: locationData.lat ?? 33,
        longitude: locationData.lng ?? 33,
      });

      const data = [
        {
          address: geoAddress.address || locationData.address || "",
          lat: locationData.lat ? locationData.lat : 33,
          lng: locationData.lng ? locationData.lng : 33,
          radius: locationData.radius ? locationData.radius : "",
          city: geoAddress.city || locationData.city || "",
          state: geoAddress.state || locationData.state || "",
          timezone: timeZone.data?.timeZoneName,
          timeZoneID: timeZone.data?.timeZoneId,
        },
      ];
      console.log(data);
      const response = await updateLocationLink(JSON.stringify(data), token);
      //console.log(response);
      if (response.data.success) {
        dispatch(addAddress(response.data["request->data"][0]));
        navigate(-1);
      } else {
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <div
        ref={mapContainerRef}
        style={{ height: "75vh", width: "100%", marginBottom: 20 }}
      />
      <input
        className="input mt10"
        value={locationData?.radius || ""}
        placeholder="Driving Radius"
        style={{ marginBottom: 20 }}
        onChange={onRadiusChange}
      />
      <Button fill="solid" text="Set Location" onClick={handleSubmit}></Button>
    </div>
  );
};

export default Location;

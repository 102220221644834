import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function PrivateRoute({ children }) {
  const auth = useSelector((state) => state.auth);

  const isAuthenticated = auth.isAuthenticated;

  return !isAuthenticated ? <Navigate to="/" /> : <>{children}</>;
}

export default PrivateRoute;

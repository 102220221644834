import { useDispatch, useSelector } from "react-redux";
import { setExtras } from "../../Services/Redux/Slices/Onboarding.Slice";
import { globalStyles, spacingStyles } from "../../assets/styles/global";
import OnboardingButton from "../Common/Buttons/OnboardingButton";
import { Typography, useTheme } from "@mui/material"; // Importing Material-UI theme

export default function ExtrasComponent() {
  const dispatch = useDispatch();
  const extras = useSelector((state) => state.onboarding.extras);
  const theme = useTheme(); // Access the current theme

  return (
    <div style={{ ...globalStyles.alignCenter, ...globalStyles.w100 }}>
      <Typography variant="h4" color={theme.palette.text.primary}>
        Does your space have any of these amenities?
      </Typography>
      <Typography variant="body1" style={spacingStyles.mb20} color={theme.palette.text.secondary}>
        Select all that apply
      </Typography>
      <div className="onborading-amenities-list">
        <OnboardingButton
          selected={extras.bathroom}
          showCusIcon={true}
          onClick={() =>
            dispatch(setExtras({ ...extras, bathroom: !extras.bathroom }))
          }
          text="Bathrooms"
          cusIconName="bathroom"
        />
        <OnboardingButton
          selected={extras.changingArea}
          showCusIcon={true}
          onClick={() =>
            dispatch(
              setExtras({ ...extras, changingArea: !extras.changingArea })
            )
          }
          text={`Changing ${"\n"} Area`}
          cusIconName="changingArea"
        />
        <OnboardingButton
          selected={extras.waterFountain}
          showCusIcon={true}
          onClick={() =>
            dispatch(
              setExtras({ ...extras, waterFountain: !extras.waterFountain })
            )
          }
          text={`Water ${"\n"} Fountain`}
          cusIconName="waterFountain"
        />
        <OnboardingButton
          selected={extras.sauna}
          showCusIcon={true}
          onClick={() =>
            dispatch(setExtras({ ...extras, sauna: !extras.sauna }))
          }
          text={`Sauna`}
          cusIconName="sauna"
        />
        <OnboardingButton
          selected={extras.steamRoom}
          showCusIcon={true}
          onClick={() =>
            dispatch(setExtras({ ...extras, steamRoom: !extras.steamRoom }))
          }
          text={`Steam Room`}
          cusIconName="steamRoom"
        />
        <OnboardingButton
          selected={extras.coldPlunge}
          showCusIcon={true}
          onClick={() =>
            dispatch(setExtras({ ...extras, coldPlunge: !extras.coldPlunge }))
          }
          text={`Cold Plunge`}
          cusIconName="coldPlunge"
        />
        <OnboardingButton
          selected={extras.soundSystems}
          showCusIcon={true}
          onClick={() =>
            dispatch(
              setExtras({ ...extras, soundSystems: !extras.soundSystems })
            )
          }
          text={`Sound \n System`}
          cusIconName="soundSystems"
          iconHeight={38}
        />
      </div>
    </div>
  );
}

import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function ImageCarousel({ images, width, height }) {
  return (
    <div style={{ width, height }}>
      <Carousel
        autoPlay={false}
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        emulateTouch
        infiniteLoop
      >
        {images.map(({ id, image, name }) => (
          <div key={id}>
            <img
              src={image}
              alt="asset"
              style={{
                objectFit: "cover",
                width,
                height: height - 50,
                borderRadius: 14,
              }}
            />
            <p>{name}</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default ImageCarousel;

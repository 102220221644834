import axios from "axios";
import appConfig from "../../config";

export const GetTimeZone = async (latlong) => {
  var config = {
    method: "get",
    url: `https://maps.googleapis.com/maps/api/timezone/json?location=${latlong.latitude}%2C${latlong.longitude}&timestamp=1331161200&key=${appConfig.googleAPI}`,
    headers: {},
  };

  const response = await axios(config);
  return response;
};

export const CustIcons = {
  treadmill: {
    src: require("../../assets/icons/equipment/treadmill.svg"),
    displayName: "Treadmill",
  },
  eliptical: {
    src: require("../../assets/icons/equipment/eliptical.svg"),
    displayName: "Eliptical",
  },
  spinBikes: {
    src: require("../../assets/icons/equipment/spinBikes.svg"),
    displayName: "Spin Bike",
  },
  machines: {
    src: require("../../assets/icons/equipment/machines.svg"),
    displayName: "Machines",
  },
  freeWeights: {
    src: require("../../assets/icons/equipment/freeWeights.svg"),
    displayName: "Free Weights",
  },
  dumbells: {
    src: require("../../assets/icons/equipment/dumbells.svg"),
    displayName: "Dumbells",
  },
  rowMachine: {
    src: require("../../assets/icons/equipment/rowmachine.svg"),
    displayName: "Row Machine",
  },
  pullupBar: {
    src: require("../../assets/icons/equipment/pullupBar.svg"),
    displayName: "Pullup Bar",
  },
  punchingBags: {
    src: require("../../assets/icons/equipment/punchingBags.svg"),
    displayName: "Punching Bags",
  },
  crossTraining: {
    src: require("../../assets/icons/equipment/crossTraining.svg"),
    displayName: "Cross Training",
  },
  floorMats: {
    src: require("../../assets/icons/equipment/floorMats.svg"),
    displayName: "Floor Mar",
  },
  stairMaster: {
    src: require("../../assets/icons/equipment/stairMaster.svg"),
    displayName: "Stair Master",
  },

  aerialYoga: {
    src: require("../../assets/icons/amenities/aerialYoga.svg"),
    displayName: "Aerial Yoga",
  },
  backetballCourt: {
    src: require("../../assets/icons/amenities/basketballcourt.svg"),
    displayName: "Basketball Court",
  },
  dancePoles: {
    src: require("../../assets/icons/amenities/poledance.svg"),
    displayName: "Pole Dance",
  },
  indoorField: {
    src: require("../../assets/icons/amenities/track2.svg"),
    displayName: "Indoor Field",
  }, // which icon?
  indoorTrack: {
    src: require("../../assets/icons/amenities/track.svg"),
    displayName: "Indoor Track",
  },
  outdoorField: {
    src: require("../../assets/icons/amenities/track2.svg"),
    displayName: "Outdoor Field",
  }, // which icon?
  outdoorTrack: {
    src: require("../../assets/icons/amenities/track2.svg"),
    displayName: "Outdoor Track",
  }, //which icon?
  pool: {
    src: require("../../assets/icons/amenities/swimmingpool.svg"),
    displayName: "Pool",
  },
  racketballCourt: {
    src: require("../../assets/icons/amenities/racketball.svg"),
    displayName: "Racketball Court",
  },
  tennisCourt: {
    src: require("../../assets/icons/amenities/tennis.svg"),
    displayName: "Tennis Court",
  },
  trampoline: {
    src: require("../../assets/icons/amenities/trampoline.svg"),
    displayName: "Trampoline",
  },
  volleyballCourt: {
    src: require("../../assets/icons/amenities/volleyball.svg"),
    displayName: "Volleyball Court",
  },

  bathroom: {
    src: require("../../assets/icons/utilities/bathroom.svg"),
    displayName: "Bathroom",
  },
  changingArea: {
    src: require("../../assets/icons/utilities/changingarea.svg"),
    displayName: "Changing Area",
  },
  coldPlunge: {
    src: require("../../assets/icons/utilities/coldplunge.svg"),
    displayName: "Cold Plunge",
  },
  sauna: {
    src: require("../../assets/icons/utilities/sauna.svg"),
    displayName: "Sauna",
  },
  soundSystems: {
    src: require("../../assets/icons/utilities/soundsystem.svg"),
    displayName: "Sound System",
  },
  steamRoom: {
    src: require("../../assets/icons/utilities/steamroom.svg"),
    displayName: "Steam Room",
  },
  waterFountain: {
    src: require("../../assets/icons/utilities/waterfountain.svg"),
    displayName: "Water Fountain",
  },

  concrete: {
    src: require("../../assets/icons/floors/concrete.svg"),
    displayName: "Concrete",
  },
  foam: {
    src: require("../../assets/icons/floors/foam.svg"),
    displayName: "Foam",
  },
  padded: {
    src: require("../../assets/icons/floors/padded.svg"),
    displayName: "Padded",
  },
  rubber: {
    src: require("../../assets/icons/floors/rubber.svg"),
    displayName: "Rubber",
  },
  spring: {
    src: require("../../assets/icons/floors/spring.svg"),
    displayName: "Spring",
  },
  turf: {
    src: require("../../assets/icons/floors/turf.svg"),
    displayName: "Turf",
  },
  wood: {
    src: require("../../assets/icons/floors/wood.svg"),
    displayName: "Wood",
  },
  danceFloor: {
    src: require("../../assets/icons/floors/wood3.svg"),
    displayName: "Dance Floor",
  },

  gym: {
    src: require("../../assets/icons/custom/gym.svg"),
    displayName: "Gym",
  },

  Early: {
    src: require("../../assets/icons/sessions/early.svg"),
    displayName: "Early",
  },
  Morning: {
    src: require("../../assets/icons/sessions/morning.svg"),
    displayName: "Morning",
  },
  Noon: {
    src: require("../../assets/icons/sessions/noon.svg"),
    displayName: "Noon",
  },
  Afternoon: {
    src: require("../../assets/icons/sessions/afternoon.svg"),
    displayName: "After",
  },
  Evening: {
    src: require("../../assets/icons/sessions/evening.svg"),
    displayName: "Evening",
  },
  Night: {
    src: require("../../assets/icons/sessions/night.svg"),
    displayName: "Night",
  },

  confirmCheckmark: {
    src: require("../../assets/checkmark.svg"),
    displayName: "Checkmark",
  },
  closed: {
    src: require("../../assets/icons/custom/closed.svg"),
    displayName: "Closed",
  },
  calendar: {
    src: require("../../assets/icons/custom/calendar.svg"),
    displayName: "Calendar",
  },
};

import * as React from "react";
import { Slider } from "@mui/material";

export default function CustomSlider({
  value,
  onValueChange,
  min = 0,
  max = 5,
  step = 1,
}) {
  return (
    <div>
      <Slider
        sx={{
          "& .MuiSlider-thumb": {
            backgroundColor: "#fff",
            width: 37,
            height: 35,
          },
          "& .MuiSlider-valueLabel": {
            backgroundColor: "#fff",
            color: "#000",
            fontSize: 16,
            fontWeight: "bold",
            borderRadius: "50%",
            padding: 0,
            userSelect: "none",
            transform: "translateY(70%) scale(1) !important",
          },
        }}
        value={value || 0}
        onChange={(e, newValue) => onValueChange(newValue)}
        valueLabelDisplay="on"
        min={min}
        max={max}
        step={step}
      />
    </div>
  );
}

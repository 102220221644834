import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { useDispatch, useSelector } from "react-redux";
import { Box, TextField, Typography } from "@mui/material"; // Import MUI components
import { useTheme } from "@mui/material/styles"; // Import MUI theme hook
import MapboxCircle from "mapbox-gl-circle"; // Import MapboxCircle
import { Button } from "../Common/Buttons/Button";
import config from "../../config";
import { globalStyles } from "../../assets/styles/global";
import useStateRef from "react-usestateref";
import { GetTimeZone } from "../../Services/Helpers/GetTimeZone";
import { updateLocationLink } from "../../Services/APIHandlers/ProfileApi";
import { addAddress } from "../../Services/Redux/Slices/Location.Slice";

mapboxgl.accessToken =
  "pk.eyJ1IjoiaG9wcGVyZml0IiwiYSI6ImNsa2tpM2c0ZzA2dWkzcG1tNnV3MmVyMjQifQ.x9vQVrNmILfTln-SAXS8pw";

const OnboardingLocation = ({ radiusOpt = true }) => {
  const [isLoading, setLoading] = useState(false);
  if (isLoading) return null;
  return <MapWithSearchBox radiusOpt={radiusOpt} />;
};

const MapWithSearchBox = ({ radiusOpt }) => {
  const dispatch = useDispatch();
  const theme = useTheme(); // Access the current theme
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const geocoderContainerRef = useRef(null);
  const markerRef = useRef(null);
  const myCircleRef = useRef(null);
  const [isLocationChanged, setIsLocationChanged] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const location = useSelector((state) => state.location.address);
  const [locationData, setLocationData, locationDataRef] = useStateRef(location);
  const [address, setAddress] = useState(false);
  const [geoAddress, setGeoAddress] = useState({
    address: "",
    city: "",
    state: "",
    zip: "",
  });

  useEffect(() => {
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: false,
    });

    // Clear existing Geocoder
    if (geocoderContainerRef.current) {
      const existingGeocoder = geocoderContainerRef.current.querySelector(
        ".mapboxgl-ctrl-geocoder"
      );
      if (existingGeocoder) {
        geocoderContainerRef.current.removeChild(existingGeocoder);
      }
    }

    if (geocoderContainerRef.current) {
      geocoderContainerRef.current.appendChild(geocoder.onAdd(mapRef.current));
    }

    geocoder.on("result", (e) => {
      const locationData = locationDataRef.current;
      mapRef.current.flyTo({
        center: e.result.center,
        essential: true,
      });
      if (markerRef.current) markerRef.current.remove();
      markerRef.current = new mapboxgl.Marker({ color: "red" })
        .setLngLat(e.result.center)
        .addTo(mapRef.current);

      myCircleRef.current.setCenter({
        lat: e.result.center[1],
        lng: e.result.center[0],
      });

      setLocationData({
        ...locationData,
        lat: e.result.center[1],
        lng: e.result.center[0],
      });
      setIsLocationChanged(true);
    });
  }, []);

  useEffect(() => {
    const locationData = locationDataRef.current;

    //console.log(locationData); 
    setAddress(locationData.address); 

    const mapStyle =
    theme.palette.mode === "dark"
      ? "mapbox://styles/mapbox/dark-v10"
      : "mapbox://styles/mapbox/streets-v11";


    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: mapStyle,
      center: [locationData?.lng ?? -74.5, locationData?.lat ?? 40],
      zoom: 15,
    });

    mapRef.current.addControl(new mapboxgl.NavigationControl());

    mapRef.current.on("load", () => {
      if (markerRef.current) markerRef.current.remove();
      markerRef.current = new mapboxgl.Marker({ color: "red" })
        .setLngLat([locationData?.lng ?? -74.5, locationData?.lat ?? 40])
        .addTo(mapRef.current);

      myCircleRef.current = new MapboxCircle(
        {
          lat: locationData.lat,
          lng: locationData.lng,
        },
        locationData.radius ? locationData.radius * 1609.34 : 1,
        {
          editable: false,
          minRadius: 10,
          fillColor: "#29AB87",
        }
      ).addTo(mapRef.current);
    });

    mapRef.current.on("click", (e) => {
      const locationData = locationDataRef.current;
      
      if (markerRef.current) markerRef.current.remove();
      markerRef.current = new mapboxgl.Marker({ color: "red" })
        .setLngLat(e.lngLat)
        .addTo(mapRef.current);
      const circleCenter = {
        lat: e.lngLat.lat,
        lng: e.lngLat.lng,
      };
      myCircleRef.current.setCenter(circleCenter);

      setLocationData({
        ...locationData,
        lat: e.lngLat.lat,
        lng: e.lngLat.lng,
      });
      setIsLocationChanged(true);
    });
  }, [locationDataRef, setLocationData, theme.palette.mode]);


  const onRadiusChange = (e) => {
    const newRadius = parseFloat(e.target.value);
    if (!isNaN(newRadius)) {
      myCircleRef.current.setRadius(newRadius * 1609.34); // Convert miles to meters
      setLocationData({
        ...locationData,
        radius: e.target.value,
      });
    } else {
      setLocationData({
        ...locationData,
        radius: 0,
      });
    }
  };

  const handleSubmit = async () => {
    const locationData = locationDataRef.current;
    setIsLocationChanged(false);

    try {
      const timeZone = await GetTimeZone({
        latitude: locationData.lat ?? 33,
        longitude: locationData.lng ?? 33,
      });

      const data = [
        {
          address: geoAddress.address || locationData.address || "",
          lat: locationData.lat ? locationData.lat : 33,
          lng: locationData.lng ? locationData.lng : 33,
          radius: locationData.radius ? locationData.radius : "",
          city: geoAddress.city || locationData.city || "",
          state: geoAddress.state || locationData.state || "",
          timezone: timeZone.data?.timeZoneName,
          timeZoneID: timeZone.data?.timeZoneId,
        },
      ];
      const response = await updateLocationLink(JSON.stringify(data), token);
      if (response.data.success) {
        dispatch(addAddress(response.data["request->data"][0]));
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box sx={{ ...globalStyles.w100, padding: 2 }}>
      {/* Geocoder container */}
      <Box ref={geocoderContainerRef} sx={{ marginBottom: 2 }} />
      
      {/* Map container with dynamic styling */}
      <Box
        ref={mapContainerRef}
        sx={{
          height: "45vh",
          width: "100%",
          borderRadius: "10px",
          overflow: "hidden",
          marginBottom: 3,
        }}
      />

      {/* Radius input */}
      {radiusOpt && (
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="h6" color={theme.palette.text.primary}>
            Radius:
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={locationData?.radius || ""}
            placeholder="Driving Radius"
            onChange={onRadiusChange}
            sx={{
              input: { color: theme.palette.text.primary },
              backgroundColor: theme.palette.background.default,
              borderRadius: "4px",
            }}
          />
        </Box>
      )}

      {/* Display address and button */}
      <Box sx={{ 
        marginTop: 3 , 
        maxWidth: '440px',    // Set the max width to 600px or your preferred width
        margin: '0 auto',     // Center the box horizontally
        //textAlign: 'center' 
        }}>
        {address && isLocationChanged && (
          <Typography variant="body1">
            <b>Address:</b> {address}
          </Typography>
        )}
        <Button
          fill={isLocationChanged ? "solid" : "inactive"}
          disabled={!isLocationChanged}
          text="Set this Address"
          onClick={handleSubmit}
        />
      </Box>
    </Box>
  );
};

export default OnboardingLocation;

import { createSlice } from "@reduxjs/toolkit"; // PayloadAction, createAsyncThunk

// Initial state for the slice
const initialState = {
  //current_bookings: [],
  current_stats: {
    cash_available: "0",
    bookings: 0,
    cancelled: 0,
    lifetime_balance: "0",
    noShows: 0,
    refers: 0,
    updated: "",
  },
  payouts: [
    {
      cash_value: 0,
      bookings: 0,
      refers: 0,
      noshows: 0,
      cancelled: 0,
      cashout_datetime: "",
      status: "",
    },
  ],
  periodBookings: [
    {
      booking_id: 0,
      start_time: null,
      end_time: null,
      booking_user_id: null,
      status: 0,
    },
  ],
};

// Create the slice
const WalletSlice = createSlice({
  initialState: initialState,
  name: "walletSlice",
  reducers: {
    addWalletDetails(state, action) {
      // console.log("WalletSlice????");
      // console.log("WalletSlice: ", action.payload);
      return action.payload;
      // state.current_bookings = action.payload.current_bookings;
      // state.current_stats = action.payload.current_stats;
      // state.payouts = action.payload.payouts;
      //state.stripebalance = action.payload.stripebalance;
    },
    addPayout: (state, action) => {
      state.payouts.push(action.payload);
    },
  },
});

// Export actions
export const { addWalletDetails, addPayout } = WalletSlice.actions;

// Export the reducer
export default WalletSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

// const initialState: siteInfo = {
//   descr: "",
//   id: null,
//   image: "",
//   location: null,
//   meta: "",
//   name: "",
//   rating: null,
//   status: "",
//   type: "",
//   slug: "",
//   bookingList: null,
// };

const initialState = [];

const SearchSites = createSlice({
  initialState: initialState,
  name: "SearchSitesSlice",
  reducers: {
    addSites(state, action) {
      return action.payload;
    },
  },
});

export const { addSites } = SearchSites.actions;

export default SearchSites.reducer;

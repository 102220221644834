import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { globalStyles } from "../../assets/styles/global";

import LogoutButton from "../Common/Buttons/LogoutButton";
import { Box, Stack } from "@mui/material";

import SlideInPanel from "../Modals/slideInPanel";
import OnboardingLocation from "../Onboarding/OnboardingLocation";

const ProfileSettings = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  // const trainer = useSelector((state: BusinessSelector) => state.bData);
  const address = useSelector((state) => state.location.address);

  const onClick = () => {
    //navigate("/Location");
    setPanelOpen(true); 
  }

  const [isPanelOpen, setPanelOpen] = React.useState(false);
  const onLoadProfilePicSuccess = async () => {
    setPanelOpen(false);
  };

  return (
    <>
      <Stack direction="column" alignItems="center" sx={{ height: "60px" }}>
        <Box style={{ ...globalStyles.label }}>Location: </Box>
        <Box
          onClick={onClick}
          style={{
            ...globalStyles.input,
            // ...(disabled ? { backgroundColor: "#000" } : {}),
            // cursor: !disabled ? "pointer" : "auto",
          }}
        >
          {address.address || " "}
        </Box>
      </Stack>
      <LogoutButton></LogoutButton>
      <SlideInPanel
        isOpen={isPanelOpen}
        closePanel={() => setPanelOpen(false)}
        content={OnboardingLocation}
        onSuccess={onLoadProfilePicSuccess}
      />

    </>
  );
};

export default ProfileSettings;

import { useEffect, useState } from "react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { addRoleID, addUser } from "../../Services/Redux/Slices/User.Slice";
//import { createAuth } from "../../Services/Redux/Slices/Auth.Slice";

// models
import jwtDecode from "jwt-decode";

// utils
import Select from "react-select";

// navigation
import { useNavigate, redirect } from "react-router-dom";

// api
import { updateRole } from "../../Services/APIHandlers/ProfileApi";

import { spacingStyles } from "../../assets/styles/global";
import "../../assets/styles/global.css";

export default function UserType({ onSuccess }) {
  //const [error, setError] = useState();

  const navigation = useNavigate();
  const dispatch = useDispatch();

  const [userType, setUserType] = useState();
  const [userData, setUserData] = useState();

  const options = [
    { value: "Trainer", label: "Trainer" },
    { value: "Fitness Facility", label: "Fitness Facility" },
  ];

  const [token, setToken] = useState("");
  //const token = useSelector((state) => state.auth.token);

  const loader = async () => {
    setUserData(JSON.parse(await localStorage.getItem("user")));

    const token = await localStorage.getItem("token");
    setToken(token);

    if (!token || token === "") {
      localStorage.clear();
      return navigation("/");
    }

    return null;
  };

  useEffect(() => {
    //console.log(token);
    loader();

    // const intialLoad = async () => {
    //   const userInfo = jwtDecode(token);
    //   console.log("UTS 108 - ", userInfo);
    // };

    //console.log(options);

    // intialLoad().catch(console.error);
  }, []);

  const submitType = async (role_id) => {
    console.log(role_id);

    const response = await updateRole(role_id, token);
    console.log(response.data);

    dispatch(addRoleID(role_id));

    handleUserTypeSuccess(role_id);
  };

  const handleUserTypeSuccess = (role_id) => {
    switch (role_id) {
      case 2:
        navigation("/SiteOnboarding");
        break;
      case 3:
        navigation("/TrainerOnboarding");
        break;
      default:
        navigation("/GymDash");
        break;
    }
  };

  return (
    <>
      <div
        className="container"
        style={{
          ...spacingStyles.pt10h,
          ...spacingStyles.formSpace,
          height: "60vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <img
          className="noLogFavicon"
          src={require("../../assets/favicon.png")}
          alt="Favicon"
        />
        <h1 className="h1 mt10 center">User Type</h1>
        <h2>What type of Hopper are you?</h2>
        <div className="select-container">
          <Select
            styles={{ ...customStyles, ...spacingStyles.pt20h }}
            defaultButtonText={userType || "Select your type"}
            options={options}
            onChange={(item) => {
              console.log(item);
              if (item.value === "Trainer") {
                //setUserData({ ...userData, role_id: 3 });
                submitType(3);
              } else if (item.value === "Fitness Facility") {
                //setUserData({ ...userData, role_id: 2 });
                submitType(2);
              } else if (item.value === "Client") {
                //setUserData({ ...userData, role_id: 1 });
                submitType(1);
              } else {
                navigation("/Login");
              }
            }}
          />
        </div>
      </div>
    </>
  );
}

const customStyles = {
  //setTimeout(() => { debugger }, 3000)
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#FFFFFF26",
    borderRadius: 10,
    borderColor: state.isFocused ? "none" : provided.borderColor,
    boxShadow: state.isFocused ? "none" : provided.boxShadow,
    "&:hover": {
      borderColor: "transparent",
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#FFFFFF26",
    position: "unset",
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: "#FFFFFF26",
    color: "white",
  }),
  singleValue: (provided) => ({
    ...provided,

    color: "white",
  }),
};

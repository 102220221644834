import React, { useEffect, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../Navigation/AuthWrapper";

import { useSelector } from "react-redux";
import {
  colors,
  globalStyles,
  spacingStyles,
} from "../../assets/styles/global";
import "../../assets/styles/global.css";
import { Button } from "../Common/Buttons/Button";
import {
  getWalletData,
  getCurrentData,
} from "../../Services/APIHandlers/WalletApi";

const DashWallet = () => {
  const navigation = useNavigate();
  const auth = useContext(AuthContext);
  const wallet = useSelector((state) => state.wallet);
  const user = useSelector((state) => state.user);

  const [showLifetime, setShowLifetime] = useState(false);
  const [balance, setBalance] = useState(200);
  const [lifetimeEarnings, setlifetimeEarnings] = useState(24501);

  useEffect(() => {
    const cashAvailable = Number(wallet?.current_stats?.cash_available);
    if (!isNaN(cashAvailable)) {
      setBalance(cashAvailable);
    }

    const lifetimeBalance = Number(wallet?.current_stats?.lifetime_balance);
    if (!isNaN(lifetimeBalance)) {
      setlifetimeEarnings(lifetimeBalance);
    }
  }, [wallet]);

  useEffect(() => {
    //console.log(allData);

    const fetchAccountData = async () => {
      try {
        const response = await getCurrentData(auth.token);
        //console.log(response);
      } catch (err) {
        // Handle errors appropriately
        // console.error(err);
      }
    };

    fetchAccountData();
  }, []);

  const Tabs = () => (
    <div className="flexRow flexCenter ">
      <div
        style={{
          alignItems: "center",
          padding: 10,
          borderTopLeftRadius: 15,
          borderBottomLeftRadius: 15,
          minWidth: 100,
          backgroundColor: showLifetime
            ? globalStyles.lightBlack.backgroundColor
            : globalStyles.green.color,
          cursor: "pointer",
        }}
        onClick={() => setShowLifetime(false)}
      >
        <div
          style={{
            ...globalStyles.h1,
            fontSize: 23,
            ...(showLifetime ? {} : globalStyles.black),
          }}
        >
          Balance
        </div>
      </div>

      <div
        onClick={() => setShowLifetime(true)}
        style={{
          alignItems: "center",
          padding: 10,
          borderTopRightRadius: 15,
          borderBottomRightRadius: 15,
          minWidth: 150,
          backgroundColor: !showLifetime
            ? globalStyles.lightBlack.backgroundColor
            : globalStyles.green.color,
          cursor: "pointer",
        }}
      >
        <div
          style={{
            ...globalStyles.h1,
            fontSize: 23,
            ...(!showLifetime ? {} : globalStyles.black),
          }}
        >
          Lifetime
        </div>
      </div>
    </div>
  );

  return (
    <div className="dash-box flexCol">
      <Tabs />

      <div
        style={{
          ...globalStyles.mt30,
          ...globalStyles.alignCenter,
          justifyContent: "center",
          backgroundColor:
            !balance && !lifetimeEarnings
              ? globalStyles.black.color
              : globalStyles.green.color,
          alignSelf: "center",
          borderRadius: 15,
          borderColor:
            !balance && !lifetimeEarnings ? globalStyles.orange.color : "",
          borderWidth: !balance && !lifetimeEarnings ? 1 : 0,
          borderStyle: "solid",
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 20,
          paddingRight: 20,
          display: "inline-block",
        }}
      >
        <div
          style={{
            ...globalStyles.h2,
            ...globalStyles.black,
            color:
              !balance && !lifetimeEarnings
                ? globalStyles.orange.color
                : colors.dark_gray.color,
          }}
        >
          {!balance && !lifetimeEarnings
            ? "Time to Hustle and Grind"
            : showLifetime
            ? "Lifetime Earnings"
            : "Current Balance"}
        </div>
        <div
          style={{
            ...globalStyles.black,
            ...globalStyles.fw600,
            fontSize: 50,
            color:
              !balance && !lifetimeEarnings
                ? globalStyles.orange.color
                : colors.dark_gray.color,
          }}
        >
          $
          {!balance && !lifetimeEarnings
            ? 2500.0
            : showLifetime
            ? lifetimeEarnings.toFixed(2)
            : balance.toFixed(2)}
        </div>
      </div>
      {!balance && !lifetimeEarnings && (
        <div
          style={{
            ...globalStyles.mt20,
            ...globalStyles.h1,
            alignSelf: "center",
            textAlign: "center",
            fontWeight: "500",
          }}
        >
          The average monthly income of HopperFit Trainers
        </div>
      )}
      <div
        style={{
          ...globalStyles.pt40,
          alignSelf: "center",
          display: "inline-block",
          minWidth: 300,
        }}
      >
        <div>
          {user.stripe_payoutId === null && (
            <Button
              fill={"inactive"}
              text={"Transfer Funds"}
              onClick={() => {
                navigation("/wallet");
              }}
            />
          )}
        </div>
        <div>
          {user.stripe_payoutId !== null && (
            <Button
              fill={"outline"}
              text={"Manage Wallet"}
              onClick={() => {
                navigation("/wallet");
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashWallet;

import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Navigation/AuthWrapper";
import LoginForm from "../../Components/Forms/LoginForm";
import RegisterForm from "../../Components/Forms/RegisterForm";
import OTPForm from "../../Components/Forms/OTPForm";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { spacingStyles, colors, formStyles } from "../../assets/styles/global";

const AuthComponent = ({ authTog = "Login" , onAuthSuccess, mode = "dark" }) => {
  const [isRegistered, setIsRegistered] = useState(false);
  const [isOTPConfirmed, setIsOTPConfirmed] = useState(false);
  const [authToggle, setAuthToggle] = useState(authTog);
  const [isLogged, setIsLogged] = useState(false);

  const auth = useContext(AuthContext);

  const textStyle =
    mode === "light"
      ? { color: colors.black, inputStyles: formStyles.input_light }
      : { inputStyles: formStyles.input };

  useEffect(() => {
    setIsLogged(auth.token !== "");
  }, [auth.token, isRegistered]);

  useEffect(() => {
    setAuthToggle(authTog);
  }, [authTog]);

  const handleAuthToggle = (value) => {
    setAuthToggle(value);
  };

  const handleRegistrationSuccess = () => {
    setIsRegistered(true);
  };

  const handleOTPSuccess = () => {
    setIsOTPConfirmed(true);
    onAuthSuccess();
  };

  return (
    <div
      style={{
        ...spacingStyles.onboardingMaxWidth,
        color: "#ffffff",

      }}
    >
      {!isRegistered && !isLogged && (
        <>
        <div style={{ ...spacingStyles.pb20 }}>
          {/* <ButtonGroup
            sx={{ width: "100%" }}
            color="primary"
            variant="outlined"
            size="large"
            aria-label="outlined primary button group"
          >
            <Button
              sx={{
                ...(mode === "light"
                  ? localStyles.toggle_right_light
                  : localStyles.toggle_right),
                border:
                  authToggle === "Register"
                    ? "2px solid #ff6d00"
                    : colors.primary_50,
                color: authToggle === "Register" ? textStyle.color : colors.gray,
              }}
              onClick={() => handleAuthToggle("Register")}
            >
              Register
            </Button>
            <Button
              sx={{
                ...(mode === "light"
                  ? localStyles.toggle_left_light
                  : localStyles.toggle_left),
                border:
                  authToggle === "Login"
                    ? "2px solid #ff6d00"
                    : colors.primary_50,
                color: authToggle === "Login" ? textStyle.color : colors.gray,
              }}
              onClick={() => handleAuthToggle("Login")}
            >
              Login
            </Button>
          </ButtonGroup> */}
          </div>
          {authToggle === "Login" && (
            <LoginForm onSuccess={handleRegistrationSuccess} mode={mode} />
          )}
          {authToggle === "Register" && (
            <RegisterForm onSuccess={handleRegistrationSuccess} mode={mode} />
          )}
        </>
      )}
      {isRegistered && !isOTPConfirmed && (
        <div style={{ ...spacingStyles.mauto, ...spacingStyles.pt40 }}>
          <OTPForm onSuccess={handleOTPSuccess} />
        </div>
      )}
    </div>
  );
};

const localStyles = {
  toggle_left: {
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    borderWidth: 2,
    width: "50%",
    color: colors.gray,
    "&:hover": {
      color: "white",
    },
  },
  toggle_right: {
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    borderWidth: 2,
    width: "50%",
    color: colors.gray,
    "&:hover": {
      color: "white",
    },
  },
  toggle_left_light: {
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    borderWidth: 2,
    width: "50%",
    color: colors.gray,
    "&:hover": {
      color: "black",
    },
  },
  toggle_right_light: {
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    borderWidth: 2,
    width: "50%",
    color: colors.gray,
    "&:hover": {
      color: "black",
    },
  },
};

export default AuthComponent;

function timeConverter(time) {
  let dateTime = new Date();
  let timeArray = time.split(":");
  //console.log(time);

  dateTime.setHours(parseInt(timeArray[0]));
  dateTime.setMinutes(parseInt(timeArray[1]));
  dateTime.setSeconds(parseInt(timeArray[2]));
  return dateTime;
}

export default timeConverter;

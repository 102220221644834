import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import moment, { weekdays } from "moment";
import {
  selectDate,
  selectTime,
} from "../../Services/Redux/Slices/Booking.Slice.js";

import {
  hasPast,
  hasAvailability,
  isToday,
  isBlackOut,
  isSelectDate,
} from "../../Services/Helpers/CalendarHelpers.js";
import {
  colors,
  calendarStyles,
  spacingStyles,
} from "../../assets/styles/global.js";
import { Button } from "../Common/Buttons/Button.js";
import { useNavigate } from "react-router";
import ImageCarousel from "../Common/ImageCarousel.js";

const DashBookingList = ({ schedule }) => {
  const navigation = useNavigate();
  const daySelect = useSelector((state) => state.booking.selectedDate);
  const bookingData = useSelector((state) => state.sites);
  const now = new Date(Date.now());
  now.setHours(0, 0, 0, 0);

  const handleClick = (booking) => {};

  const swiperImages = useMemo(() => {
    if (!Array.isArray(bookingData)) return [];

    return bookingData?.slice(0, 10);
  }, [bookingData]);

  return (
    <div>
      <div style={{ margin: 0, padding: 0 }}>
        <h3 className="left" style={{ margin: 0, ...spacingStyles.pb10 }}>
          {moment(daySelect).format("dddd, MMMM DD")}
        </h3>
        <div>
          <h4 style={{ ...colors.gray }}>No bookings on this day.</h4>
        </div>


        <div className="flexRow" style={{ gap: 20, padding: 10 }}>
          <Button
            fill="outline"
            text="Manage Bookings"
            onClick={() => navigation("/Bookings")}
          />
          {/* <Button
            fill="solid"
            text="Search"
            onClick={() => navigation("/Search")}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default DashBookingList;

import React, { useState } from "react";
import { useSelector } from "react-redux";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";

import { IoIosCheckmarkCircle } from "react-icons/io";

import { spacingStyles, colors } from "../../assets/styles/global";
import "../../assets/styles/global.css";

import config from "../../config";

const ImageUploader = ({ ratio = 1, onSuccess }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [crop, setCrop] = useState({
    unit: "px",
    minWidth: 160,
    minHeight: 90,
    aspect: 1.778,
  });
  const [previewImage, setPreviewImage] = useState(null);

  const token = useSelector((state) => state.auth.token);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);

    // Create a preview URL for the selected image
    const previewURL = URL.createObjectURL(file);
    setPreviewImage(previewURL);
  };

  const handleUpload = async () => {
    if (selectedImage) {
      const formData = new FormData();
      formData.append("file", selectedImage);
      formData.append("token", token);

      // Add other data to formData if needed
      // formData.append('directory', 'your_directory');

      try {
        const response = await fetch(
          config.apiUrl + "Upload/singlePhotoUpload",
          {
            method: "POST",
            body: formData,
          }
        );

        console.log(response);

        if (response.ok) {
          const result = await response.json();
          console.log("Upload successful:", result);
          // Handle the response as needed
          onSuccess(result.data.picture_id);
          //console.log(onSuccess());
          setSelectedImage(null);
        } else {
          console.error("Upload failed:", response.statusText);
          // Handle the error
        }

        setCrop(crop);

      } catch (error) {
        console.error("Error during upload:", error.message);
        // Handle the error
      }
    } else {
      console.warn("No image selected");
    }
  };

  return (
    <div>
      {selectedImage && (
        <div>
          <ReactCrop
            crop={crop}
            onChange={(c) => {
              c.aspect = 1.778;
              c.unit = "px";
              setCrop(c);
            }}
          >
            <img src={previewImage} alt="crop" />
          </ReactCrop>
        </div>
      )}

      {!selectedImage && (
        <div className="upload-box">
          <label className="upload-box-label flexCol flexCenter">
            <PhotoCameraBackIcon sx={{ fontSize: 80 }} />
            <input type="file" onChange={handleImageChange} />
            <i className="fa fa-cloud-upload"></i>Upload Image
          </label>
        </div>
      )}

      {/* {previewImage && (
        <div>
          <p>Preview</p>
          <img src={previewImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '200px' }} />
        </div>
      )} */}
      {selectedImage && (
        <div className="upload-confirm">
          <IoIosCheckmarkCircle
            size={37}
            color={colors.green.color}
            onClick={handleUpload}
          ></IoIosCheckmarkCircle>
        </div>
      )}
    </div>
  );
};

export default ImageUploader;

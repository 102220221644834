import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useMemo, useRef, useState } from "react";

export default function CustomDateTimePicker({
  isVisible,
  date,
  onConfirm,
  onClose,
}) {
  const datePickerRef = useRef();

  const value = useMemo(() => moment(date), [date]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        open={isVisible}
        ref={datePickerRef}
        value={value}
        onChange={onConfirm}
        onClose={onClose}
        maxDate={moment()}
        renderInput={(inputProps) => (
          <TextField {...inputProps} variant="outlined" />
        )}
      />
    </LocalizationProvider>
  );
}

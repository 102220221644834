import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../Common/Buttons/Button"; // Custom Button
import { Box, TextField, Typography } from "@mui/material";
import { addEmail } from "../../Services/Redux/Slices/User.Slice";
import { saveToken } from "../../Services/Redux/Slices/Auth.Slice";
import { LoginHandler, verifyLogin } from "../../Services/APIHandlers/AuthApi";

const LoginForm = ({ onSuccess, mode = "dark" }) => {
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const email = useSelector((state) => state.user.email);

  const textStyle = mode === "light" ? { color: "#000" } : { color: "#fff" };

  const submitEmail = async () => {
    if (!validateEmail(email)) {
      setError("Invalid Email Address");
      return;
    }
    console.log("Submit Email");
    try {
      const response = await LoginHandler(email);
      const data = response.data;
      if (data.error) {
        const loginResponse = await verifyLogin(email);
        dispatch(saveToken(loginResponse?.data?.verify_token));
        onSuccess();
        return;
      } else {
        setError(data.error);
        return;
      }
    } catch (err) {
      setError("Login Error. Please Contact Admin.");
      console.error(err);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  return (
    <Box sx={{ maxWidth: "sm", mx: "auto", padding: "1rem" }}>
      <Typography variant="h4" sx={{ color: textStyle.color }}>
        Login
      </Typography>
      {!error && (
        <Typography sx={{ color: textStyle.color }}>
          Login with just your email address.
        </Typography>
      )}
      {error && <Typography sx={{ color: "red" }}>{error}</Typography>}
      <Box sx={{ mt: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          label="Email"
          error={!!error}
          onChange={(e) => dispatch(addEmail(e.target.value))}
          autoFocus
          InputProps={{
            style: { color: textStyle.color },
          }}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Button fill="solid" text="Login" onClick={submitEmail} />
      </Box>
    </Box>
  );
};

export default LoginForm;

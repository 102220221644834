import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import timeConverter from "../../Services/Helpers/TimeConverter";
import formatTime from "../../Services/Helpers/FormatTime";

import {
  colors,
  globalStyles,
  calendarStyles,
} from "../../assets/styles/global";

import { addSchedule } from "../../Services/Redux/Slices/Schedule.Slice";

import { updateSchedule } from "../../Services/APIHandlers/ScheduleApi";
import { Button } from "../Common/Buttons/Button";

const LegendItem = ({ color, label }) => {
  return (
    <div
      style={{
        ...styles.legendItem,
        ...globalStyles.mt10,
        ...globalStyles.ml5,
        ...globalStyles.mr5,
      }}
    >
      <div style={{ ...styles.colorSquare, backgroundColor: color }} />
      <div style={globalStyles.h2}>{label}</div>
    </div>
  );
};

const CustomComponent = ({ schedule, daySelect }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userToken);

  function isSelectTime(timeIDX) {
    let dayIDX = 0;
    switch (daySelect) {
      case "Sun":
        dayIDX = 0;
        break;
      case "Mon":
        dayIDX = 1;
        break;
      case "Tue":
        dayIDX = 2;
        break;
      case "Wed":
        dayIDX = 3;
        break;
      case "Thu":
        dayIDX = 4;
        break;
      case "Fri":
        dayIDX = 5;
        break;
      case "Sat":
        dayIDX = 6;
        break;
      default:
        break;
    }

    if (scheduleData?.[daySelect][timeIDX].select == true) {
      return true;
    } else {
      return false;
    }
  }

  //const schedule = useSelector((state: ScheduleSelector) => state.schedule);
  const [scheduleData, setSchedule] = useState(schedule);
  const [timeChanged, settimeChanged] = useState(false);

  const handleSubmit = async () => {
    try {
      const response = await updateSchedule(
        JSON.stringify(scheduleData),
        token
      );

      if (response.data.success) {
        navigate(-1);
      } else {
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div
        style={{
          ...globalStyles.h3,
          ...globalStyles.white,
          ...globalStyles.pt10,
          ...globalStyles.center,
          ...globalStyles.pb10,
        }}
      >
        Select available times (ALL that apply)
      </div>

      <div
        style={{
          ...globalStyles.flexRow,
          ...globalStyles.flexWrap,
          ...globalStyles.fullWidth,
          ...globalStyles.p10,
          backgroundColor: "#031a27",
          borderRadius: 15,
        }}
      >
        {scheduleData[daySelect] &&
          scheduleData[daySelect].map((timeObj, timeIDX) => {
            if (timeConverter(timeObj.time) <= timeConverter("11:30:00")) {
              return (
                <div
                  key={timeIDX}
                  style={{
                    ...globalStyles.m5,
                    ...globalStyles.p3,
                    display: "flex",
                    flex: 1,
                    flexBasis: "20%",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    ...styles.time,
                    ...(isSelectTime(timeIDX)
                      ? calendarStyles.selectTime
                      : null),
                  }}
                  onClick={() => {
                    const updatedSchedule = {
                      ...scheduleData,
                      [daySelect]: [
                        ...scheduleData[daySelect].map((item, index) =>
                          index === timeIDX
                            ? { ...item, select: !item.select }
                            : item
                        ),
                      ],
                    };
                    setSchedule(updatedSchedule);
                    settimeChanged(true);
                  }}
                >
                  <div
                    style={{
                      ...colors.gray,

                      fontSize: 16,
                      fontWeight: "600",

                      ...(timeObj.select == true ? colors.white : null),
                    }}
                  >
                    {formatTime(timeObj.time)}
                  </div>
                </div>
              );
            }
          })}
      </div>
      <div
        style={{
          borderTopWidth: 3,
          borderTopColor: colors.gray,
          marginTop: 10,
          marginBottom: 10,
          borderRadius: 15,
        }}
      >
        {/* Content */}
      </div>
      <div
        style={{
          ...globalStyles.flexRow,
          ...globalStyles.flexWrap,
          ...globalStyles.fullWidth,
          ...globalStyles.p10,
          backgroundColor: "#150327",
          borderRadius: 15,
        }}
      >
        {scheduleData[daySelect] &&
          scheduleData[daySelect].map((timeObj, timeIDX) => {
            if (timeConverter(timeObj.time) > timeConverter("11:30:00")) {
              return (
                <div
                  key={timeIDX}
                  style={{
                    ...globalStyles.m5,
                    ...globalStyles.p3,
                    flex: 1,
                    flexBasis: "20%",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    ...styles.time,
                    ...(isSelectTime(timeIDX)
                      ? calendarStyles.selectTime
                      : null),
                  }}
                  onClick={() => {
                    const updatedSchedule = {
                      ...scheduleData,
                      [daySelect]: [
                        ...scheduleData[daySelect].map((item, index) =>
                          index === timeIDX
                            ? { ...item, select: !item.select }
                            : item
                        ),
                      ],
                    };
                    setSchedule(updatedSchedule);
                    settimeChanged(true);
                  }}
                >
                  <div
                    style={{
                      ...colors.gray,

                      fontSize: 16,
                      fontWeight: "600",

                      ...(timeObj.select == true ? colors.white : null),
                    }}
                  >
                    {formatTime(timeObj.time)}
                  </div>
                </div>
              );
            }
            return null;
          })}
      </div>

      <div style={{ ...globalStyles.footer, ...globalStyles.pb20 }}>
        <div style={{ ...styles.legendContainer, ...globalStyles.mb10 }}>
          <LegendItem
            color={String(globalStyles.orange.color)}
            label="Selected"
          />
          <LegendItem color={"#FFffff"} label="Available" />
        </div>
        <div style={{ width: "100%", marginBottom: 50 }}>
          <Button
            fill="solid"
            text="Save"
            onClick={() => {
              dispatch(addSchedule(scheduleData));
              handleSubmit();
            }}
            disabled={!timeChanged}
            //disabled={!isChanged}
          ></Button>
        </div>
      </div>
    </>
  );
};

export default CustomComponent;

const styles = {
  time: {
    backgroundColor: "#000",
    borderColor: colors.gray.color,
    // borderWidth: 1,
    borderRadius: 10,
  },
  legendContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  selectTime: {
    borderColor: "#C4C4C4",
    backgroundColor: "#FFffff26",
    //borderWidth: 1,
    borderRadius: 10,
    color: "#fff",
    padding: 5,
  },
  legendItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  colorSquare: {
    width: 16,
    height: 16,
    borderRadius: 4,
    marginRight: 8,
  },
};

import React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Link } from "@mui/material"; // MUI components for layout and typography
import LoginForm from "../../Components/Forms/LoginForm";
import jwtDecode from "jwt-decode";

import { spacingStyles, colors } from "../../assets/styles/global";
import CleanWrapper from "../../Components/Common/CleanWrapper";
import AnimatedBackground from "../../Components/Backgrounds/SlidingDiagonals";

function Login() {
  const navigation = useNavigate();
  const theme = useTheme(); // Access the current theme
  const mode = theme.palette.mode; // Determine if the mode is 'dark' or 'light'

  const handleLoginSuccess = (role_id) => {
    navigation("/OTP");
  };

  const checkToken = async () => {
    const token = await localStorage.getItem("token");
    if (token) {
      const userInfo = jwtDecode(token);
      handleLoginSuccess(userInfo.role_id);
    } else {
      navigation("/Register");
    }
  };

  return (
    <CleanWrapper>

      <AnimatedBackground />

      <div className="container guest-two-col" >
      <Box className="side-display">
          <Box
            component="img"
            alt="Login"
            src={theme.palette.mode === 'light' 
              ? require('../../assets/auth_display_light.jpg') 
              : require('../../assets/auth_display.jpg')}
            sx={{
              opacity: '50%', 
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
          {/* Gradient Overlay */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              // background: theme.palette.mode === 'light'
              // ? 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 100%)'
              // : 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%)',
            }}
          />
        </Box>
        <Box className="auth-contain-two-col">
          <LoginForm onSuccess={handleLoginSuccess} mode={mode} />
          <Box sx={{ paddingBottom: "40px" }}>
            <Typography variant="h6" sx={{ paddingTop: "10px", color: theme.palette.text.secondary }}>
              Not registered yet?{" "}
              <Link
                component="button"
                onClick={checkToken}
                sx={{ color: colors.primary.color, paddingLeft: "5px" }}
              >
                Register Here
              </Link>
            </Typography>
          </Box>
        </Box>

      </div>
    </CleanWrapper>
  );
}

export default Login;

import React from "react";
// import { useNavigate } from "react-router-dom";

import { spacingStyles } from "../../assets/styles/global";

import OTPForm from "../../Components/Forms/OTPForm";

export default function OTP() {
  // const navigation = useNavigate();

  const handleOTPSuccess = (role_id) => {
    // switch (role_id) {
    //   case "2":
    //     navigation("/GymDash");
    //     break;
    //   case "3":
    //     navigation("/TrainerDash");
    //     break;
    //   default:
    //     navigation("/UserType");
    //     break;
    // }
  };

  return (
    <div
      className="container"
      style={{ ...spacingStyles.pt10h, ...spacingStyles.formSpace }}
    >
      <img
        className="noLogFavicon"
        src={require("../../assets/favicon.png")}
        alt="Favicon"
      />
      <h1 className="h1 mt10 center">Verify your ID</h1>

      <OTPForm onSuccess={handleOTPSuccess} />
    </div>
  );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateSpaceDetails } from "../../APIHandlers/ProfileApi"; // adjust the path as necessary

// Async thunk for updating space details
export const updateSpaceDetailsAsync = createAsyncThunk(
  "Space/updateSpaceDetails",
  async ({ data, token }, { dispatch, getState, rejectWithValue }) => {
    try {
      //console.log("data: ", data);
      const response = await updateSpaceDetails(data, token);
       //console.log("response: ",response);
      // You can dispatch a success message if needed
      // dispatch(showSuccessMessage("Space details updated successfully"));
      return response.data;
      //return false;
    } catch (error) {
      console.log(error);
      // You can dispatch an error message if needed
      // dispatch(showErrorMessage("Failed to update space details"));
      return rejectWithValue(error.response.data);
    }
  }
);
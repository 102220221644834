import React, { useMemo } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function GuestRoute({ children }) {
  const auth = useSelector((state) => state.auth);

  const userTypeSet = useMemo(() => {
    if (!auth.isAuthenticated) return true;

    return localStorage.getItem("userTypeSet");
  }, [auth]);

  const isAuthenticated = auth.isAuthenticated;

  if (isAuthenticated && !userTypeSet) {
    const role_id = String(auth.role_id);
    switch (role_id) {
      case "2":
        console.log("Navigate to SiteOnboarding", children.type.name);
        //  return <>{children}</>; 
        return children.type.name === "SiteOnboarding" ? <>{children}</> : <Navigate to="/SiteOnboarding" />;
      // return <Navigate to="/SiteOnboarding" />;
      case "3":
        return <Navigate to="/TrainerOnboarding" />;
      default:
        return <Navigate to="/UserType" />;
    }
  }


  if (!isAuthenticated && children.type.name === "SiteOnboarding") {
    return <Navigate to="/Login" /> ;
  }


  return isAuthenticated ? <Navigate to="/TrainerDash" /> : <>{children}</>;
}

export default GuestRoute;

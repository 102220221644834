import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import OnboardingTemplate from "../../../Components/Onboarding/OnboardingTemplate";
import Space from "../../../Components/Onboarding/Space";

import { selectOnboardingState } from "../../../Services/Redux/Slices/Onboarding.Slice";
import { updateSpaceDetailsAsync } from "../../../Services/Redux/Thunks/updateSpaceDetails";

const SpaceScreen = ({ nextAction, previousAction, screenNum = 1, screenTotal = 4 }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const onboardingState = useSelector(selectOnboardingState); // Select the onboarding state

  const saveResponses = async () => {
    try {
      const response = await dispatch(updateSpaceDetailsAsync({ data: onboardingState, token }));
      if (response?.payload) { // Check if response and payload exist
        return response.payload;
      } else {
        throw new Error("No data returned from saveResponses");
      }
    } catch (error) {
      console.error("Failed to save responses:", error);
      return null; // Return null or handle the error as needed
    }
  };

  // // useEffect to trigger saveResponses when component mounts or state changes
  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     saveResponses();
  //   }, 1000); // Debounce save operation by 1 second

  //   return () => clearTimeout(timeoutId); // Cleanup timeout on unmount or before the next effect
  // }, [onboardingState]); // Watch for changes in onboarding state

  return (
    <>
      <OnboardingTemplate
        component={<Space />}
        screenNum={screenNum}
        screenTotal={screenTotal}
        nextAction={nextAction}
        previousAction={previousAction}
        title={"Space Details"}
        hideProgressBar={false}
        saveResponses={saveResponses}
      />
    </>
  );
};

export default SpaceScreen;

import React, { useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Stack, TextField, Button } from "@mui/material";
import OnboardingTemplate from "../../Components/Onboarding/OnboardingTemplate";
import { colors, globalStyles } from "../../assets/styles/global";

import { updateSpaceDetailsAsync } from "../../Services/Redux/Thunks/updateSpaceDetails";

import { setSpecialInstructions, setSpecialRules, setSpecialAccess, selectOnboardingState } from "../../Services/Redux/Slices/Onboarding.Slice";

const SpecialInstructions = ({ nextAction, previousAction, screenNum = 1, screenTotal = 1 }) => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const { instructions } = useSelector(selectOnboardingState);

    const onboardingState = useSelector(selectOnboardingState); // Select the onboarding state

    const saveResponses = async () => {
      try {
        const response = await dispatch(updateSpaceDetailsAsync({ data: onboardingState, token }));
        if (response?.payload) { // Check if response and payload exist
          return response.payload;
        } else {
          throw new Error("No data returned from saveResponses");
        }
      } catch (error) {
        console.error("Failed to save responses:", error);
        return null; // Return null or handle the error as needed
      }
    };


    return (
        <OnboardingTemplate
            component={
                <Stack
                    spacing={10}
                    style={{ ...globalStyles.textAlignLeft }}
                >

                    <Stack
                        spacing={2}
                    >
                        <h2>Got anything we should know about? </h2>
                        <p style={{ ...colors.gray }}>Does your space have something unique that people should know about? for example: is your main entrance in the rear?</p>
                        <TextField
                            className="input"
                            label="Special Instructions...."
                            //value={instructions.special_instructions ?? ""}
                            onChange={(e) =>  dispatch(setSpecialInstructions(e.target.value))}
                            fullWidth
                            multiline
                            rows={3}
                        />
                    </Stack>
                    <Stack
                        spacing={2}
                    >
                        <h2>Add your own house rules in your Host Use Agreement </h2>
                        <p style={{ ...colors.gray }}>This is where you can add your own special rules and users will agree to them when they book your space.</p>
                        <TextField
                            className="input"
                            label="Rules for Hoppers..."
                            //value={instructions.special_rules ?? ""}
                            onChange={(e) =>  dispatch(setSpecialRules(e.target.value))}
                            fullWidth
                            multiline
                            rows={3}
                        />
                    </Stack>
                    <Stack
                        spacing={2}
                    >
                        <h2>Instructions for Hoppers </h2>
                        <p style={{ ...colors.gray }}>Tell users what they need to know in order to use your space when they start their booking. Is there a door code to get access to the space? Do they have to check in with a specific person? The more details you provide will limit any confusion at the start of their booking. (Information entered in this field will be sent to users 24 hours prior to the start of their booking.)</p>
                        <TextField
                            className="input"
                            label="Instructions for Hoppers..."
                            //value={instructions.special_access ?? ""}
                            onChange={(e) => dispatch(setSpecialAccess(e.target.value))}
                            fullWidth
                            multiline
                            rows={3}
                        />
                    </Stack>
                </Stack>
            }
            screenNum={screenNum}
            screenTotal={screenTotal}
            nextAction={nextAction}
            previousAction={previousAction}
            title="Special Instructions"
            saveResponses={saveResponses}
        />
    );
};

export default SpecialInstructions;
import { useSelector } from "react-redux";

import React, { useEffect, useState } from "react";
import moment from "moment"; // Import Moment.js

import { globalStyles, calendarStyles } from "../../assets/styles/global.js";
import {
  isSelectDate,
  isToday,
  hasPast,
  hasAvailability,
  getMonthDays,
} from "../../Services/Helpers/CalendarHelpers.js";

const TwoWeekDaySelect = ({
  schedule,
  handleSelectDay,
  showSingleWeek = false,
  showFullMonth = false,
}) => {
  const [defaultMonth, setDefaultMonth] = useState(null);
  const [displayMonths, setDisplayMonths] = useState([]);
  const selectedDate = useSelector((state) => state.booking.selectedDate);

  useEffect(() => {
    setMonths();
  }, []);

  const setMonths = () => {
    const now = moment(); // Use Moment.js for current date
    const month1 = moment(now).startOf("month"); // Start of current month
    const month2 = moment(month1).add(1, "month"); // Start of next month
    setDisplayMonths([month1, month2]);
    setDefaultMonth(month1);
  };

  const isWeekActive = (weekArray) => {
    const now = moment(); // Use Moment.js for current date

    if (showFullMonth) {
      return weekArray;
    }

    if (showSingleWeek) {
      // Only show single week
      const future = moment(now).endOf("week").endOf("day");
      return weekArray.some((day) => {
        return day.isBetween(now, future, null, "[]"); // Check if day is between now and 8 days later
      });
    }

    const future = moment(now).add(8, "days").startOf("day");
    return weekArray.some((day) => {
      return day.isBetween(now, future, null, "[]"); // Check if day is between now and 8 days later
    });
  };

  const getDays = (start) => {
    let weeks = [];
    displayMonths.forEach((month) => {
      let monthDays = getMonthDays(month); // Assuming getMonthDays returns Week[]
      weeks.push(...monthDays);
    });
    return weeks;
  };

  const renderWeek = (week, monthIndex, weekIndex) => {
    return (
      isWeekActive(week) && (
        <div
          style={{ width: "100%", gap: 5, ...globalStyles.flexRow, ...globalStyles.flexCenter }}
          key={`${monthIndex}-${weekIndex}`}
        >
          {week.map((day, dayIndex) => (
            <div style={{ width: "13%" }} key={dayIndex}>
              <div
                style={{
                  overflow: "hidden",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (!hasPast(day) && !isToday(day)) {
                    handleSelectDay(day);
                  }
                }}
              >
                <div
                  style={{
                    ...calendarStyles.month_day,
                    ...(hasPast(day) ? calendarStyles.pastDay : {}),
                    ...(isToday(day) ? calendarStyles.today : {}),
                    ...(!hasPast(day) ? calendarStyles.comingDay : {}),
                    ...(hasAvailability(day, schedule) && !hasPast(day)
                      ? calendarStyles.availableDay
                      : {}),
                    ...(selectedDate !== null &&
                    selectedDate !== undefined &&
                    isSelectDate(selectedDate, day)
                      ? calendarStyles.selectDay
                      : {}),
                    fontSize: 15,
                    borderRadius: 15,
                  }}
                >
                  {day.format("DD")}
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    );
  };

  return (
    <div style={{ ...globalStyles.w100 }}>
      <div
        style={{
          width: "100%",
          ...globalStyles.bbg,
          ...globalStyles.flexRow,
          ...globalStyles.flexCenter,
          ...globalStyles.pb10,
          ...globalStyles.mb10,
        }}
      >
        {["Su", "M", "Tu", "W", "Th", "F", "Sa"].map((dayHeader, index) => (
          <div key={index} style={{ width: "14%" }}>
            <div style={{ textAlign: "center", ...calendarStyles.short_day }}>
              {dayHeader}
            </div>
          </div>
        ))}
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        {defaultMonth &&
          getDays(defaultMonth)
            .slice(0, 6)
            .map((week, weekIndex) => renderWeek(week, 0, weekIndex))}
      </div>
    </div>
  );
};

export default TwoWeekDaySelect;

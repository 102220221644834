import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";

//styles
import {
  globalStyles,
  spacingStyles,
  colors,
} from "../../../assets/styles/global";

//components
import TrainerDetailsHeader from "./TrainerDetailsHeader";
import { useState } from "react";
import StarIcon from "@mui/icons-material/Star";

import DetailsSchedule from "../../../Components/Calendar/DetailsSchedule";
import { Button } from "../../../Components/Common/Buttons/Button";
import { getSiteData } from "../../../Services/APIHandlers/BookingApi";
import { Container } from "@mui/material";
import SlideInPanel from "../../../Components/Modals/slideInPanel";
import Checkout from "../../Public/Checkout";

const CalendarSelect = ({ schedule }) => {
  const [currentCalendar, setCurrentCalendar] = useState("This Week");

  const Tab = ({ label }) => {
    const selected = currentCalendar === label;

    return (
      <div
        style={{
          ...globalStyles.centerView,
          // width: "47%",
          backgroundColor: !selected ? "transparent" : colors.green.color,
          borderRadius: 50,
          margin: 4,
          cursor: "pointer",
        }}
        onClick={() => setCurrentCalendar(label)}
      >
        <div
          style={{
            color: !selected ? colors.green.color : colors.black.color,
            fontWeight: "bold",
            padding: 10,
            fontSize: 15,
          }}
        >
          {label}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: colors.dark_gray.color,
          borderRadius: 50,
          width: "70%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 25,
          marginBottom: 25,
        }}
      >
        <Tab label="This Week" />
        <Tab label="This Month" />
      </div>

      <DetailsSchedule
        schedule={schedule}
        heading=""
        showSingleWeek={currentCalendar === "This Week"}
        showFullMonth={currentCalendar === "This Month"}
      />
    </>
  );
};

const TrainerDetails = () => {
  const { slug } = useParams();
  const Gym = useSelector((state) => state.booking.selectedSite);

  const token = useSelector((state) => state.auth.token);

  const selectedDate = useSelector((state) => state.booking.selectedDate);
  const selectedTime = useSelector((state) => state.booking.selectedTime);

  const daySelect = useSelector((state) => state.booking.selectedDate);
  const timeSelect = useSelector((state) => state.booking.selectedTime);

  const [isPanelOpen, setPanelOpen] = useState(false);

  const openPanel = () => {
    setPanelOpen(true);
  };

  const closePanel = () => {
    setPanelOpen(false);
  };

  const [scheduleData, setSchedule] = useState({});

  const dispatch = useDispatch();

  const getSchedule = async () => {
    const response = await getSiteData(slug || Gym.slug);
    console.log("..response", JSON.stringify(response.data));

    if (response.status == 200) {
      setSchedule(response.data.publicData.calendar.schedule);
    }
  };

  useEffect(() => {
    getSchedule();
  }, []);

  //cconsole.log(Gym)
  return (
    <Container maxWidth="md">
      <div
        style={{
          ...globalStyles.p10,
          ...globalStyles.bbg,
        }}
      >
        <div>
          {Gym && <TrainerDetailsHeader Gym={Gym} showProfilePic={true} />}

          <div style={{ justifyContent: "center", alignItems: "center" }}>
            <div
              style={{
                ...globalStyles.h1,
                ...globalStyles.mt20,
                color: colors.primary.color,
                fontWeight: "bold",
              }}
            >
              Book Now
            </div>

            <div
              style={{
                ...globalStyles.h3,
                ...globalStyles.mt10,
                textAlign: "center",
              }}
            >
              Select a date and time for your training session with this trainer
            </div>
          </div>

          <CalendarSelect schedule={scheduleData} />
        </div>
      </div>

      <div className="footer">
        <div
          className="flexRow flexAround footer-inner"
          style={{ ...spacingStyles.w100 }}
        >
          <div className="flexCol centerDiv">
            <div className="flexRow price">
              <span className="h1 white" style={{ fontSize: 45 }}>
                $15
              </span>
              <span className=" white" style={{ ...spacingStyles.pl5 }}>
                /session
              </span>
            </div>
          </div>
          <div
            style={{ ...spacingStyles.w50 }}
            onClick={() => {
              if (daySelect !== null && timeSelect !== null) {
                openPanel();
              }
            }}
          >
            <Button
              fill={
                daySelect === null || timeSelect === null ? "outline" : "solid"
              }
              text="Book Now"
              disabled={daySelect === null || timeSelect === null}
            ></Button>
          </div>
        </div>
      </div>
      {daySelect !== null && timeSelect !== null && (
        <SlideInPanel
          isOpen={isPanelOpen}
          closePanel={closePanel}
          content={() => <Checkout isOpen={isPanelOpen} />}
        />
      )}
    </Container>
  );
};

export default TrainerDetails;

import { createSlice } from "@reduxjs/toolkit";
import { updateSpaceDetailsAsync } from "../Thunks/updateSpaceDetails";

const initialState = {
  space: {
    sqFeet: 0,
    dedicatedSpace: false,
    dedicatedSqFeet: 0,
    chargeRate: "",
    chargeDaily: "",
  },
  spaceType: {
    indoor: false,
    outdoor: false,
  },
  capacity: 0,
  floorCovering: {
    wood: false,
    rubber: false,
    turf: false,
    foam: false,
    concrete: false,
    spring: false,
  },
  equipment: {
    treadmill: false,
    eliptical: false,
    spinBikes: false,
    machines: false,
    freeWeights: false,
    dumbells: false,
    rowMachine: false,
    pullupBar: false,
    punchingBags: false,
    crossTraining: false,
    floorMats: false,
    stairMaster: false,
  },
  amenities: {
    pool: false,
    indoorTrack: false,
    outdoorTrack: false,
    indoorField: false,
    outdoorField: false,
    basketballCourt: false,
    tennisCourt: false,
    racketballCourt: false,
    volleyballCourt: false,
    danceFloor: false,
    aerialYoga: false,
    dancePoles: false,
    trampoline: false,
  },
  extras: {
    bathroom: false,
    changingArea: false,
    waterFountain: false,
    sauna: false,
    steamRoom: false,
    coldPlunge: false,
    soundSystems: false,
  },
  instructions: {
    special_instructions: false,
    special_rules: false,
    special_access: false,
  },
};

const OnboardingSlice = createSlice({
  name: "onboarding",
  initialState: initialState,
  reducers: {
    updateSqFeet(state, action) {
      state.space.sqFeet = action.payload;
    },
    setDedicatedSpace(state, action) {
      //console.log("DedicatedSpace", action.payload); 
      state.space.dedicatedSpace = action.payload;
    },
    setDedicatedSqFeet(state, action) {
      state.space.dedicatedSqFeet = action.payload;
    },
    setIndoor(state, action) {
      state.spaceType.indoor = action.payload;
    },
    setOutdoor(state, action) {
      state.spaceType.outdoor = action.payload;
    },
    setCapacity(state, action) {
      state.capacity = action.payload;
    },
    setFloorCoverings(state, action) {
      state.floorCovering = action.payload;
    },
    setEquipment(state, action) {
      state.equipment = action.payload;
    },
    setAmenities(state, action) {
      state.amenities = action.payload;
    },
    setExtras(state, action) {
      state.extras = action.payload;
    },
    setChargeRate(state, action) {
      state.space.chargeRate = action.payload;
    },
    setchargeDaily(state, action) {
      state.space.chargeDaily = action.payload;
    },
    setSpecialInstructions(state, action) {
      //console.log("update special_instructions: ", action.payload ); 
      state.instructions.special_instructions = action.payload;
    },
    setSpecialRules(state, action) {
      //console.log("update special_rules: ", action.payload ); 
      state.instructions.special_rules = action.payload;
    },
    setSpecialAccess(state, action) {
      //onsole.log("update special_access: ", action.payload ); 
      state.instructions.special_access = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateSpaceDetailsAsync.pending, (state) => {
        // // Handle loading state if necessary
        // state.loading = true;
      })
      .addCase(updateSpaceDetailsAsync.fulfilled, (state, action) => {
        // console.log("Onboarding Update:", action.payload);
        // Update the state based on the response
        // state.space = action.payload.space || state.space;
        // state.spaceType = action.payload.spaceType || state.spaceType;
        // state.capacity = action.payload.capacity || state.capacity;
        // state.floorCovering = action.payload.floorCovering || state.floorCovering;
        // state.equipment = action.payload.equipment || state.equipment;
        // state.amenities = action.payload.amenities || state.amenities;
        // state.extras = action.payload.extras || state.extras;
        // state.loading = false;
      })
      .addCase(updateSpaceDetailsAsync.rejected, (state, action) => {
        // Handle errors if necessary
        // state.loading = false;
        // state.error = action.payload;
      });
  },
});

export const {
  updateSqFeet,
  setDedicatedSpace,
  setDedicatedSqFeet,
  setIndoor,
  setOutdoor,
  setCapacity,
  setFloorCoverings,
  setEquipment,
  setAmenities,
  setExtras,
  setChargeRate,
  setchargeDaily,
  setSpecialInstructions,
  setSpecialRules,
  setSpecialAccess,
} = OnboardingSlice.actions;

export const selectOnboardingState = (state) => state.onboarding;

export default OnboardingSlice.reducer;

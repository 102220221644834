import React, { useContext } from "react";
import { Box, Typography, Container, List, ListItem, ListItemIcon, useTheme } from "@mui/material";
import { FaCamera, FaInfoCircle, FaCalendarAlt } from 'react-icons/fa';
import { Button } from "../../../Components/Common/Buttons/Button";

const Setup = ({ role, goToStep }) => {
  const theme = useTheme(); // Get the MUI theme, light or dark mode

  return (
    <Container>
      <Box
        sx={{
          height: "100%",
          paddingLeft: 2,
          paddingRight: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box className="intro-details">
            <Typography 
              variant="h4" 
              gutterBottom 
              color={theme.palette.text.primary} // Ensure text color changes with theme
            >
              List Your Space
            </Typography>
            <Typography 
              variant="body1" 
              gutterBottom 
              color={theme.palette.text.secondary} // Set secondary text color
            >
              We will guide you through the process.
            </Typography>

            <List sx={{ mb: 2, padding: 0 }}>
              <ListItem sx={{ padding: 0 }}>
                <ListItemIcon sx={{ minWidth: 0, marginRight: 1 }}>
                  <FaCamera color={theme.palette.text.primary} /> {/* Adjust icon color */}
                </ListItemIcon>
                <Typography variant="body1" color={theme.palette.text.primary}>
                  Start with photos of your space.
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0 }}>
                <ListItemIcon sx={{ minWidth: 0, marginRight: 1 }}>
                  <FaInfoCircle color={theme.palette.text.primary} /> {/* Adjust icon color */}
                </ListItemIcon>
                <Typography variant="body1" color={theme.palette.text.primary}>
                  Add Details and amenities
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0 }}>
                <ListItemIcon sx={{ minWidth: 0, marginRight: 1 }}>
                  <FaCalendarAlt color={theme.palette.text.primary} /> {/* Adjust icon color */}
                </ListItemIcon>
                <Typography variant="body1" color={theme.palette.text.primary}>
                  Set your availability and pricing
                </Typography>
              </ListItem>
            </List>
          </Box>

          <Box className="onboarding-setup-cta" sx={{ mt: 2, width: "100%" }}>
            <Button
              text={"Get Started"}
              onClick={() => {
                if (role === "trainer") {
                  goToStep("DemographicInfo");
                } else if (role === "site") {
                  goToStep("Description");
                }
              }}
              fill=""
            />
            <Typography 
              variant="body2" 
              sx={{ mt: 2 }} 
              color={theme.palette.text.primary} // Explicitly set color based on theme
            >
              <strong>Got it. I am ready to get started.</strong>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Setup;
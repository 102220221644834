import { globalStyles, spacingStyles } from "../../../assets/styles/global";

//redux
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//Calendar
import { useEffect, useState, useRef } from "react";

//models

// import { todayString } from "react-native-calendars/src/expandableCalendar/commons";

import ThreeMonthDaySelect from "../../../Components/Calendar/ThreeMonthDaySelect";

import { Button } from "../../../Components/Common/Buttons/Button";
import { Container } from "@mui/material";
import { CustIcons } from "../../../Services/Constants/customIcons";

import PageHeader from "../../../Components/Common/PageHeader";

const LegendItem = ({ color, label }) => {
  return (
    <div
      style={{
        ...styles.legendItem,
        ...globalStyles.mt10,
        ...globalStyles.ml5,
        ...globalStyles.mr5,
      }}
    >
      <div style={{ ...styles.colorSquare, backgroundColor: color }} />
      <div style={globalStyles.h2}>{label}</div>
    </div>
  );
};

export default function UserCalendar() {
  const navigate = useNavigate();
  // const [selected, setSelected] = useState(todayString);
  const [selected, setSelected] = useState();

  const schedule = useSelector((state) => state.schedule);
  const [scheduleData, setSchedule] = useState(schedule);

  const blackOuts = useSelector((state) => state.blackout);
  const [selectBlackOuts, setBlackOuts] = useState([]);

  const currentDate = new Date();

  // Calculate the start and end dates for the visible months
  const minDate = currentDate.toISOString().split("T")[0]; // Current month
  const maxDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 2,
    0
  )
    .toISOString()
    .split("T")[0]; // Two months from now

  const getSchedule = async () => {
    //const response = await getMockData()
    //const schedule = response.data.publicData.calendar.schedule;
    // setSchedule(schedule);
    //console.log(scheduleData);
  };

  const scrollViewRef = useRef(null);
  const scrollToBottom = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollToEnd({ animated: true });
    }
  };

  useEffect(() => {
    const options = {
      weekday: "short",
    };
    const formatter = Intl.DateTimeFormat("en-US", options);
    const date = new Date();
    const selectDay = formatter.format(date);
    setSelected(selectDay);
    setSchedule(schedule);
  }, [schedule]);

  useEffect(() => {
    setBlackOuts(blackOuts);
    //console.log("Blackout Dates:", selectBlackOuts);
  }, [blackOuts]);

  return (
    <>
      {/* <PageHeader /> */}
      <div       style={{
       ...spacingStyles.desktopMaxWidthTwoCol
      }}>
        <h1>Availability</h1>
        <h3>Modify your availability calendar below.</h3>
        <div style={{ ...spacingStyles.pt40 }} className="calendar-contain">
          <div
            style={{
              ...globalStyles.pt20,
              ...globalStyles.fullWidth,
              ...globalStyles.p5,
            }}
          >
            <ThreeMonthDaySelect
              schedule={scheduleData}
              daySelect={selected}
              blackOuts={selectBlackOuts}
            ></ThreeMonthDaySelect>
          </div>
          <div className="calendar-legend">
            <h3>Legend</h3>
            <div style={{ ...styles.legendContainer, ...globalStyles.mb10, ...globalStyles.mt30,
              }}
            >
              <LegendItem color={"#FFffff40"} label="Times Available" />
              <LegendItem color={"red"} label="Closed" />
            </div>
            <div style={{ ...styles.legendContainer, ...globalStyles.mb10 }}>
              <LegendItem
                color={String(globalStyles.orange.color)}
                label="Selected"
              />
              <LegendItem
                color={String(globalStyles.green.color)}
                label="Booking Date"
              />
            </div>
            <div style={{ ...globalStyles.pt20 }}>
              <Button
                fill="outline"
                text="Edit Availabilty"
                showCusIcon={true}
                cusIconName="calendar"
                onClick={() => {
                  navigate("/EditSchedule");
                }}
              ></Button>
            </div>
            <div style={{ ...globalStyles.pt10 }}>
              <Button
                fill="outline"
                text="Set Closed Dates"
                showCusIcon={true}
                cusIconName="closed"
                onClick={() => {
                  navigate("/EditBlackOutDates");
                }}
              ></Button>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  legendItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    width: "100%",
  },
  legendContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  colorSquare: {
    width: 16,
    height: 16,
    borderRadius: 4,
    marginRight: 8,
  },
  day: {
    borderRadius: 10,
  },
  hasDay: {
    borderColor: "#C4C4C4",
    backgroundColor: "#FFffff40",
    //borderWidth: 1,
    borderRadius: 10,
    color: "#fff",
    padding: 5,
  },
  selectDay: {
    backgroundColor: "#FF6D00",
    borderColor: globalStyles.orange.color,
    borderWidth: 1,
    borderRadius: 10,
  },
};

import React, { useState, useEffect } from "react";
import {
  Container,
  Switch,
  FormControlLabel,
  Button,
  Select,
  MenuItem,
  ButtonGroup,
  Typography,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles"; // Import MUI theme hook
import { useDispatch } from "react-redux";
import { addSchedule } from "../../Services/Redux/Slices/Schedule.Slice";
import { TbCirclePlus } from "react-icons/tb";
import { RiCloseCircleFill } from "react-icons/ri";
import moment from "moment";

const initialSchedule = {
  Sun: { open: false, hours: [] },
  Mon: { open: false, hours: [] },
  Tue: { open: false, hours: [] },
  Wed: { open: false, hours: [] },
  Thu: { open: false, hours: [] },
  Fri: { open: false, hours: [] },
  Sat: { open: false, hours: [] },
};

const dayNameMapping = {
  Sun: "Sunday",
  Mon: "Monday",
  Tue: "Tuesday",
  Wed: "Wednesday",
  Thu: "Thursday",
  Fri: "Friday",
  Sat: "Saturday",
};

const timeSlots = [
  "04:00:00", "04:30:00", "05:00:00", "05:30:00", "06:00:00",
  "06:30:00", "07:00:00", "07:30:00", "08:00:00", "08:30:00",
  "09:00:00", "09:30:00", "10:00:00", "10:30:00", "11:00:00",
  "11:30:00", "12:00:00", "12:30:00", "13:00:00", "13:30:00",
  "14:00:00", "14:30:00", "15:00:00", "15:30:00", "16:00:00",
  "16:30:00", "17:00:00", "17:30:00", "18:00:00", "18:30:00",
  "19:00:00", "19:30:00", "20:00:00", "20:30:00", "21:00:00",
  "21:30:00", "22:00:00", "22:30:00", "23:00:00", "23:30:00",
];

const EditSchedule = () => {
  const [scheduleData, setScheduleData] = useState(initialSchedule);
  const [selectedDay, setSelectedDay] = useState("Sun");
  const dispatch = useDispatch();
  const theme = useTheme(); // Use Material-UI theme

  useEffect(() => {
    if (!scheduleData[selectedDay]) {
      setScheduleData((prev) => ({
        ...prev,
        [selectedDay]: { open: false, hours: [] },
      }));
    }
  }, [selectedDay, scheduleData]);

  const handleOpenCloseChange = (day) => {
    const isOpen = !scheduleData[day]?.open;
    const updatedHours = isOpen && scheduleData[day]?.hours.length === 0
      ? [{ start: "", end: "" }]
      : scheduleData[day]?.hours || [];

    const updatedSchedule = {
      ...scheduleData,
      [day]: { open: isOpen, hours: isOpen ? updatedHours : [] },
    };

    setScheduleData(updatedSchedule);
    dispatchTransformedSchedule(updatedSchedule);
  };

  const handleTimeChange = (day, index, key, value) => {
    const updatedHours = scheduleData[day]?.hours.map((hour, i) => {
      if (i === index) {
        return { ...hour, [key]: value };
      }
      return hour;
    });

    const updatedSchedule = {
      ...scheduleData,
      [day]: { ...scheduleData[day], hours: updatedHours },
    };

    setScheduleData(updatedSchedule);
    dispatchTransformedSchedule(updatedSchedule);
  };

  const addTimeSlot = (day) => {
    const updatedSchedule = {
      ...scheduleData,
      [day]: {
        ...scheduleData[day],
        hours: [...(scheduleData[day]?.hours || []), { start: "", end: "" }],
      },
    };

    setScheduleData(updatedSchedule);
    dispatchTransformedSchedule(updatedSchedule);
  };

  const handleDeleteTimeSlot = (day, index) => {
    const updatedHours = scheduleData[day].hours.filter((_, i) => i !== index);

    const updatedSchedule = {
      ...scheduleData,
      [day]: { ...scheduleData[day], hours: updatedHours },
    };

    setScheduleData(updatedSchedule);
    dispatchTransformedSchedule(updatedSchedule);
  };

  const isDayGreen = (day) => {
    return scheduleData[day]?.open && scheduleData[day].hours.some(hour => hour.start && hour.end);
  };

  const dispatchTransformedSchedule = (scheduleData) => {
    const transformedSchedule = Object.keys(scheduleData).reduce((acc, day) => {
      acc[day] = timeSlots.map((time) => {
        const selected = scheduleData[day].open &&
          scheduleData[day].hours.some(({ start, end }) => {
            const startTime = new Date(`1970-01-01T${convertTo24HourFormat(start)}Z`);
            const endTime = new Date(`1970-01-01T${convertTo24HourFormat(end)}Z`);
            const slotTime = new Date(`1970-01-01T${time}Z`);
            return slotTime >= startTime && slotTime < endTime;
          });
        return { time, select: selected };
      });
      return acc;
    }, {});

    dispatch(addSchedule(transformedSchedule));
  };

  const convertTo24HourFormat = (time) => {
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":");

    if (modifier === "PM" && hours !== "12") {
      hours = String(parseInt(hours, 10) + 12);
    }

    if (modifier === "AM" && hours === "12") {
      hours = "00";
    }

    return `${hours}:${minutes}:00`;
  };

  const renderOpenDays = () => {
    const sortedDays = Object.keys(scheduleData).sort((a, b) => {
      const dayOrder = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return dayOrder.indexOf(a) - dayOrder.indexOf(b);
    });

    return sortedDays
      .filter(
        (day) =>
          scheduleData[day].open &&
          scheduleData[day].hours.some((hour) => hour.start && hour.end)
      )
      .map((day) => (
        <Box key={day} sx={{ marginBottom: 2 }}>
          <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
            {dayNameMapping[day]}
          </Typography>
          {scheduleData[day].hours
            .filter((hour) => hour.start && hour.end)
            .map((hour, index) => (
              <Typography key={index} sx={{ color: theme.palette.text.secondary }}>
                {moment(hour.start, "HH:mm:ss").format("h:mm a")} to{" "}
                {moment(hour.end, "HH:mm:ss").format("h:mm a")}
              </Typography>
            ))}
        </Box>
      ));
  };

  return (
    <Container sx={{ padding: "unset", color: theme.palette.text.primary }}>
      <Box sx={{ display: "flex", flexDirection: "column", marginBottom: 3 }}>
        <Typography variant="h4" sx={{ paddingBottom: 2 }}>
          Open Days & Hours
        </Typography>
        <Box sx={{ width: "50%" }}>{renderOpenDays()}</Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start" }}>
        <ButtonGroup variant="contained">
          {Object.keys(scheduleData).map((day) => (
            <Button
              key={day}
              onClick={() => setSelectedDay(day)}
              sx={{
                backgroundColor: selectedDay === day
                  ? theme.palette.primary.main
                  : isDayGreen(day)
                    ? theme.palette.success.light
                    : theme.palette.grey[600],
                margin: "0 3px",
                color: theme.palette.common.white,
                fontWeight: "600",
              }}
            >
              {day}
            </Button>
          ))}
        </ButtonGroup>

        <Box sx={{ width: "50%" }}>
          <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
            <Typography variant="h6" sx={{ marginRight: 2 }}>
              {dayNameMapping[selectedDay]}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={scheduleData[selectedDay]?.open || false}
                  onChange={() => handleOpenCloseChange(selectedDay)}
                  color="primary"
                />
              }
              label={scheduleData[selectedDay]?.open ? "Open" : "Closed"}
            />
          </Box>

          {scheduleData[selectedDay]?.open && (
            <>
              {scheduleData[selectedDay]?.hours.map((hour, index) => (
                <Box key={index} sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
                  <Select
                    value={hour.start}
                    onChange={(e) => handleTimeChange(selectedDay, index, "start", e.target.value)}
                    sx={{
                      marginRight: 2,
                      minWidth: 100,
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.background.paper,
                    }}
                  >
                    {timeSlots.map((time) => (
                      <MenuItem key={time} value={time}>
                        {moment(time, "HH:mm:ss").format("h:mm a")}
                      </MenuItem>
                    ))}
                  </Select>

                  <Typography sx={{ marginX: 2 }}>to</Typography>

                  <Select
                    value={hour.end}
                    onChange={(e) => handleTimeChange(selectedDay, index, "end", e.target.value)}
                    sx={{
                      minWidth: 100,
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.background.paper,
                    }}
                  >
                    {timeSlots
                      .filter((time) => moment(time, "HH:mm:ss").isAfter(moment(hour.start, "HH:mm:ss")))
                      .map((time) => (
                        <MenuItem key={time} value={time}>
                          {moment(time, "HH:mm:ss").format("h:mm a")}
                        </MenuItem>
                      ))}
                  </Select>

                  {/* Ensure delete button always shows */}
                  <RiCloseCircleFill
                    onClick={() => handleDeleteTimeSlot(selectedDay, index)}
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                      color: theme.palette.error.main,
                      visibility: scheduleData[selectedDay]?.hours.length > 1 ? "visible" : "hidden", // Only hide when it's the last time slot
                    }}
                  />
                </Box>
              ))}

              <Button
                onClick={() => addTimeSlot(selectedDay)}
                sx={{
                  color: theme.palette.text.primary,
                  marginTop: 2,
                  display: "flex",
                  alignItems: "center",
                }}
                startIcon={<TbCirclePlus />}
              >
                Add Time Slot
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default EditSchedule;

import React, { useEffect, useState } from "react";
import { useSearchParam } from "react-use";
import { validateFormData } from "../../Services/Forms/Validations";
import { RegisterHandler } from "../../Services/APIHandlers/AuthApi";

import { useDispatch } from "react-redux";
import { saveToken } from "../../Services/Redux/Slices/Auth.Slice";
import { useNavigate } from "react-router";
import Loader from "../../Components/Common/Loader";

const ThirdPartyRegister = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState({
    country_phone: {
      country: {
        code: "",
        iso: "",
        name: "",
        sample_phone: "",
      },
      phone: useSearchParam("phone") || 0,
    },
    email: useSearchParam("email") || "",
    name: useSearchParam("name") || "",
    lastname: useSearchParam("name") || "",
    certified_trainer: useSearchParam("certified_trainer"),
    role_id: null,
  });

  useEffect(() => {
    const handleSubmit = async () => {
      setLoading(true);

      const { name, lastname, email, country_phone } = userData;
      const phone = country_phone.phone;


      const validationData = {
        email: { email: true, required: true },
        name: { required: true },
        lastname: { required: true },
        phone: { required: true, minlength: 10 },
      };

      const validated = validateFormData(validationData, {
        email,
        name,
        lastname,
        phone,
      });

      console.log(validated.errors);

      if (!validated.isValid) {
        setLoading(false);
        setFormErrors(validated.errors);
        return;
      } else {
        setFormErrors({});

        console.log(userData);
        try {
          const response = await RegisterHandler(userData);
          if (!response.data?.error) {
            dispatch(saveToken(response?.data?.verify_token));
            navigation("/OTP");
          } else {
            setFormErrors({ emailInUse: "Email already in use" });
          }
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.error(err);
        }
      }
    };

    handleSubmit();
  }, [dispatch, navigation, userData]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <p>
          {formErrors?.type && <span>User type is required</span>}
          {formErrors?.name && <span>Name is required</span>}
          {formErrors?.lastname && <span>Please enter your last name</span>} 
          {formErrors?.email && <span>Valid email is required</span>}
          {formErrors?.emailInUse && <span>{formErrors.emailInUse}</span>}
          {formErrors?.phone && <span>Valid phone number is required</span>}
        </p>
      )}
    </>
  );
};

export default ThirdPartyRegister;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  imageURI: ''
};

const AuthSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    addImage(state, action) {
      state.imageURI = action.payload;
    },
    removeImage(state) {
      state = initialState;
    },
  },
});

export const { addImage, removeImage } = AuthSlice.actions;

export default AuthSlice.reducer;

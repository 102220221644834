import { useDispatch, useSelector } from "react-redux";
import { setEquipment } from "../../Services/Redux/Slices/Onboarding.Slice";
import { globalStyles, spacingStyles } from "../../assets/styles/global";
import OnboardingButton from "../Common/Buttons/OnboardingButton";
import { Typography, useTheme } from "@mui/material";

export default function EquipmentComponent() {
  const dispatch = useDispatch();
  const equipment = useSelector((state) => state.onboarding.equipment);
  const theme = useTheme(); // Access current theme

  return (
    <>
      <div style={{ ...globalStyles.alignCenter, ...globalStyles.w100 }}>
        <Typography variant="h4" color={theme.palette.text.primary}>
          What equipment do you have?
        </Typography>
        <Typography variant="body1" style={spacingStyles.mb20} color={theme.palette.text.secondary} >
          Select all that apply
        </Typography>
        <div className="onborading-amenities-list">
          <OnboardingButton
            text="Treadmill"
            onClick={() =>
              dispatch(
                setEquipment({ ...equipment, treadmill: !equipment.treadmill })
              )
            }
            selected={equipment.treadmill}
            showCusIcon={true}
            cusIconName="treadmill"
          />
          <OnboardingButton
            text="Eliptical"
            onClick={() =>
              dispatch(
                setEquipment({ ...equipment, eliptical: !equipment.eliptical })
              )
            }
            selected={equipment.eliptical}
            showCusIcon={true}
            cusIconName="eliptical"
          />
          <OnboardingButton
            text="Spin Bikes"
            onClick={() =>
              dispatch(
                setEquipment({ ...equipment, spinBikes: !equipment.spinBikes })
              )
            }
            selected={equipment.spinBikes}
            showCusIcon={true}
            cusIconName="spinBikes"
          />
          <OnboardingButton
            text="Machines"
            onClick={() =>
              dispatch(
                setEquipment({ ...equipment, machines: !equipment.machines })
              )
            }
            selected={equipment.machines}
            showCusIcon={true}
            cusIconName="machines"
          />
          <OnboardingButton
            text="Free Weights"
            onClick={() =>
              dispatch(
                setEquipment({
                  ...equipment,
                  freeWeights: !equipment.freeWeights,
                })
              )
            }
            selected={equipment.freeWeights}
            showCusIcon={true}
            cusIconName="freeWeights"
          />
          <OnboardingButton
            text="Dumbells"
            onClick={() =>
              dispatch(
                setEquipment({ ...equipment, dumbells: !equipment.dumbells })
              )
            }
            selected={equipment.dumbells}
            showCusIcon={true}
            cusIconName="dumbells"
            iconWidth={20}
            iconHeight={40}
          />
          <OnboardingButton
            text="Row Machine"
            onClick={() =>
              dispatch(
                setEquipment({
                  ...equipment,
                  rowMachine: !equipment.rowMachine,
                })
              )
            }
            selected={equipment.rowMachine}
            showCusIcon={true}
            cusIconName="rowMachine"
          />
          <OnboardingButton
            text="Pullup bar"
            onClick={() =>
              dispatch(
                setEquipment({ ...equipment, pullupBar: !equipment.pullupBar })
              )
            }
            selected={equipment.pullupBar}
            showCusIcon={true}
            cusIconName="pullupBar"
          />
          <OnboardingButton
            text="Punching Bags"
            onClick={() =>
              dispatch(
                setEquipment({
                  ...equipment,
                  punchingBags: !equipment.punchingBags,
                })
              )
            }
            selected={equipment.punchingBags}
            showCusIcon={true}
            cusIconName="punchingBags"
            iconWidth={12}
            iconHeight={40}
          />
          <OnboardingButton
            text="Cross Training"
            onClick={() =>
              dispatch(
                setEquipment({
                  ...equipment,
                  crossTraining: !equipment.crossTraining,
                })
              )
            }
            selected={equipment.crossTraining}
            showCusIcon={true}
            cusIconName="crossTraining"
          />
          <OnboardingButton
            text="Floor Mats"
            onClick={() =>
              dispatch(
                setEquipment({ ...equipment, floorMats: !equipment.floorMats })
              )
            }
            selected={equipment.floorMats}
            showCusIcon={true}
            cusIconName="floorMats"
          />
          <OnboardingButton
            text="Stair Master"
            onClick={() =>
              dispatch(
                setEquipment({
                  ...equipment,
                  stairMaster: !equipment.stairMaster,
                })
              )
            }
            selected={equipment.stairMaster}
            showCusIcon={true}
            cusIconName="stairMaster"
          />
        </div>
      </div>
    </>
  );
}

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Stack, TextField, Typography } from "@mui/material";
import OnboardingTemplate from "../../Components/Onboarding/OnboardingTemplate";
import { useTheme } from "@mui/material/styles"; // Use the theme from ThemeProvider
import { addBusiness } from "../../Services/Redux/Slices/Business.Slice";
import { updateBusinessAsync } from "../../Services/Redux/Thunks/updateBusiness";
import { formStyles } from "../../assets/styles/global";

const BusinessDescription = ({ nextAction, previousAction, screenNum = 1, screenTotal = 1 }) => {
  const dispatch = useDispatch();
  const theme = useTheme(); // Get the MUI theme, light or dark mode

  const token = useSelector((state) => state.auth.token);
  const bData = useSelector((state) => state.business);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update the specific field in the business data
    const updatedBusinessData = {
      ...bData,
      [name]: value,
    };

    // Dispatch the action with the updated business data
    dispatch(addBusiness(updatedBusinessData));
  };

  const saveResponses = async () => {
    try {
      const updatedBusinessData = JSON.stringify({ ...bData }); // Generate your updated business data here
      await dispatch(updateBusinessAsync({ updatedBusinessData, token }));
      return { data: updatedBusinessData };
    } catch (error) {
      console.error("Failed to save responses:", error);
      throw error;
    }
  };

  const businessDescriptionComponent = (
    <Stack
      spacing={10}
      sx={{
        textAlign: "left",
        width: "100%",
        color: theme.palette.text.primary, // Ensure text color changes with theme
      }}
    >
      <Stack spacing={3} sx={{ width: "100%" }}>
        <Typography variant="h5" color={theme.palette.text.primary}>
          What is the name of your Space?
        </Typography>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          Does your space have something unique that people should know about? For example, is your main entrance in the rear?
        </Typography>
        <TextField
        //   className="input"
          label="Space name"
          name="name"
          value={bData.name || ""}
          onChange={handleInputChange}
          fullWidth
          sx={{
            // ...formStyles.textField,
            //backgroundColor: theme.palette.background.paper, // Dynamic background color based on theme
            input: {
              color: theme.palette.text.primary, // Text color based on theme
            },
            label: {
              color: theme.palette.text.secondary, // Label color based on theme
            },
            '& .MuiInputBase-root': {
              color: theme.palette.text.primary, // Set the input text color
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: theme.palette.divider, // Border color based on theme
              },
              '&:hover fieldset': {
                borderColor: theme.palette.primary.main, // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main, // Border color when focused
              },
            },
          }}
        />
      </Stack>

      {/* <Stack spacing={2} sx={{ width: "100%" }}>
        <Typography variant="h5" color={theme.palette.text.primary}>
          What is the description of your space?
        </Typography>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          Does your space have something unique that people should know about? For example, is your main entrance in the rear?
        </Typography>
        <TextField
          //   className="input"
          label="Description"
          name="description"
          value={bData.description || ""}
          onChange={handleInputChange}
          fullWidth
          sx={{
            // ...formStyles.textField,
            // backgroundColor: theme.palette.background.paper, // Dynamic background color based on theme
            input: {
              color: theme.palette.text.primary, // Text color based on theme
            },
            label: {
              color: theme.palette.text.secondary, // Label color based on theme
            },
            '& .MuiInputBase-root': {
              color: theme.palette.text.primary, // Set the input text color
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: theme.palette.divider, // Border color based on theme
              },
              '&:hover fieldset': {
                borderColor: theme.palette.primary.main, // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main, // Border color when focused
              },
            },
          }}
          multiline
          rows={3}
        />
      </Stack> */}
    </Stack>
  );

  return (
    <OnboardingTemplate
      component={businessDescriptionComponent}
      screenNum={screenNum}
      screenTotal={screenTotal}
      nextAction={nextAction}
      previousAction={previousAction}
      saveResponses={saveResponses}
      title="Business Description"
    />
  );
};

export default BusinessDescription;

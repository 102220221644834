import { Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";

import Setup from "../Wizard/SiteOnboarding/Setup";
import MatchMeTopic from "../Wizard/SiteOnboarding/MatchMeTopic";
import DemographicInfo from "../Wizard/SiteOnboarding/DemographicInfo";
import { useDispatch, useSelector } from "react-redux";
import { updateMatchMe } from "../../Services/APIHandlers/ProfileApi";
import { setSimilarFitnessTrainers } from "../../Services/Redux/Slices/MatchMe.Slice";

const screens = {
  Setup: "Setup",
  DemographicInfo: "DemographicInfo",
  Coach: "Coach",
  Goal: "Goal",
  Area: "Area",
  Motivate: "Motivate",
  Success: "Success",
  Nonsuccess: "Nonsuccess",
  Skip: "Skip",
  Done: "Done",
};

const TrainerOnboarding = () => {
  const dispatch = useDispatch();
  // Retrieve all topic data from Redux store
  const matchMeData = useSelector((state) => state.matchMe);
  const location = useSelector((state) => state.location);
  const token = useSelector((state) => state.auth.token);

  const navigate = useNavigate();

  const [activeScreen, setActiveScreen] = useState("Setup");

  const saveResponses = async () => {
    const result = await updateMatchMe({
      token,
      data: matchMeData,
      lat: location?.coords?.latitude || 0,
      long: location?.coords?.longitude || 0,
    });

    return result;
  };

  const nextAction = (screen, data) => {
    setActiveScreen(screen);
  };

  if (activeScreen === screens.Setup) {
    return <Setup role="trainer" goToStep={(screen) => nextAction(screen)} />;
  }

  if (activeScreen === screens.DemographicInfo) {
    return (
      <DemographicInfo
        previousAction={() => navigate(-1)}
        nextAction={(data) => nextAction(screens.Coach, data)}
        screenNum={1}
        screenTotal={7}
      />
    );
  }

  if (activeScreen === screens.Coach) {
    return (
      <MatchMeTopic
        topic="coach"
        previousAction={() => nextAction(screens.DemographicInfo)}
        nextAction={(data) => nextAction(screens.Goal, data)}
        screenNum={2}
        screenTotal={7}
      />
    );
  }

  if (activeScreen === screens.Goal) {
    return (
      <MatchMeTopic
        topic="goal"
        previousAction={() => nextAction(screens.Coach)}
        nextAction={(data) => nextAction(screens.Area, data)}
        screenNum={3}
        screenTotal={7}
      />
    );
  }

  if (activeScreen === screens.Area) {
    return (
      <MatchMeTopic
        topic="area"
        previousAction={() => nextAction(screens.Goal)}
        nextAction={(data) => nextAction(screens.Motivate, data)}
        screenNum={4}
        screenTotal={7}
      />
    );
  }

  if (activeScreen === screens.Motivate) {
    return (
      <MatchMeTopic
        topic="motivate"
        previousAction={() => nextAction(screens.Area)}
        nextAction={(data) => nextAction(screens.Success, data)}
        screenNum={5}
        screenTotal={7}
      />
    );
  }

  if (activeScreen === screens.Success) {
    return (
      <MatchMeTopic
        topic="success"
        previousAction={() => nextAction(screens.Motivate)}
        nextAction={(data) => nextAction(screens.Nonsuccess, data)}
        screenNum={6}
        screenTotal={7}
      />
    );
  }

  if (activeScreen === screens.Nonsuccess) {
    return (
      <MatchMeTopic
        topic="nonsuccess"
        previousAction={() => nextAction(screens.Success)}
        nextAction={({ trainers }) => {
          dispatch(setSimilarFitnessTrainers(trainers?.partnersNearMe || []));
          navigate("/SimilarFitnessTrainers");
        }}
        screenNum={7}
        screenTotal={7}
        saveResponses={saveResponses}
      />
    );
  }

  if (activeScreen === screens.Skip) {
    return <Navigate to="/TrainerDash" />;
  }

  return <Navigate to="/GymDash" />;
};

export default TrainerOnboarding;

import axios from "axios";
import config from '../../config';

export const getWalletData = async (token) => {
  const response = await axios.get(config.apiUrl + "Wallet/getData/"+token);
  return response;
};

export const getCurrentData = async (token) => {
  const response = await axios.get(config.apiUrl + "Wallet/getCurrentData/"+token);
  return response;
};

export const setupTransfer = async (token) => {
  const response = await axios.get(config.apiUrl + "Stripe/getConnectURl/"+token);
  return response;
};

export const cashOut = async (token) => {
  const response = await axios.get(config.apiUrl + "Wallet/cashOut/"+token);
  return response;
};



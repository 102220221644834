import { Box, TextField } from "@mui/material";
import {
  LocalizationProvider,
  PickersDay,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { colors } from "../../../assets/styles/global";
import moment from "moment";

const MultiSelectCalendar = ({ dates, handleDateSelection }) => {
  console.log(">>dates", dates);

  const renderDay = (day, selectedDates, pickersDayProps) => {
    const dateString = moment(day).format("yyyy-MM-DD");

    const isSelected = selectedDates[dateString]?.selected;

    const isToday = moment().format("yyyy-MM-DD") === dateString; // Check if the day is today

    if (isToday) {
      console.log(">>isToday", dateString);
    }

    return (
      <PickersDay
        {...pickersDayProps}
        selected={isSelected}
        sx={{
          ...(isToday && {
            borderColor: `${colors.orange.color} !important`,
            borderWidth: 2,
            borderStyle: "solid",
          }),
          ...(isSelected && {
            backgroundColor: `${colors.red.color} !important`,
          }),
        }}
      />
    );
  };

  return (
    <Box
      sx={{
        color: "#fff",
        "& .MuiPickerStaticWrapper-content": {
          backgroundColor: "#000 !important",
        },
        "& .MuiButtonBase-root": {
          backgroundColor: "#000",
          color: "#fff !important",
        },
        "& .MuiTypography-root": {
          color: "#fff",
        },
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          label="Multi-Select Calendar"
          value={null}
          onChange={handleDateSelection}
          renderDay={(day, _value, DayComponentProps) =>
            renderDay(day, dates, DayComponentProps)
          }
          openTo="day"
          views={["year", "month", "day"]}
          showDaysOutsideCurrentMonth
        />
      </LocalizationProvider>
    </Box>
  );
};

export default MultiSelectCalendar;

import React, { useState } from "react";
import { validateFormData } from "../../Services/Forms/Validations";
import { RegisterHandler } from "../../Services/APIHandlers/AuthApi";
import { useSelector, useDispatch } from "react-redux";
import { saveToken } from "../../Services/Redux/Slices/Auth.Slice";
import { addPhone } from "../../Services/Redux/Slices/User.Slice";
import { Button } from "../Common/Buttons/Button";
import { Box, TextField, Typography, Checkbox, FormControlLabel } from "@mui/material";
import formatPhoneNumber from "../../Services/Helpers/FormatPhoneNumber";

const RegisterForm = ({ onSuccess, mode = "dark" }) => {

  const email = useSelector((state) => state.user.email);
  const dispatch = useDispatch();

  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState("");
  const [userData, setUserData] = useState({
    country_phone: {
      country: {
        code: "",
        iso: "",
        name: "",
        sample_phone: "",
      },
      phone: 0,
    },
    email: "",
    lastname: "",
    name: "",
    terms: true,
    role_id: null,
  });

  const handleSubmit = async () => {
    setLoading(true);
    if (!userData.terms) {
      setFormErrors({ ...formErrors, terms: true });
      return;
    }

    const { lastname, name, email } = userData;
    const phone = String(userData.country_phone.phone);
    const validationData = {
      email: { email: true, required: true },
      name: { required: true },
      lastname: { required: true },
      phone: { required: true, minlength: 10 },
    };

    const validated = validateFormData(validationData, {
      email,
      name,
      lastname,
      phone,
    });

    if (!validated.isValid) {
      setLoading(false);
      setFormErrors(validated.errors);
      return;
    } else {
      setFormErrors({});
      try {
        const response = await RegisterHandler(userData);
        if (!response.data?.error) {
          dispatch(saveToken(response?.data?.verify_token));
          onSuccess();
        } else {
          setFormErrors({ emailInUse: "Email already in use" });
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    }
  };

  return (
    <Box sx={{ maxWidth: "sm", mx: "auto", padding: "1rem" }}>
      <Typography variant="h4" sx={{ marginBottom: "10px", color: mode === "light" ? "black" : "white" }}>
        Register
      </Typography>

      {!Object.keys(formErrors).length && (
        <Typography sx={{ color: mode === "light" ? "black" : "white", marginBottom: "20px" }}>
          Enter your info and verify your account.
        </Typography>
      )}

      <Box sx={{ color: "red", marginBottom: "10px" }}>
        {formErrors?.type && <Typography>Please select a user type</Typography>}
        {formErrors?.name && <Typography>Please enter your first name</Typography>}
        {formErrors?.lastname && <Typography>Please enter your last name</Typography>}
        {formErrors?.email && <Typography>Please enter a valid email</Typography>}
        {formErrors?.emailInUse && <Typography>{formErrors.emailInUse}</Typography>}
        {formErrors?.phone && <Typography>Please enter a valid phone number</Typography>}
        {formErrors?.terms && <Typography>Please agree to terms and agreements</Typography>}
      </Box>

      <TextField
        label="First Name"
        fullWidth
        variant="outlined"
        error={!!formErrors?.name}
        sx={{ marginBottom: "10px" }}
        onChange={(e) => setUserData({ ...userData, name: e.target.value })}
      />
      <TextField
        label="Last Name"
        fullWidth
        variant="outlined"
        error={!!formErrors?.lastname}
        sx={{ marginBottom: "10px" }}
        onChange={(e) => setUserData({ ...userData, lastname: e.target.value })}
      />
      <TextField
        label="Email"
        fullWidth
        variant="outlined"
        error={!!formErrors?.email || !!formErrors?.emailInUse}
        sx={{ marginBottom: "10px" }}
        value={userData.email}
        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
      />
      <TextField
        label="Phone Number"
        fullWidth
        variant="outlined"
        error={!!formErrors?.phone}
        sx={{ marginBottom: "10px" }}
        value={formatPhoneNumber(number)}
        onChange={(e) => {
          const value = e.target.value;
          setNumber(value);
          dispatch(addPhone(value));
          setUserData({
            ...userData,
            country_phone: {
              ...userData.country_phone,
              phone: Number(value.replace(/\D/g, "")),
            },
          });
        }}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={userData.terms}
            onChange={() => setUserData({ ...userData, terms: !userData.terms })}
          />
        }
        label="I agree to terms and conditions"
        sx={{ color: mode === "light" ? "black" : "white", marginTop: "5px" }}
      />

      <Box sx={{ marginTop: "15px" }}>
        <Button fill="solid" text="Register" onClick={handleSubmit} disabled={loading} />
      </Box>


    </Box>
  );
};

export default RegisterForm;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  descr: '',
  id: '',
  image: '',
  location: [],
  meta: '',
  name: '',
  rating: null,
  status: '',
  type: ''
};

const SelectedGymSlice = createSlice({
  initialState: initialState,
  name: "SelectedGymSlice",
  reducers: {
    SelectBookingData: (state, action) => {
      return action.payload;
    }
  }
});

export const { SelectBookingData } = SelectedGymSlice.actions;

export default SelectedGymSlice.reducer;

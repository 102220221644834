import React, { useEffect } from "react";
import { AiFillCheckCircle } from "react-icons/ai";

import { globalStyles } from "../../../assets/styles/global";
import { Button } from "../../../Components/Common/Buttons/Button";

import { useSelector } from "react-redux";

//navigation

import { updateSpaceDetails } from "../../../Services/APIHandlers/ProfileApi";
import getErrorMessage from "../../../Services/Helpers/getErrorMessage";
import { Container } from "@mui/material";

const SetupComplete = ({ nextAction }) => {
  const token = useSelector((state) => state.auth.token);
  // const location = useSelector((state) => state.onboarding);

  const handleSubmit = async () => {
    nextAction();
    // try {
    //   //console.log(location);
    //   const response = await updateSpaceDetails(
    //     JSON.stringify(location),
    //     token
    //   );

    //   if (response.data.success) {
    //     nextAction();
    //   } else {
    //     console.log(response.data);
    //   }
    // } catch (err) {
    //   getErrorMessage(err);
    // }
  };

  useEffect(() => {
    localStorage.setItem("userTypeSet", true);
  }, []);

  return (
    <Container maxWidth="sm">
      <div style={{ ...globalStyles.container }}>
        <div
          style={{
            ...globalStyles.w100,
            ...globalStyles.alignCenter,
            ...globalStyles.ht50,
            ...globalStyles.ph20,
          }}
        >
          <div>
            <AiFillCheckCircle size={140} color="#7ceb9a" />
          </div>
          <span style={{ ...globalStyles.h1, fontSize: 30, fontWeight: "300" }}>
            HopperSite{" "}
          </span>
          <span style={{ ...globalStyles.h1, fontSize: 30, fontWeight: "700" }}>
            setup complete!
          </span>
          <div
            style={{
              ...globalStyles.mt60,
              ...globalStyles.w100,
              ...globalStyles.alignCenter,
            }}
          >
            <span
              style={{
                ...globalStyles.h3,
                ...globalStyles.textAlign,
                ...globalStyles.fw500,
              }}
            >
              All that's left is to complete your profile info.{`\n`}
              We will review your info and activate your HopperSite.
            </span>
            <div
              style={{
                ...globalStyles.mt30,
              }}
            >
              <Button
                fill="fill"
                onClick={() => handleSubmit()}
                disabled={false}
                text={"Done!"}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SetupComplete;

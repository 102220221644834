import { useState } from "react";

import { useSelector } from "react-redux";

import { globalStyles } from "../../assets/styles/global";
import ProfileEditForm from "./ProfileEditForm";
import ProfileFieldInput from "./ProfileFieldInput";
import { getLoginData } from "../../Services/APIHandlers/ProfileApi";
import { Stack } from "@mui/material";

const ProfileBusiness = () => {
  const token = useSelector((state) => state.auth.token);

  const bData = useSelector((state) => state.business);

  const [editField, setEditField] = useState(null);

  return (
    <>
      {!!editField && (
        <ProfileEditForm
          field={editField}
          onClose={() => {
            setEditField(null);
            getLoginData(token);
          }}
        />
      )}

      <Stack
        spacing={2}
        mb={10}
        sx={{ margin: "auto" }}
      >
        <ProfileFieldInput
          label="Business Name"
          value={bData.name ? bData.name : ""}
          onClick={() => {
            setEditField("BusinessName");
          }}
        />

        <ProfileFieldInput
          label="Description"
          value={bData.description ? bData.description : ""}
          onClick={() => {
            setEditField("Description");
          }}
        />

        <ProfileFieldInput
          label="Certification"
          value={bData.certifications ? bData.certifications : ""}
          onClick={() => {
            setEditField("Certifications");
          }}
        />

        <ProfileFieldInput
          label="Key Words"
          value={bData.meta ? bData.meta : ""}
          onClick={() => {
            setEditField("Meta");
          }}
        />

        <ProfileFieldInput
          label="Specialty"
          value={bData.specialty ? bData.specialty : ""}
          onClick={() => {
            setEditField("Specialty");
          }}
        />

        <ProfileFieldInput
          label="Years Experience"
          value={bData.years_exp ? bData.years_exp : ""}
          onClick={() => {
            setEditField("Experience");
          }}
        />

        <ProfileFieldInput
          label="Languages"
          value={bData.language ? bData.language : ""}
          onClick={() => {
            setEditField("Languages");
          }}
        />
      </Stack>
    </>
  );
};

export default ProfileBusiness;

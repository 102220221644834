import { createSlice } from "@reduxjs/toolkit";

// Get the data from local storage, if available
let storedUser;

const getStoredData = () => {
  storedUser = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
};

getStoredData();

const initialState = {
  userToken: "",
  app_token: storedUser?.app_token || "",
  birthday: storedUser?.birthday || "",
  phone: storedUser?.phone || "",
  email: storedUser?.email || "",
  gender: storedUser?.gender || "",
  picture_id: storedUser?.picture_id || "",
  profilepic: storedUser?.profilepic || "",
  role_id: storedUser?.role_id || "",
  stripe_payoutId: storedUser?.stripe_payoutId || "",
  stripe_user_id: storedUser?.stripe_user_id || "",
  user_id: storedUser?.user_id || "",
  user_lastname: storedUser?.user_lastname || "",
  user_name: storedUser?.user_name || "",
};

const UserSlice = createSlice({
  name: "User",
  initialState: initialState,
  reducers: {
    addEmail: (state, action) => {
      //localStorage.setItem("email", JSON.stringify(action.payload));
      state.email = action.payload;
    },
    addPhone: (state, action) => {
      state.phone = action.payload;
    },
    addRoleID: (state, action) => {
      //localStorage.setItem("role_id", JSON.stringify(action.payload));
      state.role_id = action.payload;
    },
    addUser: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      return action.payload;
    },
    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },
    removeUser: (state) => {
      localStorage.removeItem("user");
      getStoredData();
      return initialState;
    },
    addProfilePic: (state, action) => {
      state.profilepic = action.payload;
    },
  },
});

export const {
  addEmail,
  addPhone,
  addUser,
  setUserToken,
  removeUser,
  addProfilePic,
  addRoleID,
} = UserSlice.actions;

export default UserSlice.reducer;

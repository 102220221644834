import React, { createContext, useEffect } from "react";

import { RenderRoutes, RenderMenu } from "./RenderNavigation";
import { useSelector, useDispatch } from "react-redux";
import { clearAuth } from "../Services/Redux/Slices/Auth.Slice";

// navigation
import { useNavigate, useLocation } from "react-router-dom";
import { getLoginData } from "../Services/APIHandlers/ProfileApi";

import DeskSideMenu from "./DeskSideMenu";


const AuthContext = createContext();

export default AuthContext;

export const AuthWrapper = ({ darkMode, toggleDarkMode }) => {

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const token = useSelector((state) => state.auth.token);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const role_id = useSelector((state) => state.auth.role_id);

  const location = useLocation(); // Use useLocation to get the current route

  const logout = () => {
    dispatch(clearAuth());
    console.log("Clear Auth");
    window.location.reload();
  };

  const login = () => {
    console.log("Login");
    navigation("/Login");
  };

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [darkMode]);

  useEffect(() => {
    if (token && isAuthenticated) {
      getLoginData(token);
    }
  }, [isAuthenticated, token]);
  
  const handleMenuClick = (menuItem) => {
    console.log(menuItem); 
    // Logic to navigate based on the menu item clicked
    switch (menuItem) {
      case "Setup":
        navigation("/SiteOnboarding#intro");
        break;
      case "Description":
        navigation("/SiteOnboarding#description");
        break;
      case "Gallery":
        navigation("/SiteOnboarding#gallery");
        break;
      case "Space":
        navigation("/SiteOnboarding#space");
        break;
      case "Amenities":
        navigation("/SiteOnboarding#amenities");
        break;
      case "Location":
        navigation("/SiteOnboarding#location");
        break;
      case "Schedule":
        navigation("/SiteOnboarding#schedule");
        break;
      case "Special":
        navigation("/SiteOnboarding#special");
        break;
      case "SetupComplete":
        navigation("/SiteOnboarding#setupcomplete");
        break;
      case "Earnings":
          navigation("/Wallet");
          break;
      case "Home":
            navigation("/");
            break;
      default:
        navigation(`/${menuItem.toLowerCase()}`); // Fallback to default screen
    }
  };

  return (
    <AuthContext.Provider value={{ token, role_id, logout, login, darkMode, toggleDarkMode }}>
      <RenderMenu />
      <div className={`content-container`}>
        <DeskSideMenu  onMenuClick={handleMenuClick}/>
        <RenderRoutes />
      </div>
    </AuthContext.Provider>
  );
};

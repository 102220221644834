import React from "react";
import { useNavigate  } from "react-router-dom";
import { spacingStyles } from "../../assets/styles/global.js";

function PageHeader() {
  const navigation = useNavigate();
  
  return (
    <div className="" onClick={() => {
      navigation("/");
    }}>
        <img
          className="noLogLogo"
          style={{ ...spacingStyles.pt10 }}
          src={require("../../assets/logo_light.png")}
          alt="Logo"
        />
    </div>
  );
}

export default PageHeader;

import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MdCalendarMonth } from "react-icons/md";

import { colors, globalStyles, spacingStyles } from "../../../assets/styles/global";
import ConfirmedCard from "../../../Components/Bookings/ConfirmedCard";
import PendingCard from "../../../Components/Bookings/PendingCard";
import CanceledCard from "../../../Components/Bookings/CanceledCard.js";
import { fetchSchedule } from "../../../Services/APIHandlers/ScheduleApi";
import BookingsWeekCalendar from "../../../Components/Bookings/BookingsWeekCalendar";
import Loader from "../../../Components/Common/Loader";
import { Grid } from "@mui/material";

const tabNames = ["CONFIRMED", "PENDING", "CANCELED"];

const Bookings = () => {
  const token = useSelector((state) => state.auth.token);

  const [isLoading, setLoading] = useState(false);
  const [bookedMe, setBookedMe] = useState([]);

  const [activeTab, setActiveTab] = useState("CONFIRMED");

  useEffect(() => {
    const getSchedule = async () => {
      try {
        setLoading(true);
        const { data } = await fetchSchedule(token);
        //console.log("..data", JSON.stringify(data));
        setBookedMe(data.bookedMe);
      } catch (error) {
        console.log(">>>Error in getSchedule", error);
      } finally {
        setLoading(false);
      }
    };

    getSchedule();
  }, [token]);

  const Tabs = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
          marginTop: 20,
        }}
      >
        {tabNames.map((tabName, index) => {
          const isActive = activeTab === tabName;
          const isLast = index === tabNames.length - 1;

          return (
            <div
              key={tabName}
              className="textButton"
              style={{
                borderBottomWidth: 2,
                borderColor: isActive
                  ? colors.orange.color
                  : colors.dark_gray.color,
              }}
              onClick={() => setActiveTab(tabName)}
            >
              <div
                style={{
                  color: isActive ? "#fff" : "#bbb",
                  fontWeight: isActive ? "700" : "400",
                  paddingLeft: index === 0 ? 0 : 15,
                  paddingRight: isLast ? 0 : 15,
                  paddingBottom: 10,
                  fontSize: 18,
                }}
              >
                {tabName}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const EmptyComponent = () =>
    isLoading ? (
      <Loader />
    ) : (
      <div
        style={{
          gap: 10,
          marginTop: 50,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            ...globalStyles.h1,
            maxWidth: "70%",
            textAlign: "center",
            color: colors.dark_gray.color,
          }}
        >
          You don't have any sessions booked, yet.
        </div>
        <MdCalendarMonth size={40} color={colors.dark_gray.color} />
      </div>
    );

  return (
    <div
      style={{
        ...spacingStyles.desktopMaxWidthTwoCol,
      }}
    >
      <div>
        <h1 style={{ ...spacingStyles.pb20 }}>Bookings</h1>

        <BookingsWeekCalendar />

        <Tabs />

        <div style={{ marginTop: 12, paddingBottom: 40 }}>
          {activeTab === "CONFIRMED" && (
            <div>
              <Grid container columnSpacing={5} rowSpacing={2} width="100%">
                {bookedMe?.map((item) => (
                  <Grid item md={6} width="100%" >
                    <ConfirmedCard item={item} />
                  </Grid>
                ))}
              </Grid>
              {!bookedMe?.length && !isLoading && <EmptyComponent />}
            </div>
          )}

          {activeTab === "PENDING" && (
            <div>
              <Grid container columnSpacing={5} rowSpacing={2} width="100%">
                {dummyPending?.map((item) => (
                  <Grid item md={6} width="100%" >
                    <PendingCard item={item} />
                  </Grid>
                ))}
              </Grid>
              {!dummyPending?.length && !isLoading && <EmptyComponent />}
            </div>
          )}

          {activeTab === "CANCELED" && (
            <div>
              <Grid container columnSpacing={5} rowSpacing={2} width="100%">
                {dummyCanceled?.map((item) => (
                  <Grid item md={6} width="100%">
                    <CanceledCard item={item} />
                  </Grid>
                ))}
              </Grid>
              {!dummyCanceled?.length && !isLoading && <EmptyComponent />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Bookings;

const dummyConfirmed = [
  {
    id: 0,
    time: "8:00am-8:45am",
    gym: "Florida Kickboxing",
    location: "Riviera Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Rodrigo H",
    checkedIn: true,
  },
  {
    id: 1,
    time: "10:00am-10:45am",
    gym: "Florida Kickboxing",
    location: "Riviera Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Luis S",
    checkedIn: false,
  },
  {
    id: 2,
    time: "1:00pm-1:45am",
    gym: "Fight Club of Palm Beach",
    location: "West Palm Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Luis S",
    checkedIn: false,
  },
];

const dummyPending = [
  {
    id: 0,
    time: "8:00am-8:45am",
    gym: "Florida Kickboxing",
    location: "Riviera Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Rodrigo H",
    checkedIn: true,
  },
  {
    id: 1,
    time: "10:00am-10:45am",
    gym: "Florida Kickboxing",
    location: "Riviera Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Luis S",
    checkedIn: false,
  },
  {
    id: 2,
    time: "10:00am-10:45am",
    gym: "Florida Kickboxing",
    location: "Riviera Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Luis S",
    checkedIn: false,
  },
  {
    id: 3,
    time: "10:00am-10:45am",
    gym: "Florida Kickboxing",
    location: "Riviera Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Luis S",
    checkedIn: false,
  },
  {
    id: 4,
    time: "10:00am-10:45am",
    gym: "Florida Kickboxing",
    location: "Riviera Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Luis S",
    checkedIn: false,
  },
];

const dummyCanceled = [
  {
    id: 0,
    time: "8:00am-8:45am",
    gym: "Fight club of Palm Beach",
    location: "West Palm Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Rodrigo H",
    checkedIn: true,
  },
  {
    id: 1,
    time: "8:00am-8:45am",
    gym: "Fight club of Palm Beach",
    location: "West Palm Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Rodrigo H",
    checkedIn: true,
  },
  {
    id: 3,
    time: "8:00am-8:45am",
    gym: "Fight club of Palm Beach",
    location: "West Palm Beach, FL",
    image: "https://picsum.photos/200",
    trainer: "Rodrigo H",
    checkedIn: true,
  },
];

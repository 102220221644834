import React from "react";
import { Switch, FormControlLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FaSun, FaMoon } from "react-icons/fa";
import { colors } from "../../assets/styles/global";
import { BsMoonStars } from "react-icons/bs";

// Custom styled switch to display sun and moon icons
const StyledSwitch = styled(({ darkMode, ...props }) => <Switch {...props} />)(({ theme, darkMode }) => ({
  width: 50,
  height: 28,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: colors.dark_gray.color,
      },
    },
    "& .MuiSwitch-thumb:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: !darkMode
        ? `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 25 25"><path fill="${encodeURIComponent(
          colors.white.color
        )}" d="m17.75 4.09l-2.53 1.94l.91 3.06l-2.63-1.81l-2.63 1.81l.91-3.06l-2.53-1.94L12.44 4l1.06-3l1.06 3zm3.5 6.91l-1.64 1.25l.59 1.98l-1.7-1.17l-1.7 1.17l.59-1.98L15.75 11l2.06-.05L18.5 9l.69 1.95zm-2.28 4.95c.83-.08 1.72 1.1 1.19 1.85c-.32.45-.66.87-1.08 1.27C15.17 23 8.84 23 4.94 19.07c-3.91-3.9-3.91-10.24 0-14.14c.4-.4.82-.76 1.27-1.08c.75-.53 1.93.36 1.85 1.19c-.27 2.86.69 5.83 2.89 8.02a9.96 9.96 0 0 0 8.02 2.89m-1.64 2.02a12.08 12.08 0 0 1-7.8-3.47c-2.17-2.19-3.33-5-3.49-7.82c-2.81 3.14-2.7 7.96.31 10.98c3.02 3.01 7.84 3.12 10.98.31"/></svg>')`
        : `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="20" viewBox="0 0 25 25"><path fill="${encodeURIComponent(
          colors.white.color
        )}" d="m5.34 6.25l1.42-1.41l-1.8-1.79l-1.41 1.41zM1 10.5h3v2H1zM11 .55h2V3.5h-2zm7.66 5.705l-1.41-1.407l1.79-1.79l1.406 1.41zM17.24 18.16l1.79 1.8l1.41-1.41l-1.8-1.79zM20 10.5h3v2h-3zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6s6-2.69 6-6s-2.69-6-6-6m0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4s4 1.79 4 4s-1.79 4-4 4m-1 4h2v2.95h-2zm-7.45-.96l1.41 1.41l1.79-1.8l-1.41-1.41z"/></svg>')`,
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: darkMode ? colors.dark_gray.color : colors.gray.color,
    width: 24,
    height: 24,
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: darkMode ? colors.darker_gray.color : colors.light_gray.color,
    borderRadius: 20 / 2,
  },
}));

const DarkModeSwitch = ({ darkMode, toggleDarkMode }) => {

  return (
    <FormControlLabel
      control={
        <StyledSwitch
          checked={darkMode}
          onChange={toggleDarkMode}
          darkMode={darkMode} // Pass darkMode as a prop to the styled switch for styling
          inputProps={{ "aria-label": "dark mode switch" }}
        />
      }
    />
  );
};

export default DarkModeSwitch;

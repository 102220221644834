import React, { useRef, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { globalStyles, spacingStyles } from "../../assets/styles/global";
import mapboxgl from "mapbox-gl";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAmericas, faBars } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

mapboxgl.accessToken =
  "pk.eyJ1IjoiaG9wcGVyZml0IiwiYSI6ImNsa2tpM2c0ZzA2dWkzcG1tNnV3MmVyMjQifQ.x9vQVrNmILfTln-SAXS8pw";

export default function MapSearch() {
  const navigate = useNavigate();
  const location = useSelector((state) => state.location);
  const similarFitnessTrainers = useSelector(
    (state) => state.matchMe.similarFitnessTrainers
  );

  useEffect(() => {
    if (!similarFitnessTrainers?.length) {
      navigate("/");
      return;
    }
  }, [navigate, similarFitnessTrainers]);

  const scrollableDivRef = useRef(null);

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-80.0534);
  const [lat, setLat] = useState(26.7153);
  const [zoom, setZoom] = useState(9);

  const [selectedMarker, setSelectedMarker] = useState(null);

  const [showList, setShowList] = useState(false);

  // useEffect(() => {
  //   if (location) {
  //     getSites();
  //   }
  // }, [location]);

  // useEffect(() => {
  //   //console.log(selectedMarker);
  // }, [selectedMarker]);

  const locations = useMemo(() => {
    return similarFitnessTrainers?.map((data) => ({
      longitude: data.location[0].long,
      latitude: data.location[0].lat,
    }));
  }, [similarFitnessTrainers]);

  // console.log("..locations", locations);

  const bounds = useMemo(() => {
    if (!locations?.length) return null;

    const sw = new mapboxgl.LngLat(
      locations[0].longitude,
      locations[0].latitude
    );
    const ne = new mapboxgl.LngLat(
      locations[0].longitude,
      locations[0].latitude
    );
    const llb = new mapboxgl.LngLatBounds(sw, ne);

    return locations?.reduce(function (bounds, loc) {
      return bounds.extend(new mapboxgl.LngLat(loc.longitude, loc.latitude));
    }, llb);
  }, [locations]);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/dark-v11",
      center: [lng, lat],
      zoom: zoom,
    });

    if (bounds) {
      map.current.fitBounds(bounds, {
        padding: { top: 100, bottom: 100, left: 100, right: 100 },
      });
    }
  }, [bounds, lat, lng, zoom]);

  useEffect(() => {
    //console.log("Set Markers");
    if (map.current && similarFitnessTrainers) {
      similarFitnessTrainers.forEach((markerInfo, index) => {
        //console.log(markerInfo);
        const el = document.createElement("div");
        //el.className = "marker";
        el.style.backgroundImage = `url(${markerInfo.image})`;
        el.style.width = `40px`;
        el.style.height = `40px`;
        el.style.backgroundSize = "cover";
        el.style.backgroundPosition = "center";
        el.style.borderRadius = "50px";
        el.style.borderColor = "#525252";
        el.style.borderStyle = "solid";
        el.style.borderWidth = "3px";

        new mapboxgl.Marker(el)
          .setLngLat([markerInfo.location[0].long, markerInfo.location[0].lat])
          // .setPopup(new mapboxgl.Popup().setHTML(
          //   `<div>
          //       <img class="mapbox-modal-img" src=${markerInfo.image} style="max-width: 100%;" >
          //       <p class="mapbox-modal-text">${markerInfo.name}</p>
          //    </div>`))

          .addTo(map.current);
        // .getPopup()
        // .on('open', function(e) {
        //   console.log("Popup opened:", markerInfo.image);
        // });

        el.addEventListener("click", () => {
          // map.current.flyTo({
          //   center: [markerInfo.location[0].long, markerInfo.location[0].lat],
          //   zoom: 14, // You can adjust the zoom level as needed
          // });
          //console.log("Marker clicked:", markerInfo);
          setSelectedMarker(markerInfo);
          window.open("/" + markerInfo.slug);
        });
      });
    }
  }, [similarFitnessTrainers]);

  const ScrollableList = ({ data }) => {
    return (
      <ul
        className="horScroll back-black"
        style={{
          ...spacingStyles.punset,
          ...spacingStyles.munset,
          ...localStyles.horScroll,
        }}
      >
        {data?.map((item, index) => (
          <li
            key={index}
            onClick={() => {
              map.current.flyTo({
                center: [item.location[0].long, item.location[0].lat],
                zoom: 14, // You can adjust the zoom level as needed
              });
              setSelectedMarker(item);
              window.open("/" + item.slug);
            }}
            style={{ ...localStyles.scrollContainer, ...spacingStyles.pb20 }}
          >
            {/* <p>{JSON.stringify(item)}</p> */}
            <div className="flex" style={{ ...localStyles.gradOverlay }}>
              <div style={localStyles.gradOverlayBefore}></div>
              <img src={item.image} alt="Logo" className="searchImg" />
              <p style={localStyles.scrollName}>{item.name}</p>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  if (location?.coords?.latitude && location.coords.longitude) {
    // const viewport = {
    //   latitude: location.coords.latitude,
    //   longitude: location.coords.longitude,
    //   zoom: 14,
    // };
  }

  const getScrollPosition = () => {
    return window.scrollY;
  };

  const handleClick = () => {
    //const scrollPosition = getScrollPosition();
    //console.log('Scroll Position:', scrollPosition);

    const mobileSearchBtn = document.querySelector(".search-list-container");
    mobileSearchBtn.classList.add("clicked");

    setShowList((prevState) => !prevState);

    if (!showList) {
      setViewMode({ label: "View Map", icon: faEarthAmericas });
    } else {
      setViewMode({ label: "View List", icon: faBars });
    }
  };
  const [viewMode, setViewMode] = useState({
    label: "View List",
    icon: faBars,
  });

  return (
    <>
      <div
        style={{
          ...globalStyles.h1,
          ...globalStyles.pt20,
          ...globalStyles.pb20,
        }}
      >
        Similar Fitness Trainers
      </div>

      <div className="search-container">
        <div
          className={`search-list-container ${showList ? "clicked" : ""}`}
          ref={scrollableDivRef}
        >
          <div className="mobile-search-btn" onClick={handleClick}>
            <h2 className="gray view-toggle">
              {viewMode.label}{" "}
              <FontAwesomeIcon
                icon={viewMode.icon}
                style={{ ...spacingStyles.pl10 }}
              />
            </h2>
          </div>
          <ScrollableList className="scrollist" data={similarFitnessTrainers} />
        </div>
        <div ref={mapContainer} className="map-container" />
      </div>

      <div></div>
    </>
  );
}

const localStyles = {
  mapContainer: {
    overflow: "auto",
    height: "90vh",
  },
  gradOverlay: {
    position: "relative",
  },
  gradOverlayBefore: {
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0) 35%  , rgba(0,0,0,0.65) 85% , rgba(0,0,0,0.65) 100%)",
  },
  scrollContainer: {
    position: "relative", // For creating the context for absolutely positioned scrollName
    // width: '100%', // Ensure the scrollName spans full width within the horScroll container
    padding: "5px",
  },
  horScroll: {
    display: "flex",
    position: "relative",
  },
  scrollName: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%", // Ensure scrollName spans full width within the scrollContainer
    margin: 0,
    padding: "8px", // Add padding to create space between image and text
    color: "#fff", // Set text color
    // background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  },
};

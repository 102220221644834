import React from "react";
import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { colors, globalStyles } from "../../assets/styles/global";
import { addBusiness } from "../../Services/Redux/Slices/Business.Slice";

import { updateBusiness } from "../../Services/APIHandlers/ProfileApi";

//api
import { addUser } from "../../Services/Redux/Slices/User.Slice";

import getErrorMessage from "../../Services/Helpers/getErrorMessage";
import { Button } from "../Common/Buttons/Button";
import { useNavigate } from "react-router";
import {
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const ProfileEditForm = ({ onClose, field }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [disabled, setDisabled] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const user = useSelector((state) => state.user);
  const [userData, setUserData] = useState(user);

  const business = useSelector((state) => state.business);
  const [businessData, setbusinessData] = useState(business);

  const token = useSelector((state) => state.user.userToken);

  const [sociallinks, setLinks] = useState({ name: "", link: "" });

  const handleBusiness = async () => {
    try {
      setSubmitting(true);
      const response = await updateBusiness(JSON.stringify(businessData), token);

      if (response.data.success) {
        // await AsyncStorage.setItem("businessData", JSON.stringify(businessData));
        localStorage.setItem("businessData", JSON.stringify(businessData));

        onClose();
      } else {
        console.log(response.data);
      }
    } catch (err) {
      alert(getErrorMessage(err));
    } finally {
      setSubmitting(false);
    }
  };
  const handleContact = async () => {
    try {
      // const response = await updateContact(JSON.stringify(userData), token);
      // if (response.data.success) {
      //     navigation.goBack();
      // } else {
      //     console.log(response.data)
      // }
    } catch (err) {
      //setLoading(false);
      console.error(err);
    }
  };

  const specialtyOptions = [
    { name: "boxing", display: "Boxing/Kickboxing" },
    { name: "bodybuilding", display: "Bodybuilding" },
    { name: "crossfit", display: "CrossFit" },
    { name: "corrective", display: "Corrective Exercise" },
    { name: "dance", display: "Dance Fitness" },
    { name: "flexibility", display: "Flexibility" },
    { name: "hiit", display: "HIIT" },
    { name: "nutrition", display: "Nutrition and Dieting" },
    { name: "performance", display: "Performance" },
    { name: "pilates", display: "Pilates" },
    { name: "seniors", display: "Senior Fitness" },
    { name: "strength", display: "Strength and Conditioning" },
    { name: "water aerobics", display: "Water Aerobics" },
    { name: "weight loss", display: "Weight Loss Transformation" },
    { name: "yoga", display: "Yoga" },
    { name: "youth", display: "Youth Fitness" },
  ];

  const yearsExp = [
    { name: ">1", display: "Less than 1 year" },
    { name: "1-3", display: "1-3 years" },
    { name: "4-10", display: "4-10 years" },
    { name: "10-20", display: "10-20 years" },
    { name: "20+", display: "20+ years" },
  ];

  const languages = [
    { name: "English", display: "English" },
    { name: "Spanish", display: "Spanish" },
    { name: "French", display: "French" },
    { name: "Portuguese", display: "Portuguese" },
    { name: "German", display: "German" },
    { name: "Vietnamese", display: "Vietnamese" },
    { name: "Italian", display: "Italian" },
    { name: "Arabic", display: "Arabic" },
    { name: "Chinese", display: "Chinese" },
    { name: "Russian", display: "Russian" },
    { name: "Polish", display: "Polish" },
  ];
  
  
  const fieldsArray = [
    { name: "BusinessName", display: "Name Your Listing" },
    { name: "Description", display: "Describe your space" },
    { name: "Certifications",display: "Certifications" },
    { name: "Meta", display: "Add Tags" },
    { name: "Specialty", display: "Select Your Specailty" },
    { name: "Experience", display: "Experience Years" },
    { name: "Languages", display: "Languages" },
    { name: "Name", display: "English" },
    { name: "Facebook", display: "Facebook" },
    { name: "Instagram", display: "Instagram" },
    { name: "Youtube", display: "English" },
    { name: "TikTok", display: "Youtube" },
    { name: "Website", display: "Website" },
  ];

  const getLabelByFieldKey = (array = fieldsArray, fieldKey) => {
    const result = array.find(item => item.name === fieldKey);
    return result ? result.display : null;
  };

  const label = getLabelByFieldKey(fieldsArray, field);

  return (
    <Dialog
      open
      maxWidth="md"
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: colors.darker_gray.color,
          padding: 20,
          borderRadius: 20,
          minWidth: "30vw",
          maxWidth: 480,
        },
      }}
    >
      <div>
        <div
          style={{
            ...globalStyles.cust_header,
            ...globalStyles.pb40,
            ...globalStyles.mt40,
          }}
        >
          <img
            style={{ ...globalStyles.noLogFavicon, objectFit: "contain" }}
            src={require("../../assets/favicon.png")}
            alt="favicon"
          />
          <div
            style={{
              ...globalStyles.h1,
              ...globalStyles.mt10,
              ...globalStyles.center,
            }}
          >
            {label}
          </div>
          <div style={{ ...globalStyles.p, ...globalStyles.center }}>
            Enter your info and save to your account.
          </div>
        </div>
        <div>
          {field === "BusinessName" ? (
            <div style={{ ...globalStyles.pb20 }}>
              <input
                style={{
                  ...globalStyles.mb20,
                  ...globalStyles.input,
                  ...styles.input,
                  ...globalStyles.mt10,
                }}
                placeholder={businessData.name ? businessData.name : ""}
                onChange={(e) => {
                  setbusinessData({ ...businessData, name: e.target.value });
                  setDisabled(false);
                }}
              />

              <Button
                fill="solid"
                text="Save"
                onClick={() => {
                  dispatch(
                    addBusiness({ ...businessData, name: businessData.name })
                  );
                  handleBusiness();
                }}
                disabled={disabled || submitting}
              ></Button>
            </div>
          ) : null}
          {field === "Description" ? (
            <div style={{ ...globalStyles.pb20 }}>
              <textarea
                style={{
                  ...globalStyles.mb20,
                  ...globalStyles.input,
                  ...styles.input,
                  ...globalStyles.mt10,
                }}
                maxLength={175}
                defaultValue={
                  businessData.description ? businessData.description : ""
                }
                placeholder={
                  businessData.description ? businessData.description : ""
                }
                onChange={(e) => {
                  setbusinessData({
                    ...businessData,
                    description: e.target.value,
                  });
                  setDisabled(false);
                }}
              />

              <Button
                fill="solid"
                text="Save"
                onClick={() => {
                  dispatch(
                    addBusiness({
                      ...businessData,
                      description: businessData.description,
                    })
                  );
                  handleBusiness();
                }}
                disabled={disabled || submitting}
              ></Button>
            </div>
          ) : null}
          {field === "Certifications" ? (
            <div style={{ ...globalStyles.pb20 }}>
              <input
                style={{
                  ...globalStyles.mb10,
                  ...globalStyles.input,
                  ...styles.input,
                  ...globalStyles.mt10,
                }}
                placeholder={
                  business.certifications ? business.certifications : ""
                }
                defaultValue={
                  businessData.certifications ? businessData.certifications : ""
                }
                onChange={(e) => {
                  setbusinessData({
                    ...businessData,
                    certifications: e.target.value,
                  });
                  setDisabled(false);
                }}
              />

              <span
                style={{
                  ...globalStyles.p,
                  ...globalStyles.mb10,
                  ...globalStyles.center,
                  ...globalStyles.light_gray,
                }}
              >
                Separate each item with a comma(,)
              </span>

              <Button
                fill="solid"
                text="Save"
                onClick={() => {
                  dispatch(
                    addBusiness({
                      ...businessData,
                      certifications: businessData.certifications,
                    })
                  );
                  handleBusiness();
                }}
                disabled={disabled || submitting}
              ></Button>
            </div>
          ) : null}
          {field === "Meta" ? (
            <div style={{ ...globalStyles.pb20 }}>
              <input
                style={{
                  ...globalStyles.mb10,
                  ...globalStyles.input,
                  ...styles.input,
                  ...globalStyles.mt10,
                }}
                placeholder={business.meta ? business.meta : ""}
                defaultValue={businessData.meta}
                onChange={(e) => {
                  setbusinessData({ ...businessData, meta: e.target.value });
                  setDisabled(false);
                }}
              />

              <span
                style={{
                  ...globalStyles.p,
                  ...globalStyles.mb10,
                  ...globalStyles.center,
                  ...globalStyles.light_gray,
                }}
              >
                Separate each item with a comma(,)
              </span>

              <div
                style={{
                  ...globalStyles.badgesContainer,
                  ...globalStyles.mb40,
                }}
              >
                <span style={{ ...globalStyles.h3 }}>
                  {/* {renderBadges(businessData.meta)} */}
                </span>
              </div>

              <Button
                fill="solid"
                text="Save"
                onClick={() => {
                  dispatch(
                    addBusiness({ ...businessData, meta: businessData.meta })
                  );
                  handleBusiness();
                }}
                disabled={disabled || submitting}
              ></Button>
            </div>
          ) : null}
          {field === "Specialty" ? (
            <div style={{ ...globalStyles.pb20, ...globalStyles.flexCol }}>
              <div style={{ ...globalStyles.pb20 }}>
                <FormControl fullWidth>
                  {!businessData.specialty && (
                    <InputLabel id="specialtySelect">
                      Select your Specialty
                    </InputLabel>
                  )}
                  <Select
                    labelId="specialtySelect"
                    id="specialtySelect-simple-select"
                    onChange={(e) => {
                      setbusinessData({
                        ...businessData,
                        specialty: e.target.value,
                      });
                      setDisabled(false);
                    }}
                    value={businessData.specialty || ""}
                    sx={{ backgroundColor: "#fff", borderRadius: 10 }}
                  >
                    {specialtyOptions.map((specialty) => (
                      <MenuItem key={specialty.name} value={specialty.name}>
                        {specialty.display}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <Button
                fill="solid"
                text="Save"
                onClick={() => {
                  dispatch(
                    addBusiness({
                      ...businessData,
                      specialty: businessData.specialty,
                    })
                  );
                  handleBusiness();
                }}
                disabled={disabled || submitting}
              ></Button>
            </div>
          ) : null}
          {field === "Experience" ? (
            <div style={{ ...globalStyles.pb20, ...globalStyles.flexCol }}>
              <div style={{ ...globalStyles.pb20 }}>
                <FormControl fullWidth>
                  {!businessData.specialty && (
                    <InputLabel id="yearsExperience">
                      Years Experience
                    </InputLabel>
                  )}
                  <Select
                    labelId="yearsExperience"
                    id="yearsExperience-simple-select"
                    onChange={(e) => {
                      setbusinessData({
                        ...businessData,
                        years_exp: yearsExp
                          .filter((option) => option.name == e.target.value)
                          .map((item) => item.name)[0],
                      });
                      setDisabled(false);
                    }}
                    value={businessData.years_exp || ""}
                    sx={{ backgroundColor: "#fff", borderRadius: 10 }}
                  >
                    {yearsExp.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.display}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <Button
                fill="solid"
                text="Save"
                onClick={() => {
                  dispatch(
                    addBusiness({
                      ...businessData,
                      years_exp: businessData.years_exp,
                    })
                  );
                  handleBusiness();
                }}
                disabled={disabled || submitting}
              ></Button>
            </div>
          ) : null}
          {field === "Languages" ? (
            <div style={{ ...globalStyles.pb20, ...globalStyles.flexCol }}>
              <div style={{ ...globalStyles.pb20 }}>
                <FormControl fullWidth>
                  {!businessData.language && (
                    <InputLabel id="yearsExperience">
                      Your primary language
                    </InputLabel>
                  )}
                  <Select
                    labelId="yearsExperience"
                    id="yearsExperience-simple-select"
                    onChange={(e) => {
                      setbusinessData({
                        ...businessData,
                        language: languages
                          .filter((option) => option.display == e.target.value)
                          .map((item) => item.name)[0],
                      });
                      setDisabled(false);
                    }}
                    value={businessData.language || ""}
                    sx={{ backgroundColor: "#fff", borderRadius: 10 }}
                  >
                    {languages.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.display}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <Button
                fill="solid"
                text="Save"
                onClick={() => {
                  dispatch(
                    addBusiness({
                      ...businessData,
                      language: businessData.language,
                    })
                  );
                  handleBusiness();
                }}
                disabled={disabled || submitting}
              ></Button>
            </div>
          ) : null}
          {field === "Name" ? (
            <div style={{ ...globalStyles.pb20 }}>
              <div style={{ ...globalStyles.label }}>First Name:</div>
              <input
                style={{
                  ...globalStyles.mb20,
                  ...globalStyles.input,
                  ...styles.input,
                  ...globalStyles.mt10,
                }}
                placeholder={user.user_name ? user.user_name : ""}
                onChange={(e) => {
                  setUserData({ ...userData, user_name: e.target.value });
                  setDisabled(false);
                }}
              />
              <div style={{ ...globalStyles.label }}>Last Name:</div>
              <input
                style={{
                  ...globalStyles.mb20,
                  ...globalStyles.input,
                  ...styles.input,
                  ...globalStyles.mt10,
                }}
                placeholder={user.user_lastname ? user.user_lastname : ""}
                onChange={(e) => {
                  setUserData({ ...userData, user_lastname: e.target.value });
                  setDisabled(false);
                }}
              />
              <Button
                fill="solid"
                text="Save"
                onClick={() => {
                  dispatch(addUser({ ...userData }));
                  handleContact();
                }}
                disabled={disabled || submitting}
              ></Button>
            </div>
          ) : null}
          {field === "Facebook" ? (
            <div style={{ ...globalStyles.pb20 }}>
              <span
                style={{
                  ...globalStyles.h2,
                  ...globalStyles.center,
                  marginRight: 8,
                }}
              >
                Username only:
              </span>
              <span
                style={{
                  ...globalStyles.h3,
                  ...globalStyles.light_gray,
                  ...globalStyles.pb10,
                  ...globalStyles.center,
                }}
              >
                {sociallinks.link}
              </span>
              <input
                style={{
                  ...globalStyles.mb20,
                  ...globalStyles.input,
                  ...styles.input,
                  ...globalStyles.mt10,
                }}
                placeholder={user.user_name ? user.user_name : ""}
                onChange={(e) => {
                  const formattedLinks = {
                    name: "Facebook",
                    link: `https://www.facebook.com/${e.target.value}`,
                  };

                  setLinks(formattedLinks);
                  setbusinessData({ ...businessData, facebook: e.target.value });
                  setDisabled(false);
                }}
              />

              <Button
                fill="solid"
                text="Save"
                onClick={() => {
                  dispatch(
                    addBusiness({
                      ...businessData,
                      facebook: businessData.facebook,
                    })
                  );
                  handleBusiness();
                }}
                disabled={disabled || submitting}
              ></Button>
            </div>
          ) : null}
          {field === "Instagram" ? (
            <div style={{ ...globalStyles.pb20 }}>
              <span
                style={{
                  ...globalStyles.h2,
                  ...globalStyles.center,
                  marginRight: 8,
                }}
              >
                Username only:
              </span>
              <span
                style={{
                  ...globalStyles.h3,
                  ...globalStyles.light_gray,
                  ...globalStyles.pb10,
                  ...globalStyles.center,
                }}
              >
                {sociallinks.link}
              </span>
              <input
                style={{
                  ...globalStyles.mb20,
                  ...globalStyles.input,
                  ...styles.input,
                  ...globalStyles.mt10,
                }}
                placeholder={user.user_name ? user.user_name : ""}
                onChange={(e) => {
                  const formattedLinks = {
                    name: "Instagram",
                    link: `https://www.instagram.com/${e.target.value}`,
                  };
                  setLinks(formattedLinks);

                  setbusinessData({ ...businessData, instagram: e.target.value });

                  setDisabled(false);
                }}
              />

              <Button
                fill="solid"
                text="Save"
                onClick={() => {
                  dispatch(
                    addBusiness({
                      ...businessData,
                      instagram: businessData.instagram,
                    })
                  );
                  handleBusiness();
                }}
                disabled={disabled || submitting}
              ></Button>
            </div>
          ) : null}
          {field === "Youtube" ? (
            <div style={{ ...globalStyles.pb20 }}>
              <span
                style={{
                  ...globalStyles.h2,
                  ...globalStyles.center,
                  marginRight: 8,
                }}
              >
                Username only:
              </span>
              <span
                style={{
                  ...globalStyles.h3,
                  ...globalStyles.light_gray,
                  ...globalStyles.pb10,
                  ...globalStyles.center,
                }}
              >
                {sociallinks.link}
              </span>
              <input
                style={{
                  ...globalStyles.mb20,
                  ...globalStyles.input,
                  ...styles.input,
                  ...globalStyles.mt10,
                }}
                placeholder={user.user_name ? user.user_name : ""}
                onChange={(e) => {
                  const formattedLinks = {
                    name: "YouTube",
                    link: `https://www.youtube.com/user/${e.target.value}`,
                  };
                  setLinks(formattedLinks);

                  setbusinessData({ ...businessData, youtube: e.target.value });

                  setDisabled(false);
                }}
              />

              <Button
                fill="solid"
                text="Save"
                onClick={() => {
                  dispatch(
                    addBusiness({
                      ...businessData,
                      youtube: businessData.youtube,
                    })
                  );
                  handleBusiness();
                }}
                disabled={disabled || submitting}
              ></Button>
            </div>
          ) : null}
          {field === "TikTok" ? (
            <div style={{ ...globalStyles.pb20 }}>
              <span
                style={{
                  ...globalStyles.h2,
                  ...globalStyles.center,
                  marginRight: 8,
                }}
              >
                Username only:
              </span>
              <span
                style={{
                  ...globalStyles.h3,
                  ...globalStyles.light_gray,
                  ...globalStyles.pb10,
                  ...globalStyles.center,
                }}
              >
                {sociallinks.link}
              </span>
              <input
                style={{
                  ...globalStyles.mb20,
                  ...globalStyles.input,
                  ...styles.input,
                  ...globalStyles.mt10,
                }}
                placeholder={user.user_name ? user.user_name : ""}
                onChange={(e) => {
                  const formattedLinks = {
                    name: "TikTok",
                    link: `https://www.tiktok.com/@${e.target.value}`,
                  };

                  setLinks(formattedLinks);

                  setbusinessData({ ...businessData, tiktok: e.target.value });

                  setDisabled(false);
                }}
              />

              <Button
                fill="solid"
                text="Save"
                onClick={() => {
                  dispatch(
                    addBusiness({ ...businessData, tiktok: businessData.tiktok })
                  );
                  handleBusiness();
                }}
                disabled={disabled || submitting}
              ></Button>
            </div>
          ) : null}
          {field === "Website" ? (
            <div style={{ ...globalStyles.pb20 }}>
              <span
                style={{
                  ...globalStyles.h2,
                  ...globalStyles.center,
                  ...globalStyles.pb5,
                  marginRight: 8,
                }}
              >
                Domain Only:
              </span>
              <span
                style={{
                  ...globalStyles.h3,
                  ...globalStyles.light_gray,
                  ...globalStyles.pb20,
                  ...globalStyles.center,
                }}
              >
                {sociallinks.link}
              </span>
              <input
                style={{
                  ...globalStyles.mb20,
                  ...globalStyles.input,
                  ...styles.input,
                  ...globalStyles.mt10,
                }}
                placeholder={user.user_name ? user.user_name : ""}
                onChange={(e) => {
                  const formattedLinks = {
                    name: "Website",
                    link: `https:// ${e.target.value}`,
                  };

                  setLinks(formattedLinks);

                  setbusinessData({ ...businessData, website: e.target.value });

                  setDisabled(false);
                }}
              />

              <Button
                fill="solid"
                text="Save"
                onClick={() => {
                  dispatch(
                    addBusiness({
                      ...businessData,
                      website: businessData.website,
                    })
                  );
                  handleBusiness();
                }}
                disabled={disabled || submitting}
              ></Button>
            </div>
          ) : null}
        </div>
      </div>
    </Dialog>
  );
};

export default ProfileEditForm;

const styles = {
  input: {
    width: "94%",
  },
};

import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdAccountCircle, MdInfo } from "react-icons/md";

//styles
import { colors, globalStyles } from "../../assets/styles/global";
import SlideInPanel from "../Modals/slideInPanel";
import ImageUploader from "../Common/ImageUpload";
import { updateProfilePicture } from "../../Services/APIHandlers/ProfileApi";
import { addProfilePic } from "../../Services/Redux/Slices/User.Slice";

const ProfileHeader = ({ setShowProfile, showProfile, setShowImageModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const business = useSelector((state) => state.business);
  const token = useSelector((state) => state.auth.token);

  const [isPanelOpen, setPanelOpen] = React.useState(false);

  const onLoadProfilePicSuccess = async (picture_id) => {
    await updateProfilePicture(picture_id, token);
    setPanelOpen(false);
    window.location.reload();
  };

  const goToOnboarding = () => {
    const role_id = String(auth.role_id);
    switch (role_id) {
      case "2":
        return navigate("/SiteOnboarding");
      case "3":
        return navigate("/TrainerOnboarding");
      default:
        return;
    }
  };

  var horBackgroundStyle = {
    backgroundImage: "url(" + (user?.profilepic ? user.profilepic : require("../../assets/camera.png")) + ")",
    height: "108px",
    width: "108px",
    borderRadius: "5em",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const renderProfileTab = (profileType, label) => (
    <span
      className="textButton"
      style={{
        ...globalStyles.fullWidth,
        ...(showProfile === profileType ? globalStyles.bbo : globalStyles.bb0),
      }}
      onClick={() => setShowProfile(profileType)}
    >
      <span
        style={{
          ...globalStyles.h3,
          fontSize: 15,
          ...globalStyles.allCaps,
          ...(showProfile === profileType ? globalStyles.orange : {}),
        }}
      >
        {label}
      </span>
    </span>
  );


  return (
    <>
      <SlideInPanel
        isOpen={isPanelOpen}
        closePanel={() => setPanelOpen(false)}
        content={ImageUploader}
        onSuccess={onLoadProfilePicSuccess}
      />

      <div>
        <div
          style={{
            ...styles.headerContainer,
            ...globalStyles.plr10,
            borderTopColor: "black",
            borderTopWidth: 0,
          }}
        >
          <div
            style={{
              ...globalStyles.flexRow,
              ...globalStyles.w100,
              ...globalStyles.pb20,
              ...globalStyles.mt40,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <span className="textButton" onClick={() => setPanelOpen(true)}>
              <div className="shadow" style={horBackgroundStyle}></div>
            </span>
            <div className="right" style={{ ...globalStyles.ml5, ...globalStyles.w100 }}>
              <div style={globalStyles.h1}>{business.name}</div>
              <div style={globalStyles.h2}>
                Status:
                {business.status === "0" ? (
                  <span style={{ color: "#329010", fontWeight: "bold" }}>LIVE</span>
                ) : (
                  <span style={{ fontWeight: "bold", ...globalStyles.errorText }}>In-Active</span>
                )}
              </div>
              <span className="textButton" onClick={goToOnboarding}>
                <MdInfo size={30} color={colors.white.color} style={{ marginTop: 4 }} />
              </span>
            </div>
          </div>
          <div className="four-grid" >
            {renderProfileTab("Contact", "Contact")}
            {renderProfileTab("Gallery", "Gallery")}
            {renderProfileTab("Trainer", "Business")}
            {renderProfileTab("Settings", "Settings")}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileHeader;

const styles = {
  headerContainer: {
    justifyContent: "center",
  },
  profilePic: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  logoHeader: {
    marginBottom: 0,
    paddingBottom: 0,
    height: 35,
    position: "relative",
  },
};

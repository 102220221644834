// src/Home.js
import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";

import GymDetailsHeader from "../../Components/Gym/GymDetailsHeader";

import { spacingStyles, colors, formStyles } from "../../assets/styles/global";

import moment from "moment";

//import { selectDate, selectTime } from '../../Services/Redux/Slices/Booking.Slice.js';

import StripePayment from "../../Components/Forms/StripePayment";
import AuthContext from "../../Navigation/AuthWrapper";

import RegisterForm from "../../Components/Forms/RegisterForm";
import LoginForm from "../../Components/Forms/LoginForm";
import OTPForm from "../../Components/Forms/OTPForm";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import { addBooking } from "../../Services/APIHandlers/BookingApi";


export default function Checkout({ isOpen, mode }) {
  const selectedDate = useSelector((state) => state.booking.selectedDate);
  const selectedTime = useSelector((state) => state.booking.selectedTime);
  const selectedSite = useSelector((state) => state.booking.selectedSite);

  const time24Hour = moment(selectedTime.time, "HH:mm:ss");
  const time12Hour = time24Hour.format("hh:mm a");

  //console.log(isAuthenticated);

  const [isRegistered, setIsRegistered] = useState(null);
  const [isOTPConfirmed, setIsOTPConfirmed] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [authToggle, setAuthToggle] = useState("Login");
  const [bookingID, setBookingID] = useState(null);

  const auth = useContext(AuthContext);

  const textStyle = mode === "light" ? { color: colors.black, inputStyles: formStyles.input_light } : { inputStyles: formStyles.input } ;

  useEffect(() => {
    setIsLogged(auth.token !== "");
    setIsRegistered(false);
  }, []);

  useEffect(() => {
    const handleBookingRequest = async () => {
      console.log(selectedSite);
      const data = {
        bookingTime: selectedTime,
        bookingDate: selectedDate,
        slug: selectedSite.profile?.slug,
      };

      console.log(data);
      try {
        const response = await addBooking(data, auth.token);

        console.log(response.data);
        setBookingID(response.data.BookingID);
      } catch (err) {
        console.error(err);
      }
    };
    // Check if both conditions are true
    if (isRegistered && isOTPConfirmed && isOpen) {
      handleBookingRequest();
    } else if (isLogged && isOTPConfirmed && isOpen) {
      handleBookingRequest();
    }
  }, [isRegistered, isOTPConfirmed, isLogged, isOpen]);

  useEffect(() => {
    //console.log("isRegistered useEffect: ", isRegistered);
  }, [isRegistered]);

  const handleRegistrationSuccess = () => {
    console.log("setIsRegistered: ", true);
    setIsRegistered(true);
  };

  const handleOTPSuccess = () => {
    setIsOTPConfirmed(true);
  };

  const handleAuthToggle = (value) => {
    // This function will be called when a button is clicked
    console.log("Button clicked with value:", value);
    // switch(value){
    //   case 'Login':
    //     setAuthToggle(value);
    //     break;
    //   case 'Register':
    //     break;
    //   default:
    // }
    setAuthToggle(value);
  };

  return (
    <>
      <div style={{ ...spacingStyles.mb20h, ...spacingStyles.pb40 }}>
        <div style={{ ...spacingStyles.mauto }}>
          <div className="centerView">
            <GymDetailsHeader
              Data={selectedSite?.profile}
              showProfilePic={true}
              horizontal={true}
              mode={mode}
            />
          </div>
          <div className="summary-box">
            <h1
              style={{
                ...spacingStyles.munset,
                ...spacingStyles.pt20,
                ...spacingStyles.pb10,
                ...colors.black,
              }}
            >
              Your Booking
            </h1>
            <div
              className="flexRow spaceBtw"
              style={{ ...spacingStyles.p10, ...spacingStyles.plr20, }}
            >
              <span style={{ ...colors.black }} className="p">Date</span>
              <span style={{ ...colors.black }} >{moment(selectedDate).format("LL")}</span>
            </div>
            <div
              className="flexRow spaceBtw"
              style={{ ...spacingStyles.p10, ...spacingStyles.plr20, ...colors.black }}
            >
              <span className="p" style={{ ...colors.black }}>Time</span>
              <span style={{ ...colors.black }}>{time12Hour}</span>
            </div>
            <div style={{ ...spacingStyles.pt40 }}></div>
            <div
              className="flexRow spaceBtw"
              style={{ ...spacingStyles.p10, ...spacingStyles.plr20 }}
            >
              <span style={{ ...colors.black }} className="p">1 Month Membership</span>
              <span className="strike-thru">$14.99</span>
            </div>
            <div
              className="flexRow spaceBtw"
              style={{ ...spacingStyles.p10, ...spacingStyles.plr20 }}
            >
              <span style={{ ...colors.black }}>Hopper Booking</span>
              <span style={{ ...colors.black }} >$15.00</span>
            </div>
            {/* <div className="flexRow spaceBtw" style={{ ...spacingStyles.p10, ...spacingStyles.plr20 }}><span>Tax</span><span>$0.00</span></div> */}
            <div className="flexCol">
              <h2 style={{ ...spacingStyles.munset, ...spacingStyles.pt20, ...colors.black }}>
                Total Amount
              </h2>
              <h1
                className={mode === "light" ? "dark_green" : "green"}
                style={{ ...spacingStyles.munset, ...spacingStyles.pt10, }}
              >
                $15.00
              </h1>
            </div>

            {/* <p> This fee covers the cost of membership, which grants access to exclusive benefits and services. By displaying it in the cart, we aim to provide transparent information and make the process of becoming a member seamless for your convenience.</p> */}
          </div>

          {/* {isLogged &&
          <h2>EasyPay</h2>
        } */}
        </div>
        <>
          {!isRegistered && !isLogged && (
            <div style={{ ...spacingStyles.pt40 }}>
              {/* <h2>Already Registered?</h2> */}
              <div style={{ ...spacingStyles.mauto }}>
                <h1 style={{ ...colors.black }} >Reserve now.</h1>
                <ButtonGroup
                  sx={{ width: "100%" }}
                  color="primary"
                  variant="outlined"
                  size="large"
                  aria-label="outlined primary button group"
                >
                  <Button
                    sx={{
                      ...(mode === 'light' ? localStyles.toggle_right_light : localStyles.toggle_right),
                      border:
                        authToggle === "Register"
                          ? "2px solid #ff6d00"
                          : colors.primary_50,
                      color: authToggle === "Register" ? textStyle.color : colors.gray,
                    }}
                    onClick={() => handleAuthToggle("Register")}
                  >
                    Register
                  </Button>
                  <Button
                    sx={{
                      ...(mode === 'light' ? localStyles.toggle_left_light : localStyles.toggle_left),
                      border:
                        authToggle === "Login"
                          ? "2px solid #ff6d00"
                          : colors.primary_50,
                      color:
                        authToggle === "Login" ? textStyle.color : colors.gray,
                    }}
                    onClick={() => handleAuthToggle("Login")}
                  >
                    Login
                  </Button>
                </ButtonGroup>
              </div>
              <div style={{  ...spacingStyles.pt5h }}>
                {authToggle === "Login" && (
                  <LoginForm onSuccess={handleRegistrationSuccess} mode={mode} />
                )}
                {authToggle === "Register" && (
                  <RegisterForm onSuccess={handleRegistrationSuccess} mode={mode} />
                )}
              </div>
            </div>
          )}
          {isRegistered && !isOTPConfirmed && (
            <div style={{ ...spacingStyles.mauto, ...spacingStyles.pt40 }}>
              <OTPForm onSuccess={handleOTPSuccess} />
            </div>
          )}{" "}
          {isRegistered && isOTPConfirmed && bookingID !== null && (
            <div style={{ ...spacingStyles.mauto, ...spacingStyles.pt40 }}>
              <StripePayment bookingID={bookingID} isOpen={isOpen} mode={mode} />
            </div>
          )}{" "}
          {isLogged && bookingID !== null && (
            <div style={{ ...spacingStyles.mauto, ...spacingStyles.pt40 }}>
              <StripePayment bookingID={bookingID} isOpen={isOpen} mode={mode} />
            </div>
          )}
        </>
        <div style={{ ...spacingStyles.mauto, ...spacingStyles.pt40 }}>
          <h1 className="light_gray left">Canellation Policy</h1>
          <p className="policy gray">
            HopperSites can cancel or postpone bookings. HopperSites must
            respond to appointment requests within 12 hours prior to the booking
            start time; otherwise, it's auto-cancelled with full refund or
            credit. Hoppers can cancel within 12 hours for valid reasons.{" "}
          </p>
        </div>
        <div style={{ ...spacingStyles.mauto, ...spacingStyles.pt40 }}>
          <h1 className="light_gray left">Rules and Regulations</h1>
          <p className="policy gray">
            {" "}
            Certified trainers only. One client accompanies a Hopper per booking
            session. Both client and Hopper must sign a waiver. Hoppers are
            responsible for cleanup; low cleanliness ratings lead to membership
            revocation. Punctuality is mandatory.{" "}
          </p>
        </div>
        <div style={{ ...spacingStyles.mauto, ...spacingStyles.pt40 }}>
          <h1 className="light_gray left">Subscription Policy</h1>
          <p className="policy gray">
            {" "}
            Exclusive Subscription Access: Bookings available only to subscribed
            Hoppers. Enjoy hassle-free subscriptions with us—cancel anytime
            without any extra fees. We value simplicity and convenience, making
            your experience with us straightforward and stress-free.
          </p>
        </div>
      </div>
    </>
  );
}

const localStyles = {
  toggle_left: {
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    borderWidth: 2,
    width: "50%",
    color: colors.gray,
    "&:hover": {
      color: "white",
      //backgroundColor: 'darkblue',
    },
  },
  toggle_right: {
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    borderWidth: 2,
    width: "50%",
    color: colors.gray,
    "&:hover": {
      color: "white",
      //backgroundColor: 'gray',
    },
  },
  toggle_left_light: {
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    borderWidth: 2,
    width: "50%",
    color: colors.gray,
    "&:hover": {
      color: "black",
      //backgroundColor: 'darkblue',
    },
  },
  toggle_right_light: {
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    borderWidth: 2,
    width: "50%",
    color: colors.gray,
    "&:hover": {
      color: "black",
      //backgroundColor: 'gray',
    },
  },
};

import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles"; // Import useTheme for theme colors

function CustomProgressBar({ fraction }) {
  const theme = useTheme(); // Access the current theme (light/dark mode)

  return (
    <Box
      sx={{
        width: "100%",
        height: 5,
        backgroundColor: theme.palette.background.default, // Use theme background color
        borderRadius: 10,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: `${(fraction * 10000) / 100}%`,
          backgroundColor: theme.palette.success.main, // Use theme success color
        }}
        mr={1}
      />
    </Box>
  );
}

export default CustomProgressBar;

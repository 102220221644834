import React from 'react';
import { useTheme } from '@mui/material/styles';
import './../../assets/styles/sliding-diagonals.css'; // Assuming your CSS file is named 'styles.css'




const AnimatedBackground = () => {
  const theme = useTheme();

  const bgGradient = theme.palette.mode === 'dark'
    ? 'linear-gradient(-60deg, rgb(21, 21, 21) 50%, rgb(0, 0, 0) 50%)'
    : 'linear-gradient(-60deg, #fff 50%, #e0e0e0 50%)';


  return (
    <div>
      <div className="bg"
        style={{ backgroundImage: bgGradient }}></div>
      <div className="bg bg2"
        style={{ backgroundImage: bgGradient }}></div>
      <div className="bg bg3"
        style={{ backgroundImage: bgGradient }}></div>
    </div>
  );
}

export default AnimatedBackground;
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { globalStyles, spacingStyles } from "../../assets/styles/global";
import ProfileEditForm from "./ProfileEditForm";
import ProfileFieldInput from "./ProfileFieldInput";
import { getLoginData } from "../../Services/APIHandlers/ProfileApi";
import { Stack } from "@mui/material";

const ProfileContact = () => {
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.user);
  const trainer = useSelector((state) => state.business);

  const [editField, setEditField] = useState(null);

  return (
    <>
      {!!editField && (
        <ProfileEditForm
          field={editField}
          onClose={() => {
            setEditField(null);
            getLoginData(token);
          }}
        />
      )}
        <Stack
          spacing={2}
          mb={10}
          sx={{ margin: "auto" }}
        >
          <ProfileFieldInput
            label="Name"
            value={
              user.user_name ? user.user_name + " " + user.user_lastname : ""
            }
            onClick={() => setEditField("Name")}
          />

          <ProfileFieldInput
            label="Email"
            value={user.email ? user.email : ""}
            // onClick={() => {
            //   setEditField("Email");
            // }}
            disabled
          />

          <ProfileFieldInput
            label="Phone"
            value={user.phone ? user.phone : ""}
            // onClick={() => {
            //   setEditField("Phone");
            // }}
            disabled
          />

          <ProfileFieldInput
            label="Instagram"
            value={trainer.instagram ? trainer.instagram : ""}
            onClick={() => {
              setEditField("Instagram");
            }}
          />

          <ProfileFieldInput
            label="Website"
            value={trainer.website ? trainer.website : ""}
            onClick={() => {
              setEditField("Website");
            }}
          />

          <ProfileFieldInput
            label="Facebook"
            value={trainer.facebook ? trainer.facebook : ""}
            onClick={() => {
              setEditField("Facebook");
            }}
          />

          <ProfileFieldInput
            label="Youtube"
            value={trainer.youtube ? trainer.youtube : ""}
            onClick={() => {
              setEditField("Youtube");
            }}
          />

          <ProfileFieldInput
            label="TikTok"
            value={trainer.tiktok ? trainer.tiktok : ""}
            onClick={() => {
              setEditField("TikTok");
            }}
          />
        </Stack>
    </>
  );
};

export default ProfileContact;

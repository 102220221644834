import React from "react";
import OnboardingTemplate from "../../../Components/Onboarding/OnboardingTemplate";
import DemographicInfoComponent from "../../../Components/Onboarding/DemographicInfo";
import { useSelector } from "react-redux";
import { Container } from "@mui/material";

const DemographicInfo = ({
  nextAction,
  previousAction,
  screenNum,
  screenTotal,
}) => {
  const matchMeData = useSelector((state) => state.matchMe);
  const gender = matchMeData.gender;
  const birthday = matchMeData.birthday;

  return (
    <Container maxWidth="xs">
      <OnboardingTemplate
        component={<DemographicInfoComponent />}
        screenNum={screenNum}
        screenTotal={screenTotal}
        nextAction={nextAction}
        previousAction={previousAction}
        disabled={!(gender && birthday)}
      />
    </Container>
  );
};

export default DemographicInfo;

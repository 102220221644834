import { globalStyles } from "../../../assets/styles/global";

//redux
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//Calendar
import { useEffect, useState } from "react";

//models

import { updateBlackOutDates } from "../../../Services/APIHandlers/ScheduleApi";
import { setBlackout } from "../../../Services/Redux/Slices/BlackoutDates.Slice";
import { Container } from "@mui/material";
import MultiSelectCalendar from "./MultiSelectCalendar";
import moment from "moment";
import { Button } from "../../../Components/Common/Buttons/Button";

const LegendItem = ({ color, label }) => {
  return (
    <div
      style={{
        ...styles.legendItem,
        ...globalStyles.mt10,
        ...globalStyles.ml5,
        ...globalStyles.mr5,
      }}
    >
      <div style={{ ...styles.colorSquare, backgroundColor: color }} />
      <div style={globalStyles.h2}>{label}</div>
    </div>
  );
};

export default function EditBlackOutDates() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);

  const blackOuts = useSelector((state) => state.blackout);
  const [selectedDates, setSelectedDates] = useState(blackOuts);

  const handleDateSelection = (date) => {
    const dateString = moment(date).format("yyyy-MM-DD");

    const updatedDates = {
      ...selectedDates,
      [dateString]: {
        selected: !selectedDates[dateString],
        //marked: true,
        //dotColor: null
      },
    };
    setSelectedDates(updatedDates);
  };

  const handleSubmit = async () => {
    const datesArray = [];

    for (const date in selectedDates) {
      datesArray.push(date);
    }

    //console.log(datesArray);

    try {
      const response = await updateBlackOutDates(
        JSON.stringify(datesArray),
        token
      );
      ///console.log(response.data);

      if (response.data.success) {
        navigate(-1);
      } else {
        console.log(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <Container maxWidth="sm" style={{}}>
        <div
          style={{
            ...globalStyles.pt20,
            ...globalStyles.fullWidth,
            ...globalStyles.p5,
          }}
        >
          <MultiSelectCalendar
            dates={selectedDates}
            handleDateSelection={handleDateSelection}
          />
        </div>

        <div style={{ ...styles.legendContainer, ...globalStyles.mb10 }}>
          <LegendItem color={"red"} label="Blackout Date" />
        </div>

        <div
          style={{
            ...globalStyles.footer,
            ...globalStyles.fullWidth,
            ...globalStyles.pb20,
          }}
        >
          <div style={{ width: "100%" }}>
            <Button
              fill="solid"
              text="Save"
              onClick={() => {
                console.log(selectedDates);
                dispatch(setBlackout(selectedDates));
                handleSubmit();
                //navigation.goBack();
                console.log("Save Button");
              }}
              disabled={false}
              //disabled={!isChanged}
            ></Button>
          </div>
        </div>
      </Container>
    </>
  );
}

const styles = {
  legendItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  legendContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  colorSquare: {
    width: 16,
    height: 16,
    borderRadius: 4,
    marginRight: 8,
  },
  day: {
    // backgroundColor: "#000",
    // borderColor: globalStyles.gray.color,
    // borderWidth: 1,
    borderRadius: 10,
  },
  hasDay: {
    // backgroundColor: "#51FCBA4F",
    // borderColor: globalStyles.green.color,
    // borderWidth: 1,
    borderColor: "#C4C4C4",
    backgroundColor: "#FFffff40",
    //borderWidth: 1,
    borderRadius: 10,
    color: "#fff",
    padding: 5,
  },
  hasDayTime: {
    color: globalStyles.white.color,
    fontWeight: "500",
  },
  selectDay: {
    backgroundColor: "#FF6D00",
    borderColor: globalStyles.orange.color,
    borderWidth: 1,
    borderRadius: 10,
  },

  colorGray: {
    color: globalStyles.gray.color,
  },
  selectTimeText: {
    color: "#fff",
    fontSize: 20,
    fontWeight: "800",
  },
};

import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import moment, { weekdays } from "moment";
import {
  selectDate,
  selectTime,
} from "../../Services/Redux/Slices/Booking.Slice.js";

import {
  hasPast,
  hasAvailability,
  isToday,
  isBlackOut,
  isSelectDate,
} from "../../Services/Helpers/CalendarHelpers.js";
import {
  colors,
  calendarStyles,
  spacingStyles,
} from "../../assets/styles/global.js";

const OneWeekDaySelect = ({ schedule }) => {
  useEffect(() => {
    //console.log(schedule);
    setMonths();
  }, []);

  const dispatch = useDispatch();
  const [defaultMonth, setdefaultMonth] = useState(null);
  const [displayMonths, setDisplayMonths] = useState([]);
  const selectedDate = useSelector((state) => state.booking.selectedDate);

  const scheduleList = {
    selectDay: new Date(), // Add your select day date here

    firstDayInMonth: function (month, year) {
      let firstDay = new Date(year, month);
      return firstDay;
    },
  };

  const weekHeader = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const getMonthDays = (start) => {
    const month = [];
    const monthBegin = scheduleList.firstDayInMonth(
      start.getMonth(),
      start.getFullYear()
    );

    month[0] = getWeek(monthBegin);
    const initMonth = monthBegin.getMonth();

    for (let i = 1; i < 6; i++) {
      if (monthBegin.getMonth() === initMonth) {
        month[i] = getWeek(monthBegin);
      }
    }
    //console.log(month);
    return month;
  };

  const getDays = (start) => {
    const weeks = [];
    displayMonths.forEach((month) => {
      getMonthDays(month).forEach((week) => {
        weeks.push(week);
      });
    });

    // // Remove only empty days and join unfilled weeks
    // const mergedWeeks = [];
    // let prevMonthWeek = false;

    // weeks.forEach(week => {
    //   if (!prevMonthWeek) {
    //     let weekDays = [];
    //     week.forEach(day => {
    //       if (day === "") {
    //         prevMonthWeek = true;
    //         return;
    //       } else {
    //         weekDays.push(day);
    //       }
    //     });
    //     mergedWeeks.push(weekDays);
    //   } else {
    //     week.forEach(day => {
    //       if (day !== "") {
    //         mergedWeeks[mergedWeeks.length - 1].push(day);
    //         prevMonthWeek = false;
    //       }
    //     });
    //   }
    // });

    return weeks; //mergedWeeks;
  };

  const getWeek = (monthDay) => {
    const start = new Date(monthDay);
    const week = [];

    for (let day = 0; day < 7; day++) {
      if (monthDay.getDay() === day) {
        if (monthDay.getMonth() === start.getMonth()) {
          week[day] = new Date(monthDay);
          monthDay.setDate(monthDay.getDate() + 1);
        } else {
          week[day] = "";
        }
      } else {
        week[day] = "";
      }
    }

    return week;
  };

  const setMonths = () => {
    const now = new Date(Date.now());
    const monthSet = new Date(
      scheduleList.firstDayInMonth(now.getMonth(), now.getFullYear())
    );

    const month1 = new Date(monthSet);

    monthSet.setMonth(monthSet.getMonth() + 1);
    const month2 = new Date(
      scheduleList.firstDayInMonth(monthSet.getMonth(), monthSet.getFullYear())
    );

    monthSet.setMonth(monthSet.getMonth() + 1);
    const month3 = new Date(
      scheduleList.firstDayInMonth(monthSet.getMonth(), monthSet.getFullYear())
    );

    let displayMonths = [month1, month2, month3];

    //console.log(displayMonths);

    setDisplayMonths(displayMonths);
    setdefaultMonth(displayMonths[0]);
    //console.log(displayMonths[0]);
  };

  const now = new Date(Date.now());
  now.setHours(0, 0, 0, 0);
  //console.log("now ", now  );
  const future = new Date();
  future.setDate(now.getDate() + 7);
  future.setHours(0, 0, 0, 0);
  //console.log("future", future );

  const isWeekActive = (weekArray) => {
    //console.log(weekArray);
    for (let i = 0; i < weekArray.length; i++) {
      let tempDate = new Date(weekArray[i]);
      //console.log(tempDate.getTime(), future.getTime());
      if (now <= tempDate && tempDate <= future) {
        return true;
      }
    }
    // No matching days found within the date range
    return false;
  };

  const handleDayClick = (day) => {
    const serializableDate = day.toISOString();
    dispatch(selectDate(serializableDate));
    dispatch(selectTime(null));
  };

  const renderWeek = (week, monthIndex, weekIndex) => {
    return (
      isWeekActive(week) && (
        <div
          key={`${monthIndex}-${weekIndex}`}
          className="flexRow"
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          {week.map((day, dayIndex) => {
            // Check if the day should be rendered
            if (day !== "") {
              return (
                <React.Fragment key={dayIndex}>
                  {day !== "" ? (
                    <div
                      style={{
                        width: "35px",
                        height: "35px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <button
                        style={{
                          ...calendarStyles.emptyDay,
                          ...(hasPast(day)
                            ? calendarStyles.pastDay
                            : calendarStyles.comingDay),
                          ...(isToday(day) && calendarStyles.today),
                          //...(hasAvailability(day, schedule) ? "" : calendarStyles.pastDay),
                          ...(isBlackOut(day) && calendarStyles.blackoutDay),
                          ...(isSelectDate(day, selectedDate) &&
                            calendarStyles.selectDay),
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleDayClick(day, dispatch);
                        }}
                      >
                        <span>{moment(day).format("DD")}</span>
                      </button>
                    </div>
                  ) : (
                    <div style={{ ...calendarStyles.emptyDay }} />
                  )}
                </React.Fragment>
              );
            } else {
              // Add new month logic here
              return (
                <div key={dayIndex} style={{ ...calendarStyles.emptyDay }} />
              );
            }
          })}
        </div>
      )
    );
  };

  return (
    <div>
      <div style={{ margin: 0, padding: 0 }}>
        <div className="flexRow" style={{ ...calendarStyles.weekContainer }}>
          {weekHeader.map((dayHeader, index) => (
            <div
              key={index}
              style={{
                width: "35px",
                height: "35px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  ...calendarStyles.short_day,
                  color: colors.gray.color,
                }}
              >
                {dayHeader}
              </span>
            </div>
          ))}
        </div>

        <div className="flexCol">
          {defaultMonth &&
            getDays(defaultMonth)
              .slice(0, 6)
              .map((week, weekIndex) => renderWeek(week, 0, weekIndex))}
        </div>
      </div>
    </div>
  );
};

export default OneWeekDaySelect;

import React, { useState, useEffect, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Box, Typography, Button, Link } from "@mui/material"; // MUI components
import { useTheme } from '@mui/material/styles'; // Access MUI theme for dark/light mode
import AuthComponent from "../../Components/Common/AuthComponent";
import AuthContext from "../../Navigation/AuthWrapper";

import Setup from "../../Screens/Wizard/SiteOnboarding/Setup";
import SpaceScreen from "../../Screens/Wizard/SiteOnboarding/Space";
import Amenities from "../../Screens/Wizard/SiteOnboarding/Amenities";

import LocationSetup from "./SiteOnboarding/LocationSetup";
import GallerySetup from "./SiteOnboarding/GallerySetup";
import ScheduleSetup from "./SiteOnboarding/ScheduleSetup";
import SetupComplete from "../../Screens/Wizard/SiteOnboarding/SetupComplete";
import BusinessDescription from "../../Components/Onboarding/BusinessDescription";
import SpecialInstructions from "../../Components/Onboarding/SpecialInstructions";

import AnimatedBackground from "../../Components/Backgrounds/SlidingDiagonals";

const screens = {
  Setup: { component: Setup, next: "Description", prev: null, screenNum: 0, props: { role: "site" } },
  Description: { component: BusinessDescription, next: "Gallery", screenNum: 1, prev: "Setup" },
  Gallery: { component: GallerySetup, next: "Space", screenNum: 2, prev: "Description" },
  Space: { component: SpaceScreen, next: "Amenities", screenNum: 3, prev: "Gallery" },
  Amenities: { component: Amenities, next: "Location", screenNum: 4, prev: "Space" },
  Location: { component: LocationSetup, next: "Schedule", screenNum: 5, prev: "Amenities" },
  Schedule: { component: ScheduleSetup, next: "Special", screenNum: 6, prev: "Location" },
  Special: { component: SpecialInstructions, next: "SetupComplete", screenNum: 7, prev: "Schedule" },
  SetupComplete: { component: SetupComplete, next: "Done", screenNum: 8, prev: null },
  Skip: { component: "Skip", prev: null },
  Done: { component: "Done", prev: null },
};

const SiteOnboarding = () => {
  const [activeScreen, setActiveScreen] = useState("Setup");
  const [isAuthComplete, setIsAuthComplete] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const auth = useContext(AuthContext);
  const location = useLocation();
  const [authTog, setAuthTog] = useState("Login");

  const theme = useTheme(); // Access the current theme
  const mode = theme.palette.mode; // Determine if the mode is 'dark' or 'light'
  const screenTotal = 8;

  const handleRegisterClick = () => {
    setAuthTog("Register");
  };

  useEffect(() => {
    if (auth.token) {
      setIsAuthComplete(true);
    }
  }, [auth.token]);

  useEffect(() => {
    const hash = location.hash.replace("#", "") || "intro";
    const screenKey = Object.keys(screens).find((key) => key.toLowerCase() === hash);
    if (screenKey) {
      setActiveScreen(screenKey);
    } else {
      setActiveScreen("Setup");
    }
  }, [location.hash]);

  useEffect(() => {
    if (auth.token && isOTPVerified) {
      setIsAuthComplete(true);
    }
  }, [auth.token, isOTPVerified]);

  const handleAuthSuccess = () => {
    setIsOTPVerified(true);
  };

  const renderScreen = () => {
    const screenInfo = screens[activeScreen];
    const ScreenComponent = screenInfo.component;

    if (ScreenComponent === "Skip" || ScreenComponent === "Done") {
      return <Navigate to="/GymDash" />;
    }

    return ScreenComponent ? (
      <ScreenComponent
        {...(screenInfo.props || {})}
        goToStep={(screen) => setActiveScreen(screen)}
        nextAction={() => setActiveScreen(screenInfo.next)}
        previousAction={() => {
          if (screenInfo.prev) {
            setActiveScreen(screenInfo.prev);
          }
        }}
        screenNum={screenInfo.screenNum}
        screenTotal={screenTotal}
      />
    ) : null;
  };

  return (
    <>
      <AnimatedBackground />
      <Box className="site-onboard" >
        {isAuthComplete ? (
          renderScreen()
        ) : (
          <Box sx={{ paddingTop: "50px" }}>
            <Typography
              variant="h4"
              sx={{ marginBottom: "1rem", color: mode === 'light' ? 'black' : 'white' }}
            >
              Let's Get Started
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginBottom: "2rem", color: mode === 'light' ? 'black' : 'white' }}
            >
              Login or Register a new account to begin listing your fitness space.
            </Typography>

            <AuthComponent onAuthSuccess={handleAuthSuccess} authTog={authTog} mode={mode} />

            {authTog === "Register" ? (
              <Box sx={{ paddingBottom: "40px" }}>
                <Typography
                  variant="body2"
                  sx={{ paddingTop: "10px", color: mode === 'light' ? 'black' : 'white' }}
                >
                  Already have an account?{" "}
                  <Link
                    component="button"
                    sx={{ color: theme.palette.primary.main, paddingLeft: "5px" }}
                    onClick={() => setAuthTog("Login")}
                  >
                    Login Here
                  </Link>
                </Typography>
              </Box>
            ) : (
              <Box sx={{ paddingBottom: "40px" }}>
                <Typography
                  variant="body2"
                  sx={{ paddingTop: "10px", color: mode === 'light' ? 'black' : 'white' }}
                >
                  Not registered yet?{" "}
                  <Link
                    component="button"
                    sx={{ color: theme.palette.primary.main, paddingLeft: "5px" }}
                    onClick={handleRegisterClick}
                  >
                    Register Here
                  </Link>
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default SiteOnboarding;

import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSlider from "../CustomSlider";
import { updateAnswer } from "../../Services/Redux/Slices/MatchMe.Slice"; // Import the appropriate actions

import { colors, globalStyles } from "../../assets/styles/global";

const MatchMeTopicComponent = ({ topic }) => {
  const dispatch = useDispatch();

  // Retrieve all topic data from Redux store
  const matchMeData = useSelector((state) => state.matchMe);

  const [selectedAnswerValues, setSelectedAnswerValues] = useState(
    !(matchMeData && topic)
      ? Array(5).fill(0)
      : matchMeData[topic].answers.map(({ select }) => Number(select))
  );

  useEffect(() => {
    if (!(matchMeData && topic)) {
      return;
    }

    setSelectedAnswerValues(
      matchMeData[topic].answers.map(({ select }) => Number(select))
    );
  }, [matchMeData, topic]);

  // Function to handle changes in the selection
  const handleSelectionChange = (index, value) => {
    setSelectedAnswerValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = value;
      return updatedValues;
    });
    dispatch(
      updateAnswer({
        topic,
        index,
        answer: { val: topicData.answers[index].val, select: value },
      })
    );
  };

  const topicData = useMemo(() => {
    return matchMeData?.[topic];
  }, [matchMeData, topic]);

  return (
    <div
      style={{
        ...globalStyles.w100,
        ...globalStyles.alignCenter,
        ...globalStyles.pt10,
      }}
    >
      {topicData && topicData.hopper_question ? (
        <div style={{ ...globalStyles.h1, ...globalStyles.pb20 }}>
          {topicData.partner_question}
        </div>
      ) : (
        <div style={{ ...globalStyles.h1 }}>Loading</div>
      )}

      {topicData?.answers.map((answer, index) => (
        <div
          style={{
            ...globalStyles.w100,
            ...globalStyles.alignCenter,
            ...globalStyles.mb10,
          }}
          key={answer.val}
        >
          <div style={{ ...globalStyles.h2, ...colors.light_gray }}>
            {answer.val}
          </div>
          <CustomSlider
            value={selectedAnswerValues[index]}
            onValueChange={(value) => {
              handleSelectionChange(index, value);
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default MatchMeTopicComponent;

import React, { useEffect } from "react";
import { MdLocationOn, MdHome } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { updateSelectSite } from "../../../Services/Redux/Slices/Booking.Slice";
import { colors, globalStyles } from "../../../assets/styles/global";
import { useDispatch } from "react-redux";
import MapSimilarTrainers from "../../Map/MapSimilarTrainers";
import PageHeader from "../../../Components/Common/PageHeader";

const hitSlop = { top: 5, right: 5, bottom: 5, left: 5 };

const SimilarFitnessTrainers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("userTypeSet", true);
  }, []);

  return (
    <div>
      <PageHeader />

      <MapSimilarTrainers />
    </div>
  );
};

export default SimilarFitnessTrainers;

const localStyles = {
  homeIconBox: {
    position: "absolute",
    right: 20,
    bottom: 20,
    padding: 5,
    borderRadius: 40,
    zIndex: 10,
  },
};

import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import moment from "moment";

import timeConverter from "../../Services/Helpers/TimeConverter";
import formatTime from "../../Services/Helpers/FormatTime";
import getDayIDX from "../../Services/Helpers/GetDayIDX";

import {
  globalStyles,
  colors,
  calendarStyles,
} from "../../assets/styles/global";

import { TimeRanges } from "../../Services/Constants/timeRanges";

const VertTimeSelect = ({ scheduleData, handleSelectTime }) => {
  const [selectTime, setSelectTime] = useState("");
  const selectedDate = useSelector((state) => state.booking.selectedDate);

  //var daySelect: string | null = selectedDate;
  const [daySelect, setDaySelect] = useState(
    moment(selectedDate).format("ddd") ||
      moment(new Date().toISOString().split("T")[0]).format("ddd")
  );

  useEffect(() => {
    setDaySelect(moment(selectedDate).format("ddd"));
    //console.log("daySelect: ", daySelect);
  }, [selectedDate]);

  function isSelectTime(time) {
    let retn = false;

    if (time == selectTime) {
      retn = true;
    }
    return retn;
  }

  function hasRangeTime(range) {
    let retn = false;

    if (daySelect !== null && scheduleData[getDayIDX(daySelect)] != undefined) {
      scheduleData[getDayIDX(daySelect)][daySelect]?.map((timeOption) => {
        if (
          timeConverter(timeOption.time) >= timeConverter(range.start) &&
          timeConverter(timeOption.time) <= timeConverter(range.end)
        ) {
          if (timeOption.select == true) {
            retn = true;
          }
        }
      });
    }
    return retn;
  }

  function isInRange(time, rangeSelect) {
    if (
      timeConverter(time) >= timeConverter(rangeSelect.start) &&
      timeConverter(time) <= timeConverter(rangeSelect.end)
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <div
        style={{
          ...globalStyles.flexRow,
          ...globalStyles.flexWrap,
          ...globalStyles.fullWidth,
          borderRadius: 15,
        }}
      >
        {TimeRanges.map((range, rangeIDX) => {
          return (
            <div
              key={range.name}
              style={{
                ...globalStyles.mt30,

                flex: 1,
                flexBasis: "13%",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div key={rangeIDX} onClick={() => {}}>
                {/* <img
                  src={range.src}
                  style={{
                    ...styles.timeIcon,
                    tintColor: colors.darker_gray.color,
                    ...(hasRangeTime(range)
                      ? calendarStyles.selectRange
                      : null),
                    objectFit: "contain",
                  }}
                  alt="range"
                /> */}
                <div
                  style={{
                    ...globalStyles.p,
                    ...calendarStyles.rangeLabel
                    //hasRangeTime(range) ? calendarStyles.selectRange : null,
                    // hasRangeTime(range) ? calendarStyles.selectRange : null as any,
                  }}
                >
                  {range.name}
                </div>
              </div>

              <div
                style={{
                  // borderTopWidth: 2,
                  // borderTopColor: "#373737", //globalStyles.gray.color,
                  marginTop: 10,
                  marginBottom: 10,
                  // borderRadius: 15,
                }}
              ></div>

              {daySelect !== null &&
                scheduleData[getDayIDX(daySelect)] !== undefined &&
                scheduleData[getDayIDX(daySelect)][daySelect]?.map(
                  (time, timeIDX) => {
                    //console.log(time);
                    //console.log(getDayIDX(daySelect));
                    //console.log(scheduleData[getDayIDX(daySelect)]);
                    if (isInRange(time.time, range)) {
                      return (
                        <div
                          key={timeIDX}
                          style={{
                            ...globalStyles.p3,

                            marginTop: 10,
                            // borderWidth: 2,
                            // borderColor: globalStyles.gray.color,
                            // borderRadius: 10,

                            ...(time.select == true
                              ? calendarStyles.availableTime
                              : null),
                            ...(isSelectTime(time) == true
                              ? calendarStyles.selectTime
                              : null),
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (time.select) {
                              setSelectTime(time);
                              handleSelectTime(time);
                            }
                          }}
                        >
                          <div
                            style={{
                              ...calendarStyles.time,

                              fontSize: 14,
                              fontWeight: "600",

                              ...(time.select === true
                                ? calendarStyles.availableTimeText
                                : {}),
                              ...(isSelectTime(time) === true
                                ? calendarStyles.selectTimeText
                                : null),
                            }}
                          >
                            {formatTime(time.time)}
                          </div>
                        </div>
                      );
                    }
                  }
                )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default VertTimeSelect;

const styles = {
  timeIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
};

import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { IoCaretBack, IoCaretForward } from "react-icons/io5";

import { colors, globalStyles } from "../../assets/styles/global";
import gradialGreen from "../../assets/radial_green.png";

const dayNames = ["Su", "M", "Tu", "W", "Th", "F", "Sa"];

const hitSlop = { top: 5, right: 5, bottom: 5, left: 5 };

const BookingsWeekCalendar = () => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [weekDays, setWeekDays] = useState([]);

  const generateWeekDays = (startOfWeek) => {
    const endOfWeek = startOfWeek.clone().endOf("week");
    let days = [];
    let day = startOfWeek;

    // Generate array of weekdays
    while (day <= endOfWeek) {
      days.push(day.clone());
      day = day.clone().add(1, "d");
    }

    setWeekDays(days);
  };

  useEffect(() => {
    generateWeekDays(moment().startOf("week"));
  }, []);

  const nextWeek = () => {
    const nextWeekStart = weekDays[0].clone().add(1, "week").startOf("week");
    generateWeekDays(nextWeekStart);
  };

  const prevWeek = () => {
    const prevWeekStart = weekDays[0]
      .clone()
      .subtract(1, "week")
      .startOf("week");
    generateWeekDays(prevWeekStart);
  };

  const currentMonthName = useMemo(() => {
    if (!weekDays?.length) return "";

    const currentDateInWeek =
      currentDate.isSameOrAfter(weekDays[0], "day") &&
      currentDate.isSameOrBefore(weekDays[weekDays.length - 1], "day");

    return (
      (currentDateInWeek ? currentDate : weekDays?.[0])
        ?.format("MMM YYYY")
        .toUpperCase() || ""
    );
  }, [weekDays, currentDate]);

  return (
    <div
      style={{
        backgroundColor: colors.card_gray.color,
        borderRadius: 10,
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 15,
        paddingRight: 15,
        margin: "auto",
        marginBottom: 15,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div onClick={prevWeek} className="textButton" hitSlop={hitSlop}>
          <IoCaretBack color={colors.primary.color} size={30} />
        </div>
        <div
          style={{
            ...globalStyles.h2,
            color: colors.primary.color,
            fontWeight: "700",
            letterSpacing: 1,
          }}
        >
          {currentMonthName}
        </div>
        <div onClick={nextWeek} className="textButton" hitSlop={hitSlop}>
          <IoCaretForward color={colors.primary.color} size={30} />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          margin: 15,
        }}
      >
        {dayNames.map((day) => (
          <div
            key={day}
            style={{
              ...globalStyles.text,
              color: colors.white.color,
              fontWeight: "600",
              fontSize: 16,
            }}
          >
            {day}
          </div>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          margin: 15,
        }}
      >
        {weekDays.map((day) => {
          const isSelected = moment(day).isSame(currentDate, "day");

          return (
            <div
              key={day}
              onClick={() => setCurrentDate(day)}
              className="textButton"
            >
              <div
                style={{
                  display: "flex",
                  backgroundColor: isSelected
                    ? colors.green50.color
                    : colors.gray.color,
                  width: 35,
                  height: 35,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 50,
                  position: "relative",
                }}
              >
                <img
                  src={gradialGreen}
                  style={{
                    position: "absolute",
                    left: -5,
                    top: -5,
                    width: 45,
                    height: 45,
                    opacity: isSelected ? 1 : 0,
                    zIndex: -1,
                  }}
                  alt="Gradient"
                />
                <div
                  style={{
                    ...globalStyles.text,
                    color: colors.white.color,
                    fontWeight: "600",
                    fontSize: 16,
                  }}
                >
                  {day.format("D")}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BookingsWeekCalendar;

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import OnboardingTemplate from "../../../Components/Onboarding/OnboardingTemplate";
import AmentitiesComponent from "../../../Components/Onboarding/Amenities";
import EquipmentComponent from "../../../Components/Onboarding/Equpment";
import ExtrasComponent from "../../../Components/Onboarding/Extras";

import { selectOnboardingState } from "../../../Services/Redux/Slices/Onboarding.Slice";
import { updateSpaceDetailsAsync } from "../../../Services/Redux/Thunks/updateSpaceDetails";


export default function Amenities({ nextAction, previousAction, screenNum = 2, screenTotal = 3 }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const onboardingState = useSelector(selectOnboardingState); // Select the onboarding state

  const saveResponses = async () => {
    try {
      const response = await dispatch(updateSpaceDetailsAsync({ data: onboardingState, token }));
      if (response?.payload) { // Check if response and payload exist
        return response.payload;
      } else {
        throw new Error("No data returned from saveResponses");
      }
    } catch (error) {
      console.error("Failed to save responses:", error);
      return null; // Return null or handle the error as needed
    }
  };

  return (
    <OnboardingTemplate
      component={
        <div className="input-sheet">
          <ExtrasComponent />
          <AmentitiesComponent />
          <EquipmentComponent />
        </div>
      }
      screenNum={screenNum}
      screenTotal={screenTotal}
      nextAction={nextAction}
      previousAction={previousAction}
      title={"Amenities"}
      saveResponses={saveResponses}
    />
  );
}

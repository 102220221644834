import React, { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { colors, globalStyles, spacingStyles } from "../../assets/styles/global";
import WalletDetails from "../../Components/Wallet/WalletDetails";
import Transfers from "../../Components/Wallet/Transfers";
import { Button } from "../../Components/Common/Buttons/Button";

import { getWalletData, setupTransfer, cashOut, } from "../../Services/APIHandlers/WalletApi";
import AuthContext from "../../Navigation/AuthWrapper";
import { addUser } from "../../Services/Redux/Slices/User.Slice";

const Wallet = () => {
  const [showTransfers, setShowTransfer] = useState(false);

  const details = useSelector((state) => state.wallet);

  //const allData = useSelector((state) => state);
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();

  const [userData, setUserData] = useState(null); // Initialize state variable

  const getUserData = async () => {
    try {
      const storedUserData = await localStorage.getItem("user");
      //console.log(storedUserData); // for debugging, consider removing in production
      setUserData(JSON.parse(storedUserData)); // Update state variable
    } catch (error) {
      console.error("Error retrieving user data:", error);
    }
  };

  useEffect(() => {
    getUserData();

    const fetchData = async () => {
      try {
        //console.log(auth.token);
        const response = await getWalletData(auth.token);
        //console.log(response.data);
        // if (response.data.success) {
        //   const walletData = response.data; // Access the 'data' property directly
        //   console.log(walletData);
        // } else {
        //   console.log(response.data);
        // }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  const handleSetupTransferClick = () => {

    const fetchData = async () => {
      try {
        //console.log(auth.token);
        const response = await setupTransfer(auth.token);
        // console.log(response.data.user);
        if (response.data.user) {
          dispatch(addUser(response.data.user));
        }
        if (response.data.connect_url) {
          window.location.href = response.data.connect_url;
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();

    // const userEmail = userData.email;
    // window.location.href = `https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_EsB1U1Q2B8kkl3edMlSrXMcQtsyAHIHJ&stripe_user[email]=${encodeURIComponent(userEmail)}`;
  };

  const handleCashOut = () => {
    //live ca_EsB1y1QLhQjU79Gglz6kckImItzpzTnZ
    //Test ca_EsB1U1Q2B8kkl3edMlSrXMcQtsyAHIHJ

    const fetchData = async () => {
      try {
        //console.log(auth.token);
        const response = await cashOut(auth.token);

        console.log(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();

    // const userEmail = userData.email;
    // window.location.href = `https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_EsB1U1Q2B8kkl3edMlSrXMcQtsyAHIHJ&stripe_user[email]=${encodeURIComponent(userEmail)}`;
  };


  return (
    <>
      {/* PageHeader */}
      <div style={{ ...spacingStyles.desktopMaxWidthTwoCol}}>
        <h1> Wallet </h1>

        <div className="two-col" style={{ ...spacingStyles.mt20 }} >
          <div>{/* Balance */}
            <div style={{ ...spacingStyles.mb20 }} >
              <span
                style={{ ...globalStyles.h2, ...colors.gray }}
              >
                Current Balance
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <span style={{ ...colors.white, fontSize: 40, ...globalStyles.mt10 }}>
                  $
                </span>
                <span
                  style={{
                    ...globalStyles.gray,
                    fontSize: 70,
                    ...globalStyles.mt40,
                    ...globalStyles.green,
                  }}
                >
                  {details?.current_stats?.cash_available}
                </span>
              </div>
              <span
                style={{
                  ...globalStyles.gray,
                  fontSize: 30,
                  alignSelf: "flex-end",
                }}
              >
                USD
              </span>
            </div>
            <>{/* Buttons */}
              <Button
                disabled={
                  userData?.stripe_payoutId === null ||
                  details?.current_stats?.cash_available === 0 ||
                  details?.current_stats?.cash_available === "0"
                }
                fill={userData?.stripe_payoutId ? "solid" : "outline"}
                text="Transfer To Bank"
                onClick={handleCashOut}

              ></Button>
              <div style={{ ...spacingStyles.mt10 }}>
                {userData?.stripe_payoutId === null && (
                  <Button
                    fill="solid"
                    text="Setup Transfer"
                    onClick={handleSetupTransferClick}
                  ></Button>
                )}
                {userData?.stripe_payoutId !== null && (
                  <Button
                    fill="outline"
                    text="Update Banking"
                    onClick={handleSetupTransferClick}
                  ></Button>
                )}
              </div>
            </>

          </div>
          <div>
            {/* Balance Tansfer Toggle */}
            <div
              style={{
                ...globalStyles.flexRow,
                ...globalStyles.flexCenter,
                ...globalStyles.mt20,
                ...globalStyles.mb20,
              }}
            >
              <div
                onClick={() => setShowTransfer(false)}
                style={{
                  cursor: "pointer",
                  minWidth: 120,
                  ...(!showTransfers ? globalStyles.bbo : globalStyles.bb0),
                  display: "inline-block",
                }}
              >
                <span
                  style={{
                    ...globalStyles.h3,
                    ...(!showTransfers ? globalStyles.orange : {}),
                    ...globalStyles.fw600,
                  }}
                >
                  Balance
                </span>
              </div>
              <div
                onClick={() => setShowTransfer(true)}
                style={{
                  cursor: "pointer",
                  minWidth: 120,
                  ...(showTransfers ? globalStyles.bbo : globalStyles.bb0),
                  display: "inline-block",
                }}
              >
                <span
                  style={{
                    ...globalStyles.h3,
                    ...(showTransfers ? globalStyles.allCaps : {}),
                    ...(showTransfers ? globalStyles.orange : {}),
                    ...globalStyles.fw600,
                  }}
                >
                  Transfers
                </span>
              </div>
            </div>
            <>{/* Details */}
              {showTransfers ? <Transfers /> : <WalletDetails details={details} />}
            </>
          </div>
        </div>

      </div>
    </>
  );
};

export default Wallet;

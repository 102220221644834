import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegEnvelope } from "react-icons/fa6";
import { Menu, MenuItem, IconButton } from "@mui/material";

import { colors, globalStyles } from "../../assets/styles/global";
import calendarReschedule from "../../assets/calendar-reschedule.png";

const CanceledCard = ({
  item: { id, time, gym, location, image, trainer, checkedIn },
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuVisible = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: colors.card_gray.color,
        borderRadius: 10,
        padding: "12px 15px", // Converted to a valid CSS shorthand
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <div style={{ marginTop: 15, width: "35%" }}>
          <img
            src={image}
            style={{ width: 45, height: 45, borderRadius: "50%" }}
            alt="Trainer"
          />
          <div
            style={{ ...globalStyles.text, fontWeight: "500", marginTop: 6 }}
          >
            {trainer}
          </div>
        </div>

        <div style={{ flex: 1, marginLeft: 15 }}>
          <div>
            <div
              style={{ ...globalStyles.text, fontSize: 15, fontWeight: "600" }}
            >
              {time}
            </div>
            <div style={{ ...globalStyles.h3, marginTop: 10 }}>{gym}</div>
            <div style={{ ...globalStyles.text }}>{location}</div>
          </div>

          <div style={{ marginTop: 10 }}>
            <div
              style={{
                padding: "6px 17px",
                borderRadius: 8,
                backgroundColor: "#9B0D0D",
              }}
            >
              <div
                style={{
                  ...globalStyles.text,
                  fontSize: 13,
                  fontWeight: "500",
                  color: "#fff", // Ensure the text is visible
                }}
              >
                Canceled: 1 Week Ago
              </div>
            </div>
          </div>
        </div>

        <div>
          <IconButton onClick={handleMenuOpen}>
            <BsThreeDotsVertical size={25} color={colors.white.color} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={menuVisible}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                borderRadius: 8,
                marginTop: 10,
              },
            }}
          >
            <MenuItem onClick={handleMenuClose}>
              <img
                src={calendarReschedule}
                style={{ width: 18, height: 18, marginRight: 10 }}
                alt="Calendar Reschedule"
              />
              Reschedule
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <FaRegEnvelope
                size={18}
                color={colors.black.color}
                style={{ marginRight: 10 }}
              />
              Message
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default CanceledCard;

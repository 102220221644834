import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selected: false,
};

const BlackoutDatesSlice = createSlice({
  name: "blackoutDates",
  initialState: initialState,
  reducers: {
    setBlackout(state, action) {
      return action.payload;
    },
  },
});

export const { setBlackout } = BlackoutDatesSlice.actions;

export default BlackoutDatesSlice.reducer;

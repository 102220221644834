import React from "react";
import { css } from "glamor";
import { ReactSVG } from "react-svg";
import { CustIcons } from "../../Services/Constants/customIcons";

const RenderIcons = ({ iconName, width, height, color }) => {
  const iconData = CustIcons[iconName];

  // console.log(iconName);
  // console.log(color);

  if (!iconData) {
    return null;
  }

  return (
    <div
      style={{
        fill: color,
        padding: 5,
        minWidth: '25px',
      }}
    >
      <ReactSVG src={iconData.src.default} style={{ width, height }} />
    </div>
  );
};

export default RenderIcons;

export const styles = css({
  clr1: {
    fill: "white",
  },
  clr2: {
    fill: "white",
  },
});

import { nav } from "./navigation";
import AuthContext from "./AuthWrapper";
import PrivateRoute from "./PrivateRoute";
import GuestRoute from "./GuestRoute";
import { useContext, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { useTheme } from "@mui/material"; // Import useTheme for theme access
import DarkModeSwitch from "../Components/Common/DarkModeSwitch";
import { spacingStyles } from "../assets/styles/global";
import FooterMenu from "./FooterMenu";

export const RenderMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigation = useNavigate();
  const auth = useContext(AuthContext);
  const isAuthenticated = auth.token !== "";
  const { darkMode, toggleDarkMode } = useContext(AuthContext);
  const theme = useTheme(); // Define theme here at the top level of RenderMenu

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const MenuItem = ({ route }) => {
    const location = useLocation();
    const isActive = route.path === location.pathname;
    const isRentYourSpace = route.name === "Rent your space";

    return (
      <div
        className={`menuItem ${isActive ? "active" : ""} ${isRentYourSpace ? "rentYourSpace" : ""}`}
        onClick={() => {
          navigation(route.path);
          if (window.innerWidth <= 768) {
            toggleMenu(); // Close menu on item click for mobile
          }
        }}
      >
        <span>{route.name}</span>
      </div>
    );
  };

  return (
    <>
      {/* Hamburger Icon for Mobile */}
      {/* <div className="hamburger-menu" onClick={toggleMenu}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </div> */}

      {/* Side Menu for Mobile */}
      <div className={`side-menu ${isMenuOpen ? "open" : ""}`}>
        <div className="menu-header">
          <div className="logo" onClick={() => {  
            toggleMenu(); 
            navigation("/"); }}>
            <img
              className="noLogLogo"
              src={require("./../assets/logo_light.png")}
              alt="Logo"
            />
          </div>
          <div className="close-menu-btn" onClick={toggleMenu}>
            <FaTimes />
          </div>
        </div>

        <div className="menu-items">
          {nav.map((route, i) => {
            if (!route.isPrivate && route.isMenu) {
              return <MenuItem key={i} route={route} />;
            } else if (isAuthenticated && route.isMenu) {
              return <MenuItem key={i} route={route} />;
            } else return false;
          })}

          {!isAuthenticated && (
            <>
              <MenuItem route={nav[1]} />
              <MenuItem route={nav[2]} />
            </>
          )}
        </div>
      </div>

      {/* Desktop Menu */}
      <div className="desk-menu">
        <div className="desk-menu-contain">
          <div className="menu-header">
            <div className="logo" style={spacingStyles.mr20} onClick={() => navigation("/")}>
              <img
                className="noLogLogo"
                src={darkMode ? require("./../assets/logo_light.png") : require("./../assets/logo_dark.png")}
                alt="Logo"
              />
            </div>
            <DarkModeSwitch darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          </div>
          <div className="desk-menu-links">
            {nav.map((route, i) => {
              if (!route.isPrivate && route.isMenu) {
                return <MenuItem key={i} route={route} />;
              } else if (isAuthenticated && route.isMenu) {
                return <MenuItem key={i} route={route} />;
              } else return false;
            })}

            {!isAuthenticated && (
              <div className="flexRow">
                <MenuItem route={nav[1]} />
                <MenuItem route={nav[2]} />
              </div>
            )}
          </div>
        </div>
      </div>

      <FooterMenu menuClick={toggleMenu}></FooterMenu>
    </>
  );
};

export const RenderRoutes = () => {
  return (
    <div className="main-content-container">
      <Routes>
        {nav.map((route, i) => {
          if (route.isGuestAllowed) {
            return <Route key={i} path={route.path} element={route.element} />;
          } else if (route.isPrivate) {
            return (
              <Route
                key={i}
                path={route.path}
                element={<PrivateRoute>{route.element}</PrivateRoute>}
              />
            );
          } else if (!route.isPrivate) {
            return (
              <Route
                key={i}
                path={route.path}
                element={<GuestRoute>{route.element}</GuestRoute>}
              />
            );
          } else return false;
        })}
      </Routes>
    </div>
  );
};

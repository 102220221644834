import React, { useEffect, useState, useContext } from 'react';
//import { Stripe } from 'stripe';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import StripeForm from './StripeForm';
import AuthContext from '../../Navigation/AuthWrapper';

import {
  getClientSecret
} from "../../Services/APIHandlers/CheckoutApi";
import { formStyles, spacingStyles } from '../../assets/styles/global';
import { withTheme } from '@emotion/react';

const CreditProcess = ({ bookingID, isOpen, mode }) => {
  const [options, setOptions] = useState(null);
  const [secretKey, setSecretKey] = useState(null);

  const stripePromise = loadStripe('pk_test_PWDOwJFABf9NQ8Okfb4epkA6');

  const auth = useContext(AuthContext);


  useEffect(() => {

    //console.log(auth);

    const fetchData = async () => {
      try {
        const response = await getClientSecret(bookingID, auth.token); // Wait for the promise to resolve
        if (response) {
          console.log(response);

          stripePromise.then((stripeInstance) => {
            setSecretKey(response.data.client_secret);
            setupStripe(response.data.client_secret); // Pass the actual client secret
          });

        } else {
          console.log("Error");
        }
      } catch (err) {
        console.error(err);
      }
    };

    if(isOpen){
      fetchData();
    }

  }, [isOpen]);

  const paymentElementStyle = {
    base: {
      ...formStyles.input,
    },
    invalid: {
      ...formStyles.input,
      ...formStyles.errorInput,
    },
  };


  const setupStripe = (client_scret) => {
    const optionsSetup = {
      layout: {
        type: 'tabs',
        defaultCollapsed: false,
      },
      clientSecret: client_scret,

      //Fully customizable with appearance API.
      appearance: {
        // theme: 'flat',
        variables: {
          fontFamily: ' "Gill Sans", sans-serif',
          fontLineHeight: '1.5',
          borderRadius: '2em',
          colorPrimary: '#ff6d00',
          colorBackground: '#F6F8FA',
          colorPrimaryText: '#262626'
        },
        rules: {
          '.Block': {
            backgroundColor: 'var(--color-primary)',
            boxShadow: 'none',
            padding: '12px'
          },
          '.Input': {
            backgroundColor: '#efefef',//'#ffffff26',
            borderWidth: '1px',
            width: '-webkit-fill-available',
            display: 'inline-block',
            padding: '15px',
            paddingLeft: '20px',
            fontSize: '18px',
            borderRadius: '25px',
            color: 'black',//'white',
            border: 'unset',
            outline: 'none',
          },
          '.Input:disabled, .Input--invalid:disabled': {
            color: 'lightgray',
          },
          '.Input--invalid': {
            backgroundColor: '#ff00004d',
            color: '#fff',
            borderWidth: '0',
            boxShadow: 'unset',
          },
          '.Tab':{
            backgroundColor: '#efefef', //'#ffffff26',
            borderWidth: 1,
            width: '-webkit-fill-available',
            padding: '15px',
            paddingLeft: '20px',
            fontSize: '18px',
            borderTadius: '25px',
            color: 'white',
            border: 'unset',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }, 
          '.TabLabel': {
            fontWeight: '500',
            color: 'black',
            textAlign: 'center',
            width: '100%',
          },
          '.Tab:hover': {
            border: 'none',
            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
          },
          '.Tab--selected , .Tab--selected:focus, .Tab--selected:hover': {
            boxShadow: '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)', 
            backgroundColor: '#f47e3a47', 
          },
          '.TabIcon': {
            width: "100%", 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          '.Label': {
            fontWeight: '500',
            color: 'white',
            textAlign: 'center',
            width: '100%',
          },
          // '.Input:-webkit-autofill:hover:not(.p-Input--delayAutofillStyles)': {
          //   '-webkit-text-fill-color': 'unset',
          // },

        },
      },
      style: paymentElementStyle
    };
    setOptions(optionsSetup);
  };


  return (
    <div className="" style={{}} >
      {stripePromise && secretKey && (
        <Elements stripe={stripePromise} options={options}>
          <StripeForm clientSecret={secretKey} />
        </Elements>
      )}

      <img
        className="security"
        style={{ ...spacingStyles.pt10 }}
        src={mode === "light" ? require('./../../assets/security_dark.png') : require('./../../assets/security.png')}
        alt="Security"
      />

    </div>


  );
}

export default CreditProcess;

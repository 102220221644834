import React, { useState } from "react";
import { BsEnvelope, BsThreeDotsVertical } from "react-icons/bs";
import { FaCircle } from "react-icons/fa";
import { Menu, MenuItem, IconButton } from "@mui/material";

import { colors, globalStyles } from "../../assets/styles/global";
import calendarReschedule from "../../assets/calendar-reschedule.png";

const PendingCard = ({
  item: { id, time, gym, location, image, trainer, checkedIn },
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuVisible = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: colors.card_gray.color,
        borderRadius: 10,
        padding: "12px 15px", // Converted to valid CSS shorthand
      }}
    >
      <div
        style={{
          display: "flex", // Use flexbox for layout
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ ...globalStyles.text, fontSize: 15, fontWeight: "600" }}>
          {time}
        </div>

        <div
          style={{
            display: "flex", // Use flexbox for layout
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex", // Use flexbox for layout
              alignItems: "center",
              gap: 4,
              backgroundColor: colors.brown.color,
              borderRadius: 5,
              padding: "2px 5px",
            }}
          >
            <FaCircle color={colors.green.color} size={13} />
            <div
              style={{
                ...globalStyles.text,
                color: colors.white.color,
                fontSize: 13,
                fontWeight: "500",
              }}
            >
              3 Hrs Remaining
            </div>
          </div>

          <IconButton onClick={handleMenuOpen}>
            <BsThreeDotsVertical
              color={colors.white.color}
              size={25}
              style={{ marginLeft: 5 }}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={menuVisible}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                borderRadius: 8,
                marginTop: 10,
              },
            }}
          >
            <MenuItem onClick={handleMenuClose}>
              <img
                src={calendarReschedule}
                style={{ width: 18, height: 18, marginRight: 10 }}
                alt="Calendar Reschedule"
              />
              Reschedule
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <BsEnvelope
                size={18}
                color={colors.black.color}
                style={{ marginRight: 10 }}
              />
              Message
            </MenuItem>
          </Menu>
        </div>
      </div>

      <div style={{ ...globalStyles.h3, marginTop: 10 }}>{gym}</div>
      <div style={{ ...globalStyles.text }}>{location}</div>

      <div
        style={{
          display: "flex", // Use flexbox for layout
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <div style={{ marginTop: 15 }}>
          <img
            src={image}
            style={{ width: 45, height: 45, borderRadius: "50%" }}
            alt="Trainer"
          />
          <div
            style={{ ...globalStyles.text, fontWeight: "500", marginTop: 6 }}
          >
            {trainer}
          </div>
        </div>

        <div style={{ display: "flex", gap: 15 }}>
          <div disabled={checkedIn}>
            <div style={globalStyles.gradientButton}>
              <div style={globalStyles.gradientButtonText}>Accept</div>
            </div>
          </div>

          <div disabled={checkedIn}>
            <div
              style={{
                padding: "6px 17px",
                borderRadius: 8,
                backgroundColor: colors.light_gray.color,
              }}
            >
              <div
                style={{
                  ...globalStyles.text,
                  fontSize: 16,
                  fontWeight: "500",
                  color: colors.darker_gray.color,
                }}
              >
                Decline
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingCard;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedDate: null,
  selectedTime: null,
  selectedSite: null,
  bookingList: null,
};

const BookingSlice = createSlice({
  initialState,
  name: "bookingSlice",
  reducers: {
    updateSelectSite(state, action) {
      //return action.payload
      return {
        ...state,
        selectedSite: action.payload,
      };
    },
    updateSelectDate(state, action) {
      //console.log("Update State: ", action.payload)
      return {
        ...state,
        selectedDate: action.payload,
      };
    },
    updateSelectTime(state, action) {
      return {
        ...state,
        selectedTime: action.payload,
      };
    },
    addBookingList(state, action) {
      localStorage.setItem("bookings", JSON.stringify(action.payload));
      state.bookingList = action.payload;
    },
    addBookingData(state, action) {
      return action.payload;
    },
    selectDate(state, action) {
      //console.log(action.payload)
      state.selectedDate = action.payload;
    },
    selectTime(state, action) {
      state.selectedTime = action.payload;
    },
    selectSite(state, action) {
      //console.log(action.payload)
      state.selectedSite = action.payload;
    },
    updateNumRatings(state, action) {
      //console.log(action.payload)
      if (state.selectedSite) {
        state.selectedSite.profile.numRatings = action.payload;
      }
    },
    updateRating(state, action) {
      //console.log(action.payload)
      if (state.selectedSite) {
        state.selectedSite.profile.rating = action.payload;
      }
    },
    resetSelectTime(state) {
      return {
        ...state,
        selectedTime: null,
      };
    },
  },
});

export const {
  updateSelectSite,
  updateSelectDate,
  updateSelectTime,
  addBookingData,
  selectDate,
  selectTime,
  selectSite,
  updateNumRatings,
  updateRating,
  addBookingList,
  resetSelectTime,
} = BookingSlice.actions;

export default BookingSlice.reducer;

import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import {
  selectDate,
  selectTime,
} from "../../Services/Redux/Slices/Booking.Slice.js";

import {
  hasClassBooking,
  hasPast,
  hasAvailability,
  isToday,
  isBlackOut,
  isSelectDate,
} from "../../Services/Helpers/CalendarHelpers.js";
import { colors } from "../../assets/styles/global.js";

const ThreeMonthDaySelect = ({ schedule, daySelect, blackOuts }) => {
  useEffect(() => {
    setMonths();
    // console.log(schedule);
  }, []);

  const dispatch = useDispatch();
  const [defaultMonth, setdefaultMonth] = useState(null);
  const [displayMonths, setDisplayMonths] = useState([]);
  const selectedDate = useSelector((state) => state.booking.selectedDate);

  const scheduleList = {
    selectDay: new Date(), // Add your select day date here

    firstDayInMonth: function (month, year) {
      let firstDay = new Date(year, month);
      return firstDay;
    },
  };

  const [loading, setLoading] = useState(true);
  const [showCal, setShowCal] = useState(true);

  const weekHeader = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const myBookings = false; // Add your myBookings value here

  const isSelectMonth = (checkMonth) => {
    if (defaultMonth === checkMonth) {
      return true;
    } else {
      return false;
    }
  };

  const changeMonth = (changeMonth) => {
    if (defaultMonth !== changeMonth) {
      setdefaultMonth(changeMonth);
    }
  };

  const getMonthDays = (start) => {
    const month = [];
    const monthBegin = scheduleList.firstDayInMonth(
      start.getMonth(),
      start.getFullYear()
    );

    month[0] = getWeek(monthBegin);
    const initMonth = monthBegin.getMonth();

    for (let i = 1; i < 6; i++) {
      if (monthBegin.getMonth() === initMonth) {
        month[i] = getWeek(monthBegin);
      }
    }
    //console.log(month);
    return month;
  };

  const getWeek = (monthDay) => {
    const start = new Date(monthDay);
    const week = [];

    for (let day = 0; day < 7; day++) {
      if (monthDay.getDay() === day) {
        if (monthDay.getMonth() === start.getMonth()) {
          week[day] = new Date(monthDay);
          monthDay.setDate(monthDay.getDate() + 1);
        } else {
          week[day] = "";
        }
      } else {
        week[day] = "";
      }
    }

    return week;
  };

  const setMonths = () => {
    const now = new Date(Date.now());
    const monthSet = new Date(
      scheduleList.firstDayInMonth(now.getMonth(), now.getFullYear())
    );

    const month1 = new Date(monthSet);

    monthSet.setMonth(monthSet.getMonth() + 1);
    const month2 = new Date(
      scheduleList.firstDayInMonth(monthSet.getMonth(), monthSet.getFullYear())
    );

    monthSet.setMonth(monthSet.getMonth() + 1);
    const month3 = new Date(
      scheduleList.firstDayInMonth(monthSet.getMonth(), monthSet.getFullYear())
    );

    let displayMonths = [month1, month2, month3];

    //console.log(displayMonths);

    setDisplayMonths(displayMonths);
    setdefaultMonth(displayMonths[0]);
  };

  return (
    <>
      <div style={{ margin: 'auto', padding: 0, maxWidth: '330px' }}>
        <div style={{ flexDirection: "row" }}>
          <div style={{ flex: 10 }}>
            <div style={{ display: showCal ? "flex" : "none" }}>
              <div
                style={{
                  flexDirection: "col",
                  width: "100%",
                  justifyContent: "space-between",
                  marginBottom: 20,
                }}
              >
                <button
                  style={
                    isSelectMonth(displayMonths[0])
                      ? styles.activeMonth
                      : styles.month
                  }
                  onClick={() => {
                    changeMonth(displayMonths[0]);
                  }}
                >
                  <span>{moment(displayMonths[0]).format("MMM, YYYY")}</span>
                </button>
                <button
                  style={
                    isSelectMonth(displayMonths[1])
                      ? styles.activeMonth
                      : styles.month
                  }
                  onClick={() => {
                    changeMonth(displayMonths[1]);
                  }}
                >
                  <span>{moment(displayMonths[1]).format("MMM, YYYY")}</span>
                </button>
                <button
                  style={
                    isSelectMonth(displayMonths[2])
                      ? styles.activeMonth
                      : styles.month
                  }
                  onClick={() => {
                    changeMonth(displayMonths[2]);
                  }}
                >
                  <span>{moment(displayMonths[2]).format("MMM, YYYY")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="flexRow"
          style={{ ...styles.weekContainer }}
        >
          {weekHeader.map((dayHeader, index) => (
            <div key={index} style={{ width: "35px", height: "35px" }}>
              <span style={{ textAlign: "center", ...styles.short_day }}>
                {dayHeader}
              </span>
            </div>
          ))}
        </div>

        <div className="flexCol">
          {defaultMonth &&
            getMonthDays(defaultMonth).map((week, weekIndex) => (
              <div
                key={weekIndex}
                className="flexRow"
                style={{ width: "100%", justifyContent: "space-between" }}
              >
                {week.map((day, dayIndex) => (
                  <React.Fragment key={dayIndex}>
                    {day !== "" ? (
                      <div
                        style={{
                          width: "35px",
                          height: "35px",
                          alignItems: "center",
                        }}
                      >
                        <button
                          style={{
                            borderRadius: 50,
                            overflow: "hidden",
                            height: "2rem",
                            width: "2rem",
                            border: "unset",
                            marginBottom: 5,
                            ...(hasPast(day)
                              ? styles.pastDay
                              : styles.comingDay),
                            ...(isToday(day) && styles.today),
                            ...(hasAvailability(day, defaultMonth) &&
                              styles.availableDay),
                            ...(isBlackOut(day) && styles.blackoutDay),
                            ...(isSelectDate(day, selectedDate) &&
                              styles.selectDay),
                          }}
                          onClick={() => {
                            //console.log(day);
                            const serializableDate = day.toISOString();
                            dispatch(selectDate(serializableDate));
                            dispatch(selectTime(null));
                          }}
                        >
                          <span>{moment(day).format("DD")}</span>
                        </button>
                      </div>
                    ) : (
                      <div style={{ ...styles.emptyDay }} />
                    )}
                  </React.Fragment>
                ))}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ThreeMonthDaySelect;

const styles = {
  short_day: {
    color: "white",
    fontWeight: "600",
    fontSize: 20,
  },
  pastDay: {
    backgroundColor: colors.darker_gray.color,
    color: colors.gray.color,
  },
  comingDay: {
    backgroundColor: colors.gray.color,
    color: colors.black.color,
  },
  availableDay: {
    backgroundColor: "#838383",
    color: "white",
    fontWeight: 700,
  },
  blackoutDay: {
    backgroundColor: colors.darker_gray.color,
    color: colors.gray.color,
    padding: 10,
    borderRadius: 50,
  },
  weekContainer: {
    flexDirection: "col",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 5,
  },
  emptyDay: {
    width: "35px",
    height: "35px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  activeMonth: {
    width: "30%",
    alignItems: "center",
    backgroundColor: "orange",
    borderRadius: 50,
    padding: 10,
  },
  month: {
    width: "30%",
    alignItems: "center",
    borderColor: "gray",
    borderWidth: 2,
    color: "gray",
    borderRadius: 50,
    padding: 10,
  },
  today: {
    backgroundColor: colors.primary_50.color,
    color: "white",
    fontWeight: 700,
  },
  selectDay: {
    backgroundColor: colors.green50.color,
    color: colors.green.color,
    fontWeight: 700,
    fontSize: 18,
    padding: "unset",
  },
};

import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import ProfileContact from "../../../Components/Profile/ProfileContact";
import ProfileBusiness from "../../../Components/Profile/ProfileBusiness";
import ProfileHeader from "../../../Components/Profile/ProfileHeader";
import ProfileSettings from "../../../Components/Profile/ProfileSettings";
import MobileGallery from "../../MobileGallery";
import OnboardingGallery from "../../../Components/Onboarding/OnboardingGallery";
import { useSearchParam } from "react-use";
import PageHeader from "../../../Components/Common/PageHeader";
import { spacingStyles } from "../../../assets/styles/global";

const Profile = () => {
  const navigate = useNavigate();
  const tab = useSearchParam("t");

  const [showProfile, setShowProfile] = useState(tab || "Contact");

  return (
    <>
      <div style={{ ...spacingStyles.desktopMaxWidth, ...spacingStyles.plr10 }}>
        {/* <PageHeader /> */}
        <ProfileHeader
          showProfile={showProfile}
          setShowProfile={(tab) => {
            navigate(`/Profile?t=${tab}`);
            setShowProfile(tab);
          }}
        />
        <div style={{ ...spacingStyles.w100 }}>
          {showProfile === "Contact" ? <ProfileContact /> : ""}
          {showProfile === "Gallery" ? (
            <div style={{ height: "70%" }}>
              <OnboardingGallery />
            </div>
          ) : (
            ""
          )}
          {showProfile === "Trainer" ? <ProfileBusiness /> : ""}
          {showProfile === "Settings" ? <ProfileSettings /> : ""}
        </div>
      </div>

    </>
  );
};

export default Profile;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bookedMe: [],
  myBookings: [],
};

const myBookingSlice = createSlice({
  initialState: initialState,
  name: "bookingSlice",
  reducers: {
    addMyBookings(state, action) {
      state.myBookings = action.payload;
    },
    addBookedMe(state, action) {
      state.bookedMe = action.payload;
    },
  },
});

export const { addBookedMe, addMyBookings } = myBookingSlice.actions;

export default myBookingSlice.reducer;

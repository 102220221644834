import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  coords: {
    accuracy: null,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    latitude: 26.7153424,
    longitude: -80.0533746,
    speed: null,
  },
  address: {
    address: "West Palm Beach, FL 33401, USA",
    city: "West Palm Beach",
    state: "FL",
    zip: "33404",
    radius: 1,
    lat: 26.7153424,
    lng: -80.0533746,
  },
  locationAccessGranted: false,
};

const locationSlice = createSlice({
  name: "Location",
  initialState,
  reducers: {
    addLocation(state, action) {
      const {
        accuracy,
        latitude,
        longitude,
        heading,
        speed,
        altitude,
        altitudeAccuracy,
      } = action.payload;
      state.coords.accuracy = accuracy;
      state.coords.latitude = latitude;
      state.coords.longitude = longitude;
      state.coords.heading = heading;
      state.coords.speed = speed;
      state.coords.altitude = altitude;
      state.coords.altitudeAccuracy = altitudeAccuracy;
    },
    addAddress(state, action) {
      state.address = action.payload;
      state.isLoading = false;
    },
    updateLocationAccess(state, action) {
      state.locationAccessGranted = action.payload;
    },
  },
});

export const { addLocation, addAddress, updateLocationAccess } =
  locationSlice.actions;

export default locationSlice.reducer;

// Import necessary libraries and components
import React, { useEffect, useState, useContext } from "react";

import { useSelector } from "react-redux";

import { useLocation } from "react-router-dom";

import RenderIcons from "../../Components/Common/RenderIcons";
import { colors, spacingStyles } from "../../assets/styles/global";

import jwtDecode from "jwt-decode";

import { getStartTrial } from "../../Services/APIHandlers/CheckoutApi";
import AuthContext from "../../Navigation/AuthWrapper";

import { Button } from "../../Components/Common/Buttons/Button";

import formatPhoneNumber from "../../Services/Helpers/FormatPhoneNumber";
import { useNavigate } from "react-router-dom";
import { addBooking } from "../../Services/APIHandlers/BookingApi";

const ConfirmationScreen = () => {
  const auth = useContext(AuthContext);
  const navigation = useNavigate();

  const selectedDate = useSelector((state) => state.booking.selectedDate);
  const selectedTime = useSelector((state) => state.booking.selectedTime);
  const selectedSite = useSelector((state) => state.booking.selectedSite);

  // Extract URL parameters
  // const { search } = useLocation();
  // const params = new URLSearchParams(search);
  // const paymentIntent = params.get('payment_intent');
  // const clientSecret = params.get('payment_intent_client_secret');
  // const redirectStatus = params.get('redirect_status');

  const location = useLocation();
  const { paymentIntent } = location.state || {};

  const [userData, setUserData] = useState("");
  const [message, setMessage] = useState("");
  const [description, setDescription] = useState("");

  const checkToken = async () => {
    const token = await localStorage.getItem("token");
    if (token) {
      const userInfo = jwtDecode(token);
      setUserData(userInfo);
      console.log(userInfo);
    }
  };

  useEffect(() => {
    console.log(paymentIntent);
    console.log(selectedDate, selectedTime, selectedSite);

    //checkToken();

    switch (paymentIntent.status) {
      case "succeeded":
        setMessage("Success!");
        setDescription("Payment received.");
        break;

      case "processing":
        setMessage("Payment processing.");
        setDescription(" We will update you when payment is received.");
        break;

      case "requires_payment_method":
        setMessage("Payment failed.");
        setDescription("Please try another payment method.");
        break;

      default:
        setDescription("Something went wrong.");
        break;
    }
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await getStartTrial(auth.token); // Wait for the promise to resolve
      if (response) {
        console.log(response.data);
        navigation("Search");
      } else {
        console.error("Error");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div
      className="flexCol flexCenter"
      style={{
        ...spacingStyles.desktopMaxWidth,
        ...spacingStyles.mt40,
        overflow: "auto",
      }}
    >
      <RenderIcons
        iconName="confirmCheckmark"
        width={45}
        height={45}
        color={colors.green.color}
      />
      <h1 className="success" style={{ ...spacingStyles.mb0 }}>
        {message}
      </h1>
      <p className="light_gray" style={{ ...spacingStyles.mt0 }}>
        {description}
      </p>

      <h2>Email: {userData.email}</h2>
      <h2>Phone: {formatPhoneNumber(userData.phone)} </h2>

      <p className="left light_gray">
        Congratulations on your payment! Expect an email receipt and
        confirmation shortly. Our dedicated team will be in touch with you via
        phone to ensure you're all set for your first booking. Get ready to
        embark on your fitness journey with us!
      </p>
      {/* <p>Payment Intent: {paymentIntent}</p>
      <p>Client Secret: {clientSecret}</p>
      <p>Redirect Status: {redirectStatus}</p> */}
      <Button fill="solid" text="Yay!!" onClick={handleSubmit}></Button>
    </div>
  );
};

export default ConfirmationScreen;

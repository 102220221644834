import { FaCheckCircle } from "react-icons/fa";
import { FaRegCircleXmark } from "react-icons/fa6";
import { BsExclamationTriangle } from "react-icons/bs";
import { colors, globalStyles } from "../../assets/styles/global";

import "../../assets/styles/global.css";

const WalletDetails = (details) => {

  console.log(details); 

  return (
    <div>
      <div
        style={{
          ...globalStyles.mt10,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 15,
        }}
      >


        <div
          style={{
            ...globalStyles.mt40,
            ...globalStyles.flexRow,
            ...globalStyles.alignCenter,
            ...globalStyles.flexSpace,
            ...globalStyles.bbg,
            ...globalStyles.pb5,
            ...globalStyles.mb10,
          }}
        >
          <div style={{ ...globalStyles.flexRow, ...globalStyles.alignCenter }}>
            <FaCheckCircle
              style={{ ...globalStyles.mr15 }}
              color={colors.dark_gray.color}
              size={30}
            />
            <span
              style={{
                ...globalStyles.white,
                ...globalStyles.h2,
                ...globalStyles.fw600,
              }}
            >
              Bookings
            </span>
          </div>
          <span style={{ ...globalStyles.white, ...globalStyles.h1 }}>
            {details?.current_stats?.bookings}
          </span>
        </div>
        <div
          style={{
            ...globalStyles.mt5,
            ...globalStyles.flexRow,
            ...globalStyles.alignCenter,
            ...globalStyles.flexSpace,
            ...globalStyles.bbg,
            ...globalStyles.pb5,
            ...globalStyles.mb10,
          }}
        >
          <div style={{ ...globalStyles.flexRow, ...globalStyles.alignCenter }}>
            <FaRegCircleXmark
              style={{ ...globalStyles.mr15 }}
              color={colors.dark_gray.color}
              size={30}
            />
            <span
              style={{
                ...globalStyles.white,
                ...globalStyles.h2,
                ...globalStyles.fw600,
              }}
            >
              Cancels
            </span>
          </div>
          <span style={{ ...globalStyles.white, ...globalStyles.h1 }}>
            {details?.current_stats?.cancelled}
          </span>
        </div>
        <div
          style={{
            ...globalStyles.mt5,
            ...globalStyles.flexRow,
            ...globalStyles.alignCenter,
            ...globalStyles.flexSpace,
            ...globalStyles.bbg,
            ...globalStyles.pb5,
            ...globalStyles.mb10,
          }}
        >
          <div style={{ ...globalStyles.flexRow, ...globalStyles.alignCenter }}>
            <BsExclamationTriangle
              style={{ ...globalStyles.mr15 }}
              color={colors.dark_gray.color}
              size={30}
            />
            <span
              style={{
                ...globalStyles.white,
                ...globalStyles.h2,
                ...globalStyles.fw600,
              }}
            >
              No-Shows
            </span>
          </div>
          <span style={{ ...globalStyles.white, ...globalStyles.h1 }}>
            {details?.current_stats?.noShows}
          </span>
        </div>
      </div>
    </div>
  );
};

export default WalletDetails;

import { useDispatch } from "react-redux";

import { useEffect, useState, useRef } from "react";

//Calendars
import VertTimeSelect from "./VertTimeSelect";
import TwoWeekDaySelect from "./TwoWeekDaySelect";

import {
  updateSelectDate,
  updateSelectTime,
  resetSelectTime,
} from "../../Services/Redux/Slices/Booking.Slice";
import { globalStyles } from "../../assets/styles/global";

const DetailsSchedule = ({
  schedule,
  heading = "Booking Schedule",
  showSingleWeek = false,
  showFullMonth = false,
}) => {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  const dispatch = useDispatch();
  const scrollViewRef = useRef(null);

  const scrollToBottom = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollToEnd({ animated: true });
    }
  };

  function handleSelectDay(selected) {
    const dateStr = selected.toISOString();
    dispatch(updateSelectDate(dateStr));
    dispatch(resetSelectTime());
    setSelectedDate(dateStr);
    scrollToBottom();
  }

  function handleSelectTime(selected) {
    dispatch(updateSelectTime(selected));
    setSelectedTime(selected);
    scrollToBottom();
  }

  useEffect(() => {
    //console.log("DetailsSchedule: ", schedule);
    const options = {
      weekday: "short", // as "short" | "long" | "narrow",
    };
    const formatter = Intl.DateTimeFormat("en-US", options);
    const date = new Date();
    const selectDay = formatter.format(date);
    setSelectedDate(selectDay);
  }, [schedule]);

  return (
    <>
      <div>
        {heading && (
          <div
            style={{
              ...globalStyles.pb10,
              ...globalStyles.mb10,
              ...globalStyles.mt40,
            }}
          >
            <div style={{ ...globalStyles.h2, ...globalStyles.center }}>
              {heading}
            </div>
          </div>
        )}
        <TwoWeekDaySelect
          schedule={schedule}
          handleSelectDay={(day) => handleSelectDay(day)}
          showSingleWeek={showSingleWeek}
          showFullMonth={showFullMonth}
        />

        {schedule && selectedDate != null && (
          <VertTimeSelect
            scheduleData={schedule}
            handleSelectTime={(time) => handleSelectTime(time)}
          ></VertTimeSelect>
        )}
      </div>
    </>
  );
};
export default DetailsSchedule;

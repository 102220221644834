import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateSchedule } from "../../APIHandlers/ScheduleApi";
// Async thunk for updating space details
export const updateScheduleAsync = createAsyncThunk(
  "Schedule/updateSchedule",
  async ({ data, token }, { dispatch, getState, rejectWithValue }) => {
    try {
      console.log("data: ", data);
      const response = await updateSchedule(data, token);
      console.log("response: ", response);
      // You can dispatch a success message if needed
      // dispatch(showSuccessMessage("Space details updated successfully"));
      return response.data;
      //return false;
    } catch (error) {
      console.log(error);
      // You can dispatch an error message if needed
      // dispatch(showErrorMessage("Failed to update space details"));
      return rejectWithValue(error.response.data);
    }
  }
);
export function validateEmail(email){
    // Simple email validation using regular expression
    const emailRegex = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};

export function  validateFormData(validationData, formData){

    let errors = {};
    let isValid = true;

    for (const field in validationData) {
      if (validationData.hasOwnProperty(field)) {
        const rules = validationData[field];

        for (const rule in rules) {
          if (rules.hasOwnProperty(rule)) {
            if (rule === 'required' && rules[rule] && !formData[field]) {
              errors[field] = `Please enter your ${field}`;
              isValid = false;
            } else if (rule === 'email' && rules[rule] && !validateEmail(formData[field])) {
              errors[field] = `Please enter a valid email`;
              isValid = false;
            } else if (rule === 'minlength' && rules[rule] && formData[field].length < rules[rule]) {
              errors[field] = `Please enter at least ${rules[rule]} characters`;
              isValid = false;
            }
          }
        }
      }
    }

    return {
      isValid,
      errors,
    };
  };

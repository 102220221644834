import React, { useState, useEffect } from 'react';
import { Box, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { globalStyles, spacingStyles } from '../../assets/styles/global';

import { fetchTrainersNearMe } from '../../Services/APIHandlers/BookingApi';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const TrainerList = ({ latitude, longitude, gymName }) => {
  const [trainers, setTrainers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchTrainersNearMe(latitude, longitude);
        const data = response.data; // Assuming response has data property
        setTrainers(data);
        
      } catch (error) {
        console.error('Error fetching trainers:', error);
      }
    };
    fetchData();
  }, [latitude, longitude]);

  return (
    <>
      <div>
      <div style={{ ...globalStyles.h2, ...globalStyles.center, ...spacingStyles.pb20 }}>
         Trainers Near {gymName}
      </div>
        {/* <h2 className="cust_header light_gray ">Trainers Near {gymName}</h2> */}
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]} // Include Autoplay module
          spaceBetween={20}
          slidesPerView={3}
          autoplay={{
            delay: 2500, // Delay between slides in ms
            disableOnInteraction: false, // Continue autoplay after user interaction
          }}
          // navigation
          // pagination={{ clickable: true }}
          // scrollbar={{ draggable: true }}
        >
          {trainers.map((trainer, index) => (
            <SwiperSlide className="swiper" key={index}>
              <Box>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant="dot"
                >
                  <Avatar alt={`Trainer ${index}`} src={trainer.image} sx={{ width: 75, height: 75 }} />
                </StyledBadge>
                <p>{trainer.name}</p>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default TrainerList;

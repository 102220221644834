import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import React from "react";

import { globalStyles } from "../../../assets/styles/global";

import HoriTimeInput from "../../../Components/Calendar/HoriTimeInput";
import { Container } from "@mui/material";

const days = {
  weekHeader: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
};

const LegendItem = ({ color, label }) => {
  return (
    <div
      style={{
        ...styles.legendItem,
        ...globalStyles.mt10,
        ...globalStyles.ml5,
        ...globalStyles.mr5,
      }}
    >
      <div style={{ ...styles.colorSquare, backgroundColor: color }} />
      <div style={globalStyles.h2}>{label}</div>
    </div>
  );
};

const EditSchedule = () => {
  const [daySelect, setDaySelect] = useState("Sun");
  const schedule = useSelector((state) => state.schedule);
  const [scheduleData, setSchedule] = useState(schedule);

  const [isChanged, setIsChanged] = useState(false);

  function isSelectDay(day) {
    if (daySelect == null) {
      return false;
    } else if (daySelect.toUpperCase() === day.toUpperCase()) {
      return true;
    } else {
      return false;
    }
  }

  function selectDay(day) {
    setDaySelect(day);
  }

  function hasTime(day) {
    let retn = false;
    //console.log(scheduleData[day]);

    if (scheduleData[day] != undefined) {
      scheduleData[day].forEach((timeObj) => {
        if (timeObj.select === true) {
          retn = true;
        }
      });
    }
    return retn;
  }

  useEffect(() => {
    setSchedule(schedule);
    //console.log(scheduleData);
  }, [schedule]);

  return (
    <Container maxWidth="md">
      <div style={{ ...globalStyles.container, ...globalStyles.flexSpace }}>
        <div style={{ ...globalStyles.mt20 }}>
          <div
            style={{
              ...globalStyles.h3,
              ...globalStyles.white,
              ...globalStyles.center,
              ...globalStyles.pb10,
            }}
          >
            Select week day (ANY that apply)
          </div>
          <div style={{ ...globalStyles.flexRow, ...globalStyles.mb10 }}>
            {days.weekHeader.map((day, index) => (
              <div
                key={index}
                style={{
                  ...globalStyles.p5,
                  ...globalStyles.m5,
                  ...styles.day,
                  ...(hasTime(day) ? styles.hasDay : null),
                  ...(isSelectDay(day) ? styles.selectDay : null),
                  cursor: "pointer",
                }}
                onClick={() => {
                  //console.log(day);
                  selectDay(day);
                }}
              >
                <div
                  style={{
                    ...globalStyles.h2,
                    ...styles.colorGray,
                    ...(hasTime(day) ? styles.hasDayTime : null),
                    ...(isSelectDay(day) ? styles.selectTimeText : null),
                  }}
                >
                  {day}
                </div>
              </div>
            ))}
          </div>

          <HoriTimeInput
            daySelect={daySelect}
            schedule={scheduleData}
          ></HoriTimeInput>
        </div>
      </div>
    </Container>
  );
};

export default EditSchedule;

const styles = {
  legendItem: {
    flexDirection: "row",
    alignItems: "center",
  },
  colorSquare: {
    width: 16,
    height: 16,
    borderRadius: 4,
    marginRight: 8,
  },
  day: {
    // backgroundColor: "#000",
    // borderColor: globalStyles.gray.color,
    // borderWidth: 1,
    borderRadius: 10,
  },
  hasDay: {
    // backgroundColor: "#51FCBA4F",
    // borderColor: globalStyles.green.color,
    // borderWidth: 1,
    borderColor: "#C4C4C4",
    backgroundColor: "#FFffff40",
    //borderWidth: 1,
    borderRadius: 10,
    color: "#fff",
    padding: 5,
  },
  hasDayTime: {
    color: globalStyles.white.color,
    fontWeight: "500",
  },
  selectDay: {
    backgroundColor: "#FF6D00",
    borderColor: globalStyles.orange.color,
    borderWidth: 1,
    borderRadius: 10,
  },
  legendContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  colorGray: {
    color: globalStyles.gray.color,
  },
  selectTimeText: {
    color: "#fff",
    fontSize: 20,
    fontWeight: "800",
  },
};

import { Button, Dialog } from "@mui/material";
import React from "react";

const DeleteConfirmationModal = ({ onDelete, onClose }) => {
  return (
    <Dialog
      open
      maxWidth="md"
      onClose={onClose}
      PaperProps={{
        style: styles.container,
      }}
    >
      <div style={styles.container}>
        <div style={styles.modal}>
          <span style={styles.title}>Are you sure you want to delete?</span>
          <div style={styles.buttonContainer}>
            <Button
              onClick={onDelete}
              style={{ ...styles.button, ...styles.deleteButton }}
            >
              <span style={{ ...styles.buttonText, color: "#fff" }}>
                Delete
              </span>
            </Button>
            <Button onClick={onClose} style={styles.button}>
              <span style={styles.buttonText}>Cancel</span>
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const styles = {
  container: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modal: {
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 20,
    alignItems: "center",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 20,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 15,
  },
  button: {
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#ccc",
    textTransform: "none",
  },
  deleteButton: {
    backgroundColor: "red",
    borderColor: "red",
  },
  buttonText: {
    fontSize: 16,
    fontWeight: "bold",
  },
};

export default DeleteConfirmationModal;

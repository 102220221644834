import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import AuthContext from "./AuthWrapper";
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { useTheme } from '@mui/material/styles';

import LogoutButton from '../Components/Common/Buttons/LogoutButton';
import { Button } from '../Components/Common/Buttons/Button';

import { spacingStyles } from '../assets/styles/global';

import HomeIcon from '@mui/icons-material/Home';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PaidIcon from '@mui/icons-material/Paid';
import ExpandLess from '@mui/icons-material/ExpandLess'; // Icon for expanded state
import ExpandMore from '@mui/icons-material/ExpandMore'; // Icon for collapsed state
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const menuSchema = {
    Home: { label: "Home", icon: <HomeIcon />, isComplete: () => true, collapsible: false },
    MyListing: {
        label: "My Listing",
        icon: <LocationOnIcon />, // Default icon for the collapsible parent
        collapsible: true,
        subItems: {
            Description: { label: "Description", icon: <DescriptionIcon />, isComplete: () => true },
            Gallery: { label: "Photos", icon: <HomeIcon />, isComplete: () => false },
            Space: { label: "Space Details", icon: <HomeIcon />, isComplete: () => false },
            Amenities: { label: "Amenities", icon: <HomeIcon />, isComplete: () => false },
            Location: { label: "Location", icon: <HomeIcon />, isComplete: () => true },
            Schedule: { label: "Availability", icon: <HomeIcon />, isComplete: () => false },
            Special: { label: "Special Instructions", icon: <HomeIcon />, isComplete: () => false },
            SetupComplete: { label: "Agreement", icon: <HomeIcon />, isComplete: () => false },
        }
    },
    Bookings: { label: "Bookings", icon: <EventAvailableIcon />, isComplete: () => true, collapsible: false },
    Earnings: { label: "Earnings", icon: <PaidIcon />, isComplete: () => true, collapsible: false },
};

// Function to return whether a menu item is complete or not
const isComplete = (menuItem) => {
    return menuItem.isComplete ? menuItem.isComplete() : false;
};

const DeskSideMenu = ({ activeScreen, onMenuClick }) => {
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);
    const { darkMode } = useContext(AuthContext);
    const theme = useTheme();

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const menuTextStyles = {
        primaryTypographyProps: {
            style: { color: theme.palette.text.primary },
        },
    };

    const getMenuClass = (screen) => activeScreen === screen ? 'active-sidemenu-item' : '';

    const toggleSubMenu = () => setSubMenuOpen(!isSubMenuOpen);

    return (
        <div className="onboard-side-menu">
            <MenuList className="side-menu-list" style={{ width: '100%' }}>
                {/* Loop through menuSchema to dynamically generate MenuItems */}
                {Object.keys(menuSchema).map((menuKey) => {
                    const menuItem = menuSchema[menuKey];

                    // Return an array of components instead of using React.Fragment
                    return [
                        <MenuItem
                            key={menuKey}
                            className={`mui-menu-item ${getMenuClass(menuKey)}`}
                            onClick={() => menuItem.collapsible ? toggleSubMenu() : onMenuClick(menuKey)}
                        >
                            <ListItemIcon style={{ minWidth: '40px', color: darkMode ? '#fff' : '#000' }}>
                                {menuItem.icon}
                            </ListItemIcon>
                            <ListItemText primary={menuItem.label} {...menuTextStyles} />

                            {/* Show chevron for collapsible items (like MyListing) */}
                            {menuItem.collapsible && (
                                <ListItemIcon style={{ minWidth: '40px', marginLeft: 'auto' }}>
                                    {isSubMenuOpen ? <ExpandMore /> : <ChevronLeftIcon />}
                                </ListItemIcon>
                            )}
                        </MenuItem>,

                        // Render collapsible sub-items if they exist
                        menuItem.collapsible && (
                            <Collapse key={`${menuKey}-collapse`} in={isSubMenuOpen} timeout="auto" style={{ ...spacingStyles.pl20 }} unmountOnExit>
                                {Object.keys(menuItem.subItems).map((subKey) => {
                                    const subItem = menuItem.subItems[subKey];
                                    return (
                                        <MenuItem
                                            key={subKey}
                                            className={`mui-menu-item ${getMenuClass(subKey)}`}
                                            onClick={() => onMenuClick(subKey)}
                                        >
                                            <ListItemIcon style={{ minWidth: '40px' }}>
                                                {isComplete(subItem) && <CheckCircleIcon color="success" />}
                                            </ListItemIcon>
                                            <ListItemText primary={subItem.label} {...menuTextStyles} />
                                        </MenuItem>
                                    );
                                })}
                            </Collapse>
                        )
                    ];
                })}
            </MenuList>

            {/* {isAuthenticated ? (
                <LogoutButton />
            ) : (
                <div className="logbtn">
                    <div style={{ ...spacingStyles.m20 }}>
                        <Button fill="outline" text="Login" onClick={() => onMenuClick('Login')} />
                    </div>
                </div>
            )} */}

            <div className="sideBack"></div>
        </div>
    );
};

export default DeskSideMenu;

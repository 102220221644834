import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Masonry from "@mui/lab/Masonry";
import { colors, globalStyles } from "../../assets/styles/global";
import {
  getMyGallery,
  deletePhoto,
} from "../../Services/APIHandlers/ProfileApi";
import moment from "moment";
import { MdDelete, MdAdd } from "react-icons/md";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import getErrorMessage from "../../Services/Helpers/getErrorMessage";
import SlideInPanel from "../../Components/Modals/slideInPanel";
import ImageUploader from "../../Components/Common/ImageUpload";

const CardItem = ({ image, picture_id, editEnabled, setDeleteId }) => {
  return (
    <div style={styles.imageCard}>
      <img
        src={image}
        width={"100%"}
        style={{ borderRadius: 20, minHeight: 100 }}
        alt="card"
      />

      {editEnabled && (
        <span
          style={styles.deleteButton}
          onClick={() => setDeleteId(picture_id)}
        >
          <MdDelete size={28} color={colors.white.color} />
        </span>
      )}
    </div>
  );
};

const MasonaryCardView = ({ images, editEnabled, setDeleteId }) => {
  return (
    <Masonry columns={2} spacing={2} sx={{ my: 2 }}>
      {images.map(({ url, picture_id }, index) => {
        return (
          <CardItem
            key={url + index}
            image={url}
            picture_id={picture_id}
            editEnabled={editEnabled}
            setDeleteId={setDeleteId}
          />
        );
      })}
    </Masonry>
  );
};

const MobileGallery = ({ editEnabled, gallery }) => {
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth.token);

  const [deleteId, setDeleteId] = useState(null);

  const [galleryData, setGalleryData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isPanelOpen, setPanelOpen] = React.useState(false);

  const categorizeByDate = (gallery) => {
    const sortedGallery = [...gallery];
    sortedGallery.reverse();
    const categorizedData = sortedGallery.reduce((acc, obj) => {
      const date = moment(obj.date).format("DD MMM yyyy");
      if (!acc[date]) {
        acc[date] = [];
      }

      if (obj.url) {
        acc[date].push(obj);
      }
      return acc;
    }, {});

    setGalleryData(categorizedData);
  };

  const fetchMyGallery = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getMyGallery({
        token,
      });

      const gallery = response.data?.map(({ date, url, picture_id }) => {
        return {
          date,
          url,
          picture_id,
        };
      });

      categorizeByDate(gallery);
    } catch (error) {
      console.error(">>>Error", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [token]);

  const onLoadProfilePicSuccess = async (picture_id) => {
    setPanelOpen(false);
    fetchMyGallery();
  };

  const handleDelete = async () => {
    try {
      const response = await deletePhoto({
        token,
        picture_id: String(deleteId),
      });
      console.log(">>message", response.data);
      setDeleteId(null);
      fetchMyGallery();
    } catch (error) {
      alert(getErrorMessage(error));
    }
  };

  useEffect(() => {
    if (!gallery) {
      fetchMyGallery();
    } else {
      categorizeByDate(gallery);
    }
  }, [fetchMyGallery, gallery]);

  return (
    <div>
      {!!deleteId && (
        <DeleteConfirmationModal
          onDelete={handleDelete}
          onClose={() => setDeleteId(null)}
        />
      )}

      <div style={{ paddingBottom: 80, minHeight: "60vh" }}>
        {!Object.keys(galleryData)?.length && !loading && (
          <div style={{ ...globalStyles.alignCenter, ...globalStyles.mt30 }}>
            <span style={{ ...globalStyles.h3 }}>No images added</span>
          </div>
        )}

        {galleryData &&
          Object.keys(galleryData).map((key) => (
            <div key={key}>
              <span
                style={{
                  ...globalStyles.h2,
                  ...globalStyles.mb20,
                  ...globalStyles.ml10,
                }}
              >
                {key}
              </span>

              <MasonaryCardView
                images={galleryData[key]}
                editEnabled={editEnabled}
                setDeleteId={setDeleteId}
              />
            </div>
          ))}
      </div>

      {editEnabled && (
        <span style={styles.addButton} onClick={() => setPanelOpen(true)}>
          <MdAdd color={colors.white.color} size={60} />
        </span>
      )}

      <SlideInPanel
        isOpen={isPanelOpen}
        closePanel={() => setPanelOpen(false)}
        content={ImageUploader}
        onSuccess={onLoadProfilePicSuccess}
      />
    </div>
  );
};

export default MobileGallery;

const styles = {
  addButton: {
    borderRadius: 60,
    position: "relative",
    // right: 30,
    // bottom: 30,
    backgroundColor: colors.primary.color,
    alignSelf: "center",
    cursor: "pointer",
  },
  imageCard: {
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 20,
  },
  deleteButton: {
    backgroundColor: colors.gray.color,
    position: "absolute",
    bottom: 30,
    right: 10,
    borderRadius: 40,
    padding: 5,
    cursor: "pointer",
  },
};

const getErrorMessage = (error) => {
  console.error(error?.response?.data || error);

  return String(
    error?.response?.data?.msg ||
      error?.response?.data?.message ||
      "Something went wrong"
  );
};

export default getErrorMessage;

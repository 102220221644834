import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles"; // Use the theme to get current mode
import mapboxgl from "mapbox-gl";
import { fetchSitesNearMe } from "../../Services/APIHandlers/BookingApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAmericas, faBars } from "@fortawesome/free-solid-svg-icons";

mapboxgl.accessToken =
  "pk.eyJ1IjoiaG9wcGVyZml0IiwiYSI6ImNsa2tpM2c0ZzA2dWkzcG1tNnV3MmVyMjQifQ.x9vQVrNmILfTln-SAXS8pw";

export default function MapSearch() {
  const location = useSelector((state) => state.location);
  const scrollableDivRef = useRef(null);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-80.0534);
  const [lat, setLat] = useState(26.7153);
  const [zoom, setZoom] = useState(9);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [markers, setMarkers] = useState();
  const [showList, setShowList] = useState(false);
  const theme = useTheme(); // Access the current theme
  const mode = theme.palette.mode; // Determine if the mode is 'dark' or 'light'

  const mapStyle =
    mode === "dark" ? "mapbox://styles/mapbox/dark-v11" : "mapbox://styles/mapbox/light-v10"; // Choose map style based on mode

  const getSites = async () => {
    try {
      if (location?.coords?.latitude && location?.coords?.longitude) {
        const response = await fetchSitesNearMe(
          location?.coords?.latitude,
          location.coords.longitude
        );
        setMarkers(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (map.current) {
      map.current.remove(); // Destroy the map instance before re-initializing
    }
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapStyle, // Use dynamic map style
      center: [lng, lat],
      zoom: zoom,
    });

    map.current.on("load", () => {
      getSites();
    });
  }, [mapStyle]); // Re-initialize map when mapStyle changes

  useEffect(() => {
    if (map.current && markers) {
      markers.forEach((markerInfo) => {
        const el = document.createElement("div");
        el.style.backgroundImage = `url(${markerInfo.image})`;
        el.style.width = `40px`;
        el.style.height = `40px`;
        el.style.backgroundSize = "cover";
        el.style.backgroundPosition = "center";
        el.style.borderRadius = "50px";
        el.style.borderColor = "#525252";
        el.style.borderStyle = "solid";
        el.style.borderWidth = "3px";

        new mapboxgl.Marker(el)
          .setLngLat([markerInfo.location[0].long, markerInfo.location[0].lat])
          .addTo(map.current);

        el.addEventListener("click", () => {
          setSelectedMarker(markerInfo);
          window.open("/" + markerInfo.slug);
        });
      });
    }
  }, [markers]);

  const ScrollableList = ({ data }) => {
    return (
      <ul
        className="scrollableList"
      >
        {data?.map((item, index) => (
          <li
            key={index}
            onClick={() => {
              map.current.flyTo({
                center: [item.location[0].long, item.location[0].lat],
                zoom: 14,
              });
              setSelectedMarker(item);
              window.open("/" + item.slug);
            }}
          >
            <div
              className="flex"
            >
              {/* <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background:
                    "linear-gradient(to bottom, rgba(0,0,0,0) 35%, rgba(0,0,0,0.65) 85%, rgba(0,0,0,0.65) 100%)",
                }}
              ></div> */}
              <img
                src={item.image}
                alt="Logo"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <p
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  margin: 0,
                  padding: "8px",
                  color: "#fff",
                  background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                  textAlign: "center",
                }}
              >
                {item.name}
              </p>
            </div>
          </li>
        ))}
        <li className="scrollable-list-space"></li>
      </ul>
    );
  };

  const handleClick = () => {
    const mobileSearchBtn = document.querySelector(".search-list-container");
    mobileSearchBtn.classList.add("clicked");

    setShowList((prevState) => !prevState);

    if (!showList) {
      setViewMode({ label: "View Map", icon: faEarthAmericas });
    } else {
      setViewMode({ label: "View List", icon: faBars });
    }
  };

  const [viewMode, setViewMode] = useState({
    label: "View List",
    icon: faBars,
  });

  return (
    <>
      <div className="search-container">
        <div
          className={`search-list-container ${showList ? "clicked" : ""}`}
          ref={scrollableDivRef}
        >
          <ScrollableList className="scrollist" data={markers} />
        </div>
        <div ref={mapContainer} className="map-container" />
      </div>
    </>
  );
}

import React from "react";

import { globalStyles, spacingStyles } from "../../assets/styles/global";
import { Box, Stack } from "@mui/material";

function ProfileFieldInput({ label, value, onClick, disabled }) {
  return (
    <Stack direction="column" alignItems="flex-start">
      <h3 style={{ ...spacingStyles.pb10}} >{label}:</h3>
      <div
        onClick={onClick}
        style={{
          ...globalStyles.input,
          ...(disabled ? { backgroundColor: "#000" } : {}),
          cursor: !disabled ? "pointer" : "auto",
        }}
      >
        {value || " "}
      </div>
    </Stack>
  );
}

export default ProfileFieldInput;
